// src/routes/profile/ProfileHeader.tsx
import React, { useRef, useState, useEffect } from "react";
import Avatar from "boring-avatars";
import { ExternalLink, Phone, Mail } from "lucide-react";
import axiosInterceptor from "../../utils/axiosInterceptor";
import EditProfile from "./EditProfile";
import { PersonalInfo } from "./interfaces";
import "./CustomModal.css";
import Modal from "../../components/Modal";

interface ProfileHeaderProps {
  name: string;
  phone: string;
  email: string;
  address: string;
  birthday: string;
  visa: string;
  website: string;
  linkedin: string;
  github: string;
  countries: string[];
  user_data: PersonalInfo;
  userId: string;
}

const PencilIcon = ({
  className,
  loading,
}: {
  className: string;
  loading?: boolean;
}) => {
  if (loading) {
    return (
      <svg
        viewBox="0 0 24 24"
        width="100%"
        height="100%"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      >
        <path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 12 12"
            to="360 12 12"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    );
  }
  return (
    <svg
      viewBox="0 0 24 24"
      width="100%"
      height="100%"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" />
    </svg>
  );
};

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  name,
  phone,
  email,
  address,
  birthday,
  visa,
  website,
  linkedin,
  github,
  countries,
  user_data,
  userId,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await axiosInterceptor.get(
          `serve_private_doc/user_career_data/user_id=${userId}/images/`,
        );
        if (response.data?.private_docs?.length) {
          const lastIndex = response.data.private_docs.length - 1;
          const [presignedUrl] = response.data.private_docs[lastIndex];
          setProfileImage(presignedUrl);
        }
      } catch (err) {
        console.error("Error fetching profile image:", err);
      }
    };
    fetchProfileImage();
  }, [userId]);

  const handleImageUploadClick = () => fileInputRef.current?.click();
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (!["image/jpeg", "image/png"].includes(file.type)) {
      alert("Please select a JPG or PNG image.");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
      await axiosInterceptor.post(`images/upload/${userId}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRFToken": csrfToken,
        },
      });
      const response = await axiosInterceptor.get(
        `serve_private_doc/user_career_data/user_id=${userId}/images/`,
      );
      if (response.data?.private_docs?.length) {
        const lastIndex = response.data.private_docs.length - 1;
        const [presignedUrl] = response.data.private_docs[lastIndex];
        setProfileImage(presignedUrl);
      }
    } catch (error) {
      console.error("Error uploading profile image:", error);
      alert("Failed to upload profile image.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className=" bg-background dark:bg-background-dark rounded-lg p-5 shadow-sm border-solid border-[0.5px] border-background-dimmest dark:border-background-dark-dimmest"
      style={{}}
    >
      <div className="flex items-start gap-6">
        {/* Profile Image */}
        <div className="relative">
          <div className="w-24 h-24 rounded-full overflow-hidden bg-background-root dark:bg-background-dark-root ">
            {profileImage ? (
              <img
                src={profileImage}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            ) : (
              <Avatar
                name={userId}
                variant="beam"
                size={96}
                colors={["#99CBFF", "#EDEDED"]}
              />
            )}
          </div>
          <button
            onClick={handleImageUploadClick}
            disabled={isLoading}
            className={`absolute -bottom-1 -right-1  bg-background dark:bg-background-dark  border border-[#383A40] rounded-full p-1.5 shadow-sm ${
              isLoading ? "cursor-not-allowed" : "hover:bg-[#272727]"
            } transition-colors`}
            title="Edit Profile Picture"
          >
            <PencilIcon
              className="w-4 h-4 text-foreground dark:text-foreground-dark hover:text-dimmest dark:hover:text-dimmest-dark"
              loading={isLoading}
            />
          </button>
          <input
            ref={fileInputRef}
            type="file"
            className="hidden"
            accept=".jpg,.jpeg,.png"
            onChange={handleFileChange}
          />
        </div>

        <div className="flex-1">
          {/* Name and edit button row */}
          <div className="flex justify-between items-start mb-3">
            <h1 className="text-xl font-semibold text-foreground dark:text-foreground-dark ">
              {name}
            </h1>
            <button
              onClick={() => setShowEdit(true)}
              className="text-foreground dark:text-foreground-dark hover:text-dimmest dark:hover:text-dimmest-dark p-2 rounded-full transition-colors"
              title="Edit Profile Info"
            >
              <PencilIcon className="w-5 h-5" />
            </button>
          </div>

          {/* Three column layout for the profile information */}
          <div className="flex flex-col md:flex-row gap-6">
            {/* Left column: Address only */}
            <div className="md:w-1/3">
              <p className="text-sm text-foreground-dimmest dark:text-foreground-dark-dimmest  mb-2">
                {address}
              </p>
              <p className="text-sm text-foreground-dimmest dark:text-foreground-dark-dimmest  mb-3">
                {visa ? `Visa: ${visa}` : "No Visa"}
              </p>
            </div>

            {/* Middle column: Visa, Phone, Email */}
            <div className="md:w-1/3">
              {/* Contact info with white icons */}
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Phone className="w-4 h-4 text-foreground-dimmest dark:text-foreground-dark-dimmest " />
                  <a
                    href={`tel:${phone}`}
                    className="text-sm text-foreground-dimmest dark:text-foreground-dark-dimmest "
                  >
                    {phone}
                  </a>
                </div>
                <div className="flex items-center gap-2">
                  <Mail className="w-4 h-4 text-foreground-dimmest dark:text-foreground-dark-dimmest " />
                  <a
                    href={`mailto:${email}`}
                    className="text-sm text-foreground-dimmest dark:text-foreground-dark-dimmest "
                  >
                    {email}
                  </a>
                </div>
              </div>
            </div>

            {/* Right column: Websites */}
            <div className="md:w-1/3 flex flex-col items-end">
              <div className="flex flex-col items-end gap-2">
                {website && (
                  <a
                    href={website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-1 text-sm text-primary hover:underline"
                  >
                    <span>{website.replace(/^https?:\/\//, "")}</span>
                    <ExternalLink className="w-3.5 h-3.5 text-foreground-dimmest dark:text-foreground-dark-dimmest " />
                  </a>
                )}
                {linkedin && (
                  <a
                    href={linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-1 text-sm text-primary hover:underline"
                  >
                    <span>{linkedin.replace(/^https?:\/\//, "")}</span>
                    <ExternalLink className="w-3.5 h-3.5 text-foreground-dimmest dark:text-foreground-dark-dimmest " />
                  </a>
                )}
                {github && (
                  <a
                    href={github}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-1 text-sm text-primary hover:underline"
                  >
                    <span>{github.replace(/^https?:\/\//, "")}</span>
                    <ExternalLink className="w-3.5 h-3.5 text-foreground-dimmest dark:text-foreground-dark-dimmest " />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showEdit}
        setShow={(state) => setShowEdit(state)}
        title="Edit Profile"
      >
        <EditProfile existingInfo={user_data} />
      </Modal>
    </div>
  );
};

export default ProfileHeader;
