import React from "react";
import MeshMountain from "../../assets/img/landing/mesh-mountain.png";
// import Amazon from "../../assets/img/companies/amazon.png";
// import Citadel from "../../assets/img/companies/citadel.png";
// import Goldman from "../../assets/img/companies/goldman.png";
// import JaneStreet from "../../assets/img/companies/jane-street.png";
// import JPM from "../../assets/img/companies/jpm.png";
// import Microsoft from "../../assets/img/companies/microsoft.png";
// import Point72 from "../../assets/img/companies/point72.png";
// import Sig from "../../assets/img/companies/sig.png";
import {
  // CompanyCard,
  LandingButtonHollow,
  LandingButtonPrimary,
} from "../../components/LandingComponents";
import { useNavigate } from "react-router-dom";

const HeroSection: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="relative min-h-screen">
      {/* Background Image */}
      <div className="absolute inset-0 w-full h-full z-0 bg-landing-background">
        <img
          src={MeshMountain}
          alt="Background pattern"
          className="absolute w-full h-full object-cover bg-transparent object-[0px_50px] opacity-70"
        />
        <div className="absolute bottom-0 left-0 w-full h-64 pointer-events-none z-10 bg-gradient-to-b from-transparent to-landing-background"></div>
      </div>

      {/* Hero Content */}
      <div className="relative z-10 max-w-7xl mx-auto px-4 pt-40">
        <div className="z-10 max-w-2xl flex flex-col gap-8">
          <h1 className="text-6xl font-bold">
            <span className="text-background">
              Your Dream Tech Career Starts
            </span>{" "}
            <span className="text-accent-primary">Here.</span>
          </h1>
          <p className="text-background-dimmest font-light text-xl max-w-xl">
            More than a job platform—we're your career partner. Evaluate your
            skills, discover growth opportunities, and get matched with roles
            that truly fit you.
          </p>
          <div className="flex gap-3">
            <LandingButtonPrimary
              message="Get Started"
              handleClick={() => navigate("account/signup")}
            ></LandingButtonPrimary>
            <LandingButtonHollow
              message="Discover Your Career Path"
              handleClick={() => navigate("account/signup")}
            ></LandingButtonHollow>
          </div>
        </div>
      </div>
      {/* <div className="relative overflow-hidden mt-48 h-32">
        <div className="absolute top-0 left-0 flex justify-around animate-marquee m-0 p-0">
          {[
            Citadel,
            Amazon,
            Goldman,
            JaneStreet,
            JPM,
            Microsoft,
            Sig,
            Point72,
          ].map((logo, index) => (
            <CompanyCard logoPath={logo}></CompanyCard>
          ))}
        </div>
        <div className="absolute top-0 left-0 flex justify-around animate-marquee2 m-0 p-0">
          {[
            Citadel,
            Amazon,
            Goldman,
            JaneStreet,
            JPM,
            Microsoft,
            Sig,
            Point72,
          ].map((logo, index) => (
            <CompanyCard logoPath={logo}></CompanyCard>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default HeroSection;
