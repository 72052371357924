import { useNavigate } from "react-router-dom";
import { LandingButtonPrimary } from "../../components/LandingComponents";

const RecruitmentEndSection = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-500px lg:h-[700px] flex flex-col items-center justify-center gap-16">
      <div className="flex flex-col gap-8">
        <h2 className="text-6xl text-background font-bold text-center">
          Build Your Career with <br />
          <span className="text-accent-primary">
            Clarity, Connection,{" "}
          </span> and <span className="text-accent-primary">Growth</span>
        </h2>
        <h4 className="text-background-dimmer text-xl font-light text-center">
          Discover how AlgoLink transforms Your Career Journey Today.
        </h4>
      </div>
      <LandingButtonPrimary
        handleClick={() => navigate("account/signup")}
        message="Get Started"
      ></LandingButtonPrimary>
    </div>
  );
};

export default RecruitmentEndSection;
