import { useEffect, useState, ChangeEvent, FormEvent, FC } from "react";
import { useNavigate } from "react-router-dom";
import axiosInterceptor from "../../utils/axiosInterceptor";
import backgroundImage from "../../assets/img/landing/login-background.png";
import {
  BLANK_LOGIN,
  LoginErrors,
  LoginFields,
  LoginModal,
} from "../../components/LandingComponents";
import TopNavigation from "../home/TopNavigation";

const LogIn: FC = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState<LoginFields>(BLANK_LOGIN);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [errors, setErrors] = useState<LoginErrors>({
    email: false,
    password: false,
  });

  useEffect(() => {
    const isAuthenticated = !!localStorage.getItem("access_token");
    if (isAuthenticated) {
      navigate("/");
    }
  }, [navigate]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setLogin({ ...login, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");
    const newErrors: LoginErrors = {
      email: !login.email,
      password: !login.password,
    };
    setErrors(newErrors);

    if (newErrors.email || newErrors.password) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
    try {
      const response = await axiosInterceptor.post(`/login/`, login, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken || "",
        },
      });

      const refreshToken = response.data["refresh"];
      const accessToken = response.data["access"];
      const userID = response.data["UserID"];
      const userName =
        response.data["firstname"] + " " + response.data["lastname"];
      const is_email_verified = response.data["is_email_verified"];
      const is_verified_by_algolink = response.data["is_verified_by_algolink"];
      const is_algolink_admin = response.data["is_algolink_admin"];

      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
      localStorage.setItem("user_id", userID);
      localStorage.setItem("is_email_verified", is_email_verified);
      localStorage.setItem("is_algolink_admin", is_algolink_admin);
      localStorage.setItem("is_verified_by_algolink", is_verified_by_algolink);
      localStorage.setItem("user_name", userName);

      navigate("../../");
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Incorrect username or password. Please try again.");
      setErrors({ email: true, password: true });
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-landing-background relative">
      {/* Background with particles effect */}
      <TopNavigation showCompleteNavigation={false} />
      <div
        className="absolute inset-0 z-0 bg-no-repeat bg-center opacity-20"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "50% auto",
        }}
      ></div>

      <div className="relative z-10 flex flex-col flex-grow items-center justify-center">
        <LoginModal
          email={{ value: login.email, hasError: errors.email }}
          password={{ value: login.password, hasError: errors.password }}
          rememberMeChecked={rememberMe}
          errorMessage={errorMessage}
          handleRememberMeChange={(value) => setRememberMe(value)}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        ></LoginModal>
      </div>
    </div>
  );
};

export default LogIn;
