import { AggregatedAssessmentExtended, TestOption } from "./interfaces";
import { testCenterIcons } from "../../icons";

export const dummyAggregatedAssessment: AggregatedAssessmentExtended = {
  // ============== BACKGROUND ==============
  background: {
    software: [
      {
        name: "Machine Learning Engineer",
        rating: 95,
        recommended: true,
        selected: true,
      },
      {
        name: "Software Engineer",
        rating: 93,
        recommended: true,
        selected: true,
      },
      {
        name: "Cloud Solutions Architect",
        rating: 90,
        recommended: true,
        selected: false,
      },
      {
        name: "DevOps Engineer",
        rating: 88,
        recommended: true,
        selected: false,
      },
      {
        name: "AI Research Scientist",
        rating: 92,
        recommended: true,
        selected: true,
      },
    ],
    data: [
      {
        name: "Data Scientist",
        rating: 91,
        recommended: true,
        selected: true,
      },
      {
        name: "Data Engineer",
        rating: 89,
        recommended: true,
        selected: false,
      },
      {
        name: "Business Intelligence Analyst",
        rating: 85,
        recommended: true,
        selected: false,
      },
      {
        name: "Quantitative Researcher",
        rating: 88,
        recommended: true,
        selected: true,
      },
    ],
    finance: [
      {
        name: "Quantitative Trader",
        rating: 90,
        recommended: true,
        selected: true,
      },
      {
        name: "Financial Engineer",
        rating: 87,
        recommended: true,
        selected: false,
      },
      {
        name: "Risk Manager",
        rating: 84,
        recommended: true,
        selected: false,
      },
      {
        name: "Investment Banking Analyst",
        rating: 82,
        recommended: false,
        selected: false,
      },
    ],
    engineering: [
      {
        name: "Hardware Engineer",
        rating: 86,
        recommended: true,
        selected: false,
      },
      {
        name: "Robotics Engineer",
        rating: 88,
        recommended: true,
        selected: true,
      },
      {
        name: "Aerospace Engineer",
        rating: 83,
        recommended: true,
        selected: false,
      },
      {
        name: "Systems Engineer",
        rating: 85,
        recommended: true,
        selected: false,
      },
    ],
  },

  // ============== BEHAVIORAL ==============
  behavioral: {
    management: {
      Leadership: {
        rating: 90,
        recommended: true,
        selected: true,
        fraction: 60,
      },
      Initiative: {
        rating: 80,
        recommended: true,
        selected: false,
        fraction: 0,
      },
      Motivation: {
        rating: 85,
        recommended: false,
        selected: false,
        fraction: 0,
      },
    },
    expression: {
      Communication: {
        rating: 88,
        recommended: true,
        selected: true,
        fraction: 40,
      },
      Teamwork: {
        rating: 85,
        recommended: true,
        selected: false,
        fraction: 0,
      },
      "Decision Making": {
        rating: 73,
        recommended: false,
        selected: false,
        fraction: 0,
      },
    },
    resilience: {
      Adaptability: {
        rating: 60,
        recommended: false,
        selected: false,
        fraction: 0,
      },
      "Problem Solving": {
        rating: 65,
        recommended: true,
        selected: false,
        fraction: 0,
      },
      Responsibility: {
        rating: 70,
        recommended: false,
        selected: false,
        fraction: 0,
      },
    },
    other: {
      Integrity: {
        rating: 89,
        recommended: true,
        selected: false,
        fraction: 0,
      },
      "Ability to Work Under Pressure": {
        rating: 73,
        recommended: false,
        selected: false,
        fraction: 0,
      },
      "Attention to Detail": {
        rating: 65,
        recommended: false,
        selected: false,
        fraction: 0,
      },
      "Time Management": {
        rating: 69,
        recommended: false,
        selected: false,
        fraction: 0,
      },
    },
  },

  // ============== TECHNICAL ==============
  technical: {
    group1: [
      {
        name: "AI",
        rating: 90,
        recommended: true,
        selected: true,
        fraction: 50,
      },
      {
        name: "Computer Science",
        rating: 95,
        recommended: true,
        selected: true,
        fraction: 50,
      },
      {
        name: "Data Science",
        rating: 85,
        recommended: false,
        selected: false,
        fraction: 0,
      },
      {
        name: "Software",
        rating: 92,
        recommended: true,
        selected: false,
        fraction: 0,
      },
      {
        name: "Tools",
        rating: 88,
        recommended: false,
        selected: false,
        fraction: 0,
      },
    ],
    group2: [
      {
        name: "Electrical",
        rating: 60,
        recommended: false,
        selected: false,
        fraction: 0,
      },
      {
        name: "Hardware",
        rating: 55,
        recommended: false,
        selected: false,
        fraction: 0,
      },
      {
        name: "Networks",
        rating: 40,
        recommended: false,
        selected: false,
        fraction: 0,
      },
      {
        name: "Physical",
        rating: 50,
        recommended: false,
        selected: false,
        fraction: 0,
      },
    ],
    group3: [
      {
        name: "Finance",
        rating: 70,
        recommended: true,
        selected: false,
        fraction: 0,
      },
      {
        name: "Economics",
        rating: 75,
        recommended: false,
        selected: false,
        fraction: 0,
      },
      {
        name: "Quantitative Finance",
        rating: 80,
        recommended: true,
        selected: false,
        fraction: 0,
      },
      {
        name: "Design",
        rating: 35,
        recommended: false,
        selected: false,
        fraction: 0,
      },
    ],
    group4: [
      {
        name: "Biology",
        rating: 45,
        recommended: false,
        selected: false,
        fraction: 0,
      },
      {
        name: "Biomedical",
        rating: 50,
        recommended: false,
        selected: false,
        fraction: 0,
      },
      {
        name: "Chemistry",
        rating: 55,
        recommended: false,
        selected: false,
        fraction: 0,
      },
      {
        name: "Physics",
        rating: 85,
        recommended: true,
        selected: false,
        fraction: 0,
      },
    ],
  },
};

export const testOptions: TestOption[] = [
  {
    title: "Technical",
    duration: "60 mins",
    route: "/test-center/technical-home",
    icon: testCenterIcons.testOptions.technical,
  },
  {
    title: "Background",
    duration: "30 mins",
    route: "/test-center/background",
    icon: testCenterIcons.testOptions.background,
  },
  {
    title: "Behavioral",
    duration: "30 mins",
    route: "/test-center/behavioral",
    icon: testCenterIcons.testOptions.behavioral,
  },
];
