/**
 * interfaces.ts
 * Central file for all type definitions, interfaces, and constants.
 */

//
// Company interface
//
export interface Company {
  id: number;
  company_name: string;
  domain: string;
  industry: string;
  location: string;
  company_size: string;
  company_description: string;
  contact_email: string;
}

//
// Job interface
//
export interface Job {
  id: number;
  company?: number;  // Added to link jobs to companies
  title: string;
  city: string;
  timestamp: string;
  compensationLowerBound: number;
  compensationUpperBound: number;
  description: string;
  jobStartDate: string;
  // Custom field for interviews:
  interviewingStartDate?: string;
  eligible_for_visa_sponsorship: boolean;
  status: string; // e.g. "active" or "archived"
  num_users?: number; // how many applicants, etc.

  benefits?: string;
  rounds?: {
    id: number;
    name: string;
  }[];

  // user-specific data
  user_pipeline_status?: string; // e.g., "New Matches", "Messages", etc.
  user_rating_of_job?: number; // e.g., 1, 3, 5

  // If referencing child structures, e.g.:
  company_details?: {
    company_name: string;
    industry: string;
    company_description: string;
    company_size: string;
  };
}

//
// MergedCompanyJobsSection Props
//
export interface MergedCompanyJobsSectionProps {
  jobs: Job[];
  onRateJob: (jobId: number, rating: number) => Promise<void>;
}

//
// A helper interface to decorate jobs with the consolidated category and a special flag
//
export interface ConsolidatedJob extends Job {
  consolidatedCategory: ConsolidatedCategory;
  hasStar: boolean;
}

//
// Consolidated categories for the simpler UI
//
export type ConsolidatedCategory =
  | "Matches"          // includes old "New Matches" + "Messages"
  | "Recommended"      // includes old "Recommended" + "Likes You"
  | "Not Interested"
  | "Interested"       // now just for rating 3
  | "Referral";        // includes old "Referral" + "Offer"

//
// The default order of job categories in the new simplified UI
//
export const CONSOLIDATED_CATEGORY_ORDER: ConsolidatedCategory[] = [
  "Recommended",
  "Interested",
  "Not Interested",
  "Matches",
  "Referral",
];

//
// SearchParams interface
//
export interface SearchParams {
  companyName: string;
  industry: string;
  location: string;
}