import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CameraRecorder from "./CameraRecorder";
import Webcam from "react-webcam";
import { Question } from "../interfaces";
import { DefaultButton, LightNegativeButton } from "../../../components/Button";

interface InterviewTemplateProps {
  type: string;
  questions: Question[];
}

interface CameraRecorderRef {
  stopAndDownload: () => void;
}

export default function InterviewTemplate({
  type,
  questions,
}: InterviewTemplateProps): JSX.Element {
  const navigate = useNavigate();
  const cameraRecorderRef = useRef<CameraRecorderRef | null>(null);
  const [isQuestionsBegun, setIsQuestionsBegun] = useState<boolean>(false);
  const [questionsAsked, setQuestionsAsked] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1);
  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  // For progress calculations
  const numQuestionsToAsk = questionsAsked.length;

  useEffect(() => {
    setQuestionsAsked(questions);
  }, [questions]);

  const startInterview = () => {
    setCurrentQuestionIndex(0);
    setIsQuestionsBegun(true);
  };

  const handleExitClick = () => {
    setShowExitModal(true);
  };

  const handleCloseExitModal = () => {
    setShowExitModal(false);
  };

  const handleConfirmExit = async () => {
    handleCloseExitModal();
    await exitPage();
  };

  const handleNextQuestion = async () => {
    try {
      if (cameraRecorderRef.current) {
        await cameraRecorderRef.current.stopAndDownload();
      }
      if (currentQuestionIndex === questionsAsked.length - 1) {
        navigate("/test-center/home");
      } else {
        setCurrentQuestionIndex((prev) => prev + 1);
      }
    } catch (err) {
      console.error("Question transition error:", err);
      // Implement error handling as needed
    }
  };

  const exitPage = async () => {
    try {
      if (cameraRecorderRef.current) {
        await cameraRecorderRef.current.stopAndDownload();
      }
      navigate("/test-center/home");
    } catch (err) {
      console.error("Exit error:", err);
    }
  };

  return (
    <div className="min-h-screen bg-background-root dark:bg-background-dark-root text-foreground dark:text-foreground-dark p-4">
      {/* Header Section */}
      <div className="max-w-7xl mx-auto px-8 pt-8 pb-16">
        <div className="mb-8">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h1 className="text-2xl font-semibold">Test Center</h1>
              <p className="text-sm text-foreground-dimmest dark:text-foreground-dimmest">
                Practice for your interviews!
              </p>
            </div>
            <div
              className="relative rounded-full"
              style={{ width: "150px", height: "150px" }}
            >
              <CameraRecorder
                isTechnical={true}
                rounded
                moreClasses="w-full h-full"
                ref={cameraRecorderRef}
                ids={[currentQuestionIndex + 1]}
                type={type}
              />
            </div>
            <div className="flex gap-4">
              <button
                onClick={handleNextQuestion}
                className="px-6 py-2 rounded-full bg-accent-primary text-foreground dark:text-foreground-dark hover:bg-accent-primary-dimmer  transition-colors"
              >
                {currentQuestionIndex === questionsAsked.length - 1
                  ? "Submit Test"
                  : "Next Question"}
              </button>
              <button
                onClick={handleExitClick}
                className="px-6 py-2 rounded-full border border-red-500 text-red-500 hover:bg-red-600 transition-colors"
              >
                Exit Interview
              </button>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <div className="h-2 w-48 bg-[#333333] rounded-full overflow-hidden">
              <div
                className="h-full bg-green-500 transition-all duration-300"
                style={{
                  width: `${((currentQuestionIndex + 1) / numQuestionsToAsk) * 100}%`,
                }}
              />
            </div>
            <span className="text-sm text-foreground-dimmest dark:text-foreground-dimmest">
              {isQuestionsBegun
                ? `${currentQuestionIndex + 1}/${questionsAsked.length} Questions`
                : "Test not started yet"}
            </span>
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-6">
          {isQuestionsBegun ? (
            currentQuestionIndex < questionsAsked.length ? (
              <>
                {/* Question Section */}
                <p className="text-md text-foreground-dimmest dark:text-foreground-dimmest">
                  <b className="text-foreground dark:text-foreground-dark">
                    Question {currentQuestionIndex + 1}:{" "}
                  </b>
                  {questionsAsked[currentQuestionIndex].question}
                </p>

                {/* Camera Recorder Section */}
                <div className="w-full max-w-2xl mx-auto">
                  <CameraRecorder
                    isBackground={true}
                    ref={cameraRecorderRef}
                    ids={questionsAsked.map((question) => Number(question.id))}
                    type={type}
                  />
                </div>
              </>
            ) : (
              <p className="text-xl font-medium">
                You have completed all questions!
              </p>
            )
          ) : (
            <>
              {/* Instructions Section */}
              <p className="text-md text-foreground-dimmest dark:text-foreground-dimmest max-w-4xl">
                <b className="text-foreground dark:text-foreground-dark">
                  Instructions:{" "}
                </b>
                You will be given a set of {type.toLowerCase()} interview
                questions. Please answer each question within the time limit
                using the STAR response format. Submit each answer when you are
                done.
              </p>
              {/* Camera Preview */}
              <div className="w-full max-w-2xl mx-auto">
                <Webcam
                  className="rounded-lg w-full"
                  videoConstraints={{ facingMode: "user" }}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {/* Exit Modal */}
      {showExitModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-background-root dark:bg-background-dark-root rounded-xl p-6 max-w-md w-full mx-4 ">
            <h3 className="text-2xl font-medium mb-4 text-foreground dark:text-foreground-dark">
              Are you sure?
            </h3>
            <p className="text-md text-foreground-dimmest dark:text-foreground-dimmest mb-6">
              Exiting the test will end your interview and cause you to lose all
              your progress!
            </p>
            <div className="flex justify-end gap-4">
              <DefaultButton
                handleClick={handleCloseExitModal}
                message="Cancel"
              />
              <LightNegativeButton
                handleClick={handleConfirmExit}
                message="I'm Sure"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function setError(arg0: string): void {
  // Stub: implement error handling if needed.
  console.error(arg0);
}
