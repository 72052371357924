import React, { useState, useEffect } from "react";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import { PDFViewerProps, Document } from "../interfaces";

const PDFViewer: React.FC<PDFViewerProps> = ({
  userId,
  documentType,
  refreshTrigger = 0,
}) => {
  const [docs, setDocs] = useState<Document[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  let filepath = `user_career_data/user_id=${userId}/resumes/`;
  if (documentType === "Transcript") {
    filepath = `user_career_data/user_id=${userId}/transcripts/`;
  }

  useEffect(() => {
    const fetchDocs = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInterceptor.get<{
          private_docs: Document[];
        }>(`/serve_private_doc/${filepath}`);
        setDocs(response.data.private_docs);
      } catch (err) {
        console.error("Error fetching presigned URL:", err);
        setError("Failed to fetch PDF link");
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocs();
  }, [filepath, refreshTrigger]);

  return (
    <div className="flex flex-col gap-y-2">
      {error && <p className="font-normal text-red-500">{error}</p>}
      {isLoading ? (
        <p className="text-sm text-gray-500">Loading...</p>
      ) : docs && docs.length > 0 ? (
        docs.map((doc, index) => (
          <a
            key={index}
            href={doc[0]}
            target="_blank"
            rel="noopener noreferrer"
            className="text-base text-blue-500 hover:underline"
          >
            {doc[1]}
          </a>
        ))
      ) : (
        <p className="font-normal text-gray-700">
          No {documentType.toLowerCase()} uploaded
        </p>
      )}
    </div>
  );
};

export { PDFViewer };
