import { FC } from "react";

interface TestimonialItem {
  quote: string;
  name: string;
  title: string;
  imageSrc?: string;
}

interface InfiniteMovingCardsProps {
  items: TestimonialItem[];
}

const TestimonialsSection: FC = () => {
  const testimonials: TestimonialItem[] = [
    {
      quote:
        "The Profile feature really gets to know me. It understands my background, personality, and job preferences to give me personalized recommendations.",
      name: "Emily Johnson",
      title: "Software Developer @ Meta",
    },
    {
      quote:
        "The Test Center is a game-changer. It let me prove my skills in STEM, design, and finance at my own pace, helping me shine in the areas I'm strongest in.",
      name: "Michael Smith",
      title: "Data Analyst @ Amazon",
    },
    {
      quote:
        "The Courses sync perfectly with the Test Center, showing me clear steps to improve my skills and progress toward my goals. It's incredibly motivating!",
      name: "Sophia Lee",
      title: "Product Designer @ Airbnb",
    },
    {
      quote:
        "The Jobs page makes job hunting so easy! It's like a dating app but for careers. Sorting through jobs and matching with recruiters has never been this fun.",
      name: "Liam Brown",
      title: "Quantitative Trader @ Two Sigma",
    },
    {
      quote:
        "Messages is such a friendly feature! It helped me turn matches into real referrals and interviews with recruiters and managers, all while keeping communication stress-free.",
      name: "Olivia Davis",
      title: "ML Engineer @ OpenAI",
    },
  ];

  return (
    <div className="relative w-screen overflow-hidden flex flex-col items-center justify-center">
      <h2 className="text-5xl font-semibold text-center mb-12 text-[#FFFFFF]">
        Here's What Our Candidates Say
      </h2>
      <InfiniteMovingCards items={testimonials} />
    </div>
  );
};

const InfiniteMovingCards: React.FC<InfiniteMovingCardsProps> = ({ items }) => {
  return (
    <div className="relative w-full h-[500px] group">
      <div className="absolute w-min flex gap-4 justify-between animate-marquee group-hover:animation-paused pr-6">
        {items.map((item, idx) => (
          <TestimonialCard
            quote={item.quote}
            name={item.name}
            title={item.title}
            key={idx}
          ></TestimonialCard>
        ))}
      </div>
      <div className="absolute w-min flex justify-between gap-4 animate-marquee2 group-hover:animation-paused pr-6">
        {items.map((item, idx) => (
          <TestimonialCard
            quote={item.quote}
            name={item.name}
            title={item.title}
            key={idx}
          ></TestimonialCard>
        ))}
      </div>
    </div>
  );
};

interface TestimonialCardProps {
  quote: string;
  name: string;
  title: string;
}

const TestimonialCard: FC<TestimonialCardProps> = ({ quote, name, title }) => {
  return (
    <div className="w-[400px] max-w-full flex-shrink-0">
      <div
        className="bg-neutral-50 bg-opacity-0 hover:bg-opacity-10 backdrop-blur rounded-xl p-8 h-full transition-all duration-300 hover:scale-105"
        style={{
          borderWidth: "0.5px",
          borderStyle: "solid",
          borderColor: "rgba(255, 255, 255, 0.5)",
        }}
      >
        <div className="flex flex-col h-full">
          <p className="text-[#FFFFFF] text-xl font-light mb-6 flex-grow">
            "{quote}"
          </p>
          <div className="flex items-center gap-3 pt-4">
            <div className="min-w-10 min-h-10 rounded-full bg-accent-primary flex items-center justify-center text-foreground dark:text-foreground-dark font-medium">
              {name[0]}
            </div>
            <div>
              <p className="text-lg text-[#FFFFFF] font-normal text-nowrap">
                {name}
              </p>
              <p className="text-lg text-foreground-dimmest dark:text-foreground-dark-dimmest font-light text-nowrap">
                {title}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
