/**
 * JobRequirementsDisplay.tsx
 * Component for displaying job requirements with color coding
 * Uses the same dummy data as the original code
 */

import React from "react";

// Defining requirement interfaces
interface Requirement {
  name: string;
  criticality: "Critical" | "Important" | "Helpful";
  type: "behavioral" | "background" | "technical";
  category?: string; // Optional category for specialized colorization
}

interface JobRequirementsProps {
  requirements: Requirement[];
}

// Default requirements data from the original code
export const dummyJobRequirements: Requirement[] = [
  // Technical skills
  {
    name: "Machine Learning",
    type: "technical",
    criticality: "Critical",
    category: "AI",
  },
  { name: "Python", type: "technical", criticality: "Critical" },
  { name: "Data Science", type: "technical", criticality: "Critical" },
  {
    name: "Deep Learning",
    type: "technical",
    criticality: "Critical",
    category: "AI",
  },

  // Background skills
  {
    name: "Cloud Solutions Architect",
    type: "background",
    criticality: "Critical",
    category: "Software",
  },
  { name: "Research Experience", type: "background", criticality: "Critical" },
  { name: "Project Leadership", type: "background", criticality: "Critical" },

  // Behavioral skills
  { name: "Leadership", type: "behavioral", criticality: "Critical" },
  { name: "Initiative", type: "behavioral", criticality: "Critical" },
  {
    name: "Motivation",
    type: "behavioral",
    criticality: "Critical",
    category: "Resilience",
  },
];

const JobRequirementsDisplay: React.FC<JobRequirementsProps> = ({
  requirements,
}) => {
  // Group requirements by type
  const backgroundRequirements = requirements.filter(
    (req) => req.type === "background",
  );
  const technicalRequirements = requirements.filter(
    (req) => req.type === "technical",
  );
  const behavioralRequirements = requirements.filter(
    (req) => req.type === "behavioral",
  );

  // Color coding function based on Figma design
  const getColorClasses = (req: Requirement): string => {
    const baseClasses = "px-3 py-1.5 text-sm rounded-full"; // All tags use rounded-full (pill shape)

    // Background skills - red
    if (req.type === "background") {
      return `${baseClasses} bg-opacity-40 bg-[#FF3131] border-[1px] border-[#FF0000] text-white`;
    }

    // Technical skills - blue
    if (req.type === "technical") {
      if (req.category === "AI") {
        // Slightly different blue for AI
        return `${baseClasses} bg-opacity-40 bg-[#0032DF] border-[1px] border-[#0022FF] text-white`;
      }
      return `${baseClasses} bg-opacity-40 bg-[#117775] border-[1px] border-[#00C7BE] text-white`;
    }

    // Behavioral skills - purple
    if (req.type === "behavioral") {
      if (req.category === "Resilience") {
        return `${baseClasses} bg-opacity-40 bg-[#BD00FF] border-[1px] border-[#9900FF] text-white`;
      }
      return `${baseClasses} bg-opacity-40 bg-[#4B2CFF] border-[1px] border-[#4B2CFF] text-white`;
    }

    // Default fallback
    return `${baseClasses} bg-opacity-40 bg-[#27272F] border-[1px] border-[#505061] text-white`;
  };

  return (
    <div className="space-y-4">
      {backgroundRequirements.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {backgroundRequirements.map((req) => (
            <span key={req.name} className={getColorClasses(req)}>
              {req.name}
            </span>
          ))}
        </div>
      )}

      <h3 className="text-base font-semibold mt-4 mb-2">Technical:</h3>
      {technicalRequirements.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {technicalRequirements.map((req) => (
            <span key={req.name} className={getColorClasses(req)}>
              {req.name}
            </span>
          ))}
        </div>
      )}

      <h3 className="text-base font-semibold mt-4 mb-2">Behavioral:</h3>
      {behavioralRequirements.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {behavioralRequirements.map((req) => (
            <span key={req.name} className={getColorClasses(req)}>
              {req.name}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default JobRequirementsDisplay;
