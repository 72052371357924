/**
 * jobUtils.ts
 * Utility functions for job data manipulation
 */

import { Job, ConsolidatedJob, ConsolidatedCategory } from "../interfaces";

/**
 * Deduce the final "consolidated" category and star-flag from pipeline status and rating.
 * This function preserves the original categorization logic from MergedCompanyJobsSection.tsx
 */
export function mapToConsolidatedJob(job: Job): ConsolidatedJob {
  let consolidatedCategory: ConsolidatedCategory = "Recommended";
  let hasStar = false;

  // Pipeline overrides
  switch (job.user_pipeline_status) {
    case "Messages":
    case "New Matches":
      // Both go to "Matches". "Messages" has a star, "New Matches" doesn't
      consolidatedCategory = "Matches";
      if (job.user_pipeline_status === "Messages") {
        hasStar = true;
      }
      return { ...job, consolidatedCategory, hasStar };

    case "Likes You":
    case "Pending Invitations":
      // Both go to "Recommended". "Pending Invitations" has a star
      consolidatedCategory = "Recommended";
      if (job.user_pipeline_status === "Pending Invitations") {
        hasStar = true;
      }
      return { ...job, consolidatedCategory, hasStar };

    case "Referral":
    case "Accepted":
      // Both go to "Referral". "Accepted" has a star
      consolidatedCategory = "Referral";
      if (job.user_pipeline_status === "Accepted") {
        hasStar = true;
      }
      return { ...job, consolidatedCategory, hasStar };

    default:
      // If pipeline status isn't recognized, fall back to rating
      break;
  }

  // Fallback rating logic
  switch (job.user_rating_of_job) {
    case 1:
      consolidatedCategory = "Not Interested";
      break;
    case 3:
      consolidatedCategory = "Interested";
      break;
    case 5:
      consolidatedCategory = "Interested";
      hasStar = true;
      break;
    default:
      consolidatedCategory = "Recommended";
  }

  return { ...job, consolidatedCategory, hasStar };
}