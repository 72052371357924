import { useState, useEffect, useCallback, useRef, UIEvent } from "react";
import Ably from "ably";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { Search, Send } from "lucide-react";
import Avatar from "boring-avatars";
import {
  Button,
  Modal,
  Dropdown,
  Stack,
  OverlayTrigger,
  Popover,
  Form,
} from "react-bootstrap";
import { DropdownToggle } from "../../components/DropdownToggle";
import { Channel, Message, TokenRequest } from "./interfaces";
import "../profile/CustomModal.css"; // For dark-modal styling, etc.

export default function Chat() {
  // State management
  const [chatsPending, setChatsPending] = useState<boolean>(true);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [companies, setCompanies] = useState<string[][]>([]);
  const [roles, setRoles] = useState<string[][]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [channelsPending, setChannelsPending] = useState<boolean>(true);
  const [currentChannel, setCurrentChannel] =
    useState<Ably.RealtimeChannel | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageText, setMessageText] = useState<string>("");
  const [realtime, setRealtime] = useState<Ably.Realtime | null>(null);
  const [currentUserID, setCurrentUserID] = useState<string>(
    localStorage.getItem("user_id") || "",
  );
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  // Refs for scrolling
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesStartRef = useRef<HTMLDivElement>(null);

  // Modal state
  const [showNewChatModal, setShowNewChatModal] = useState<boolean>(false);
  const [availableChannels, setAvailableChannels] = useState<Channel[]>([]);

  const [showCompanyDropdown, setShowCompanyDropdown] =
    useState<boolean>(false);
  const [showRoleDropdown, setShowRoleDropdown] = useState<boolean>(false);

  // Initialize Ably Realtime
  useEffect(() => {
    const ably = new Ably.Realtime({
      authCallback: async (tokenParams, callback) => {
        try {
          const response = await axiosInterceptor.get(
            "/get_ably_token_request/",
          );
          const tokenRequest: TokenRequest = response.data;
          setCurrentUserID(tokenRequest.clientId);
          callback(null, tokenRequest);
          setChatsPending(false);
        } catch (err: any) {
          console.error("Error getting Ably token:", err);
          callback(err, null);
        }
      },
    });

    ably.connection.once("connected", () => {
      console.log("Connected to Ably");
      setRealtime(ably);
    });

    return () => {
      ably.connection.close();
    };
  }, []);

  // Fetch channels and companies when user ID or Realtime changes
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await axiosInterceptor.get(
          `/get_user_channels/${currentUserID}`,
        );
        const allChannels: Channel[] = response.data;

        const activeChannels = allChannels.filter(
          (channel) => channel.last_message && channel.last_message.length > 0,
        );
        const available = allChannels.filter(
          (channel) => !channel.last_message,
        );

        setChannels(activeChannels);
        setAvailableChannels(
          available.map((channel) => ({
            ...channel,
            last_message: "",
          })),
        );
        setChannelsPending(false);
      } catch (error) {
        console.error("Error fetching channels:", error);
      }
    };

    const fetchCompanies = async () => {
      const companyNames: string[][] = [];
      try {
        const response = await axiosInterceptor.get(
          `/get_user_matched_companies/${currentUserID}`,
        );
        companyNames.push(response.data);
        setCompanies(companyNames);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    const fetchRoles = async () => {
      const roleNames: string[][] = [];
      try {
        const response = await axiosInterceptor.get(
          `/get_user_matched_roles/${currentUserID}`,
        );
        roleNames.push(response.data);
        setRoles(roleNames);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    if (currentUserID && realtime) {
      fetchChannels();
      fetchCompanies();
      fetchRoles();
    }
  }, [currentUserID, realtime]);

  // Fetch channel history when currentChannel changes
  useEffect(() => {
    if (currentChannel) {
      setOffset(0);
      setLimit(50);
      setHasMore(true);
      fetchChannelHistory(currentChannel.name, 0, 50);
      // Polling for new messages every 5 min
      setInterval(
        () => fetchChannelHistory(currentChannel.name, 0, 50),
        300000,
      );
    }
  }, [currentChannel]);

  // Join a channel
  const joinChannel = useCallback(
    (channelId: string) => {
      console.log("Joining channel:", channelId);
      if (!realtime) {
        console.error("Realtime is not initialized");
        return;
      }

      // If already in the desired channel, do nothing
      if (currentChannel && currentChannel.name === channelId) {
        console.log("Already in this channel");
        return;
      }

      // Unsubscribe from the current channel if any
      if (currentChannel) {
        console.log("Unsubscribing from current channel");
        currentChannel.unsubscribe();
        realtime.channels.get(currentChannel.name)?.detach();
      }

      // Reset message-related states
      setMessages([]);
      setOffset(0);
      setLimit(50);
      setHasMore(true);

      // Subscribe to the new channel
      const channel = realtime.channels.get(channelId);
      channel.subscribe("message", (message) => {
        console.log("Received message:", message);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: message.id || Math.random().toString(36).substr(2, 9),
            sender_type: (message.data as any).sender_type,
            message: (message.data as any).message,
            timestamp: new Date(message.timestamp),
          },
        ]);
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      });
      setCurrentChannel(channel);
      console.log("Set current channel:", channel);
    },
    [realtime, currentChannel],
  );

  // Fetch channel history
  const fetchChannelHistory = async (
    channelId: string,
    fetchOffset: number,
    fetchLimit: number,
  ) => {
    try {
      const response = await axiosInterceptor.get(
        `/get_messages_for_channel/${currentUserID}/${channelId.split(":")[1]}`,
      );
      setMessages(response.data);
      // If you want to track pagination more precisely, uncomment setHasMore(...) logic
      // setHasMore(response.data.length === fetchLimit);
    } catch (error) {
      console.error("Error fetching channel history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle scrolling to fetch more messages
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.currentTarget;
    if (scrollTop === 0 && !isLoading && hasMore && currentChannel) {
      const newOffset = offset + limit;
      setOffset(newOffset);
      fetchChannelHistory(currentChannel.name, newOffset, limit);
    }
  };

  // Send a message
  const sendMessage = async () => {
    if (messageText.trim() === "") return;
    if (!currentChannel) {
      console.error("No current channel selected");
      return;
    }

    const message = {
      message: messageText,
      sender_type: "user", // Keep consumer's 'user' type
      timestamp: new Date().toISOString(),
    };

    try {
      await axiosInterceptor.post(
        `/create_message_for_channel/${currentUserID}/${currentChannel.name.split(":")[1]}/`,
        message,
      );
      setMessageText("");
      await fetchChannelHistory(currentChannel.name, 0, 50);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // Start a new chat
  const startNewChat = async (channel: Channel) => {
    // Add this channel to the user’s active channel list
    setChannels((prevChannels) => [
      ...prevChannels,
      {
        id: channel.id,
        name: channel.name,
        last_message: "",
        company: channel.company,
        role: channel.role,
      },
    ]);
    setAvailableChannels((prevUsers) =>
      prevUsers.filter((user) => user.id !== channel.id),
    );
    joinChannel(channel.id);
    setShowNewChatModal(false);
  };

  // If chat data is still pending
  if (chatsPending) {
    return (
      <div className="flex justify-center items-center h-screen bg-background dark:bg-background-dark text-foreground-dimmer dark:text-foreground-dark-dimmer">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-[95vh] bg-background dark:bg-background-dark text-foreground-dimmer dark:text-foreground-dark-dimmer">
      {/* Channels/Chats list */}
      <div className="w-1/3 bg-background-root dark:bg-background-dark-root pr-6">
        <div className="flex items-center gap-2 py-2  px-3">
          <div className="relative max-w-sm w-full font-bold text-2xl">
            Messages
          </div>
          <i
            className="bi bi-send-plus text-lg text-foreground dark:text-foreground-dark hover:text-[#DDDDDD] cursor-pointer"
            onClick={() => setShowNewChatModal(true)}
          />
        </div>
        <Stack direction="horizontal" gap={4} className="flex mt-3 w-full px-3">
          <Dropdown onToggle={(isOpen) => setShowCompanyDropdown(isOpen)}>
            <DropdownToggle
              message={selectedCompany === "" ? "Company" : selectedCompany}
              isOpen={showCompanyDropdown}
            />
            <Dropdown.Menu className="dark-popover bg-[#222222]">
              <Dropdown.Item
                eventKey={"unfilter"}
                className="font-medium bg-[#222222]"
                onClick={() => setSelectedCompany("")}
              >
                Select
              </Dropdown.Item>
              {companies.map((company, index) => (
                <Dropdown.Item
                  key={index}
                  eventKey={`${index}`}
                  className="font-medium bg-[#222222]"
                  onClick={() => setSelectedCompany(company[0])}
                >
                  {company}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            onToggle={(isOpen) => setShowRoleDropdown(isOpen)}
            className="bg-[#222222]"
          >
            <DropdownToggle
              message={selectedRole === "" ? "Role" : selectedRole}
              isOpen={showRoleDropdown}
            />
            <Dropdown.Menu className="dark-popover overflow-auto bg-[#222222]">
              <Dropdown.Item
                eventKey={"unfilter"}
                className="font-medium bg-[#222222]"
                onClick={() => setSelectedRole("")}
              >
                Select
              </Dropdown.Item>
              {roles.map((role, index) => (
                <Dropdown.Item
                  key={index}
                  eventKey={`${index}`}
                  className="font-medium bg-[#222222]"
                  onClick={() => {
                    setSelectedRole(role[0]);
                  }}
                >
                  {role}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
        <Stack direction="horizontal" gap={2} className="flex mt-3 w-full px-3">
          <div className="font-semibold text-[#DDDDDD]">
            {availableChannels.length}{" "}
            {availableChannels.length === 1 ? "match" : "matches"}
          </div>
          <a className="text-accent-primary hover:text-[#A6C8FF] hover:cursor-pointer">
            View All
          </a>
        </Stack>

        <ul className="overflow-y-auto mt-4 min-h-20 px-3">
          {channelsPending ? (
            <div className="flex justify-center items-center text-foreground dark:text-foreground-dark">
              <div className="lds-ring lds-ring-smaller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            channels
              .filter((channel) => {
                const filterByCompany = selectedCompany !== "";
                const filterByRole = selectedRole.split("(")[0].trim() !== "";
                if (filterByCompany && filterByRole) {
                  return (
                    channel.company === selectedCompany &&
                    channel.role === selectedRole.split("(")[0].trim()
                  );
                } else if (filterByCompany) {
                  return channel.company === selectedCompany;
                } else if (filterByRole) {
                  return channel.role === selectedRole.split("(")[0].trim();
                }
                return true;
              })
              .map((channel) => (
                <li key={channel.id} className="mb-2">
                  <button
                    className={`w-full text-left p-4 rounded-md transition-colors ${
                      currentChannel && currentChannel.name === channel.id
                        ? "bg-[#222222]"
                        : "bg-background-root dark:bg-background-dark-roothover:bg-[#222222]"
                    }`}
                    onClick={() => joinChannel(channel.id)}
                  >
                    <div className="flex items-center flex-row gap-4">
                      <Avatar
                        name={channel.name}
                        variant="beam"
                        colors={["#003DF5", "#121416"]}
                        className="w-12 h-12 rounded-full"
                      />
                      <div>
                        <h3 className="font-medium text-foreground-dimmer dark:text-foreground-dark-dimmer">
                          {channel?.name?.replace("Chat with ", "")}
                        </h3>
                        <p className="text-sm text-foreground dark:text-foreground-dark">
                          {channel.last_message &&
                          channel.last_message.length > 30
                            ? channel.last_message.slice(0, 30) + "..."
                            : channel.last_message?.slice(0, 30)}
                        </p>
                      </div>
                    </div>
                  </button>
                </li>
              ))
          )}
        </ul>
      </div>

      {/* Chat messages */}
      <div className="w-2/3 flex flex-col bg-background dark:bg-background-dark ">
        {currentChannel ? (
          <>
            <h1 className="text-xl font-normal px-4 py-3 flex flex-row items-center justify-between text-foreground-dimmer dark:text-foreground-dark-dimmer border-b-[1px] border-b-[#383A40]">
              <div className="flex flex-row items-center gap-3">
                <Avatar
                  name={
                    channels.find((c) => c.id === currentChannel?.name)?.name ||
                    ""
                  }
                  width="50px"
                  height="50px"
                  variant="beam"
                  colors={["#003DF5", "#121416"]}
                  className="rounded-full"
                />
                {channels
                  .find((c) => c.id === currentChannel?.name)
                  ?.name.replace("Chat with ", "")}
              </div>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={channelDropdown}
              >
                <i className="bi bi-three-dots-vertical cursor-pointer text-foreground dark:text-foreground-dark hover:text-[#DDDDDD]"></i>
              </OverlayTrigger>
            </h1>

            <div
              className="flex-grow overflow-auto p-4"
              onScroll={handleScroll}
            >
              {isLoading && (
                <div className="flex justify-center items-center mb-4">
                  <div className="lds-ring lds-ring-smaller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}

              <div ref={messagesStartRef} />
              {messages.map((message) => (
                <div key={message.id}>
                  {message.showDate && (
                    <div className="w-full mx-auto text-center text-foreground dark:text-foreground-dark text-xs mb-2">
                      {message.timestamp.toLocaleString()}
                    </div>
                  )}
                  <div
                    className={`mb-2 flex items-center gap-2.5 ${
                      message.sender_type === "user"
                        ? "justify-end"
                        : "justify-start"
                    }`}
                  >
                    {message.sender_type !== "user" && (
                      <Avatar
                        name={message.sender_type}
                        width="40px"
                        height="40px"
                        variant="beam"
                        colors={["#003DF5", "#121416"]}
                        className="rounded-full"
                      />
                    )}
                    <div
                      className={`max-w-[70%] ring-1 ring-[#FFFFFF] rounded-xl px-3 py-1 text-wrap`}
                    >
                      <p className="text-[#DDDDDD]">{message.message}</p>
                    </div>
                    {message.sender_type === "user" && (
                      <Avatar
                        name={message.sender_type}
                        variant="beam"
                        width="40px"
                        height="40px"
                        colors={["#003DF5", "#121416"]}
                        className="rounded-full"
                      />
                    )}
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>

            <div className="p-4 border-t-[1px] border-t-[#383A40]">
              <div className="flex items-center">
                <input
                  type="text"
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && sendMessage()}
                  placeholder="Write a message..."
                  className="flex-1 rounded-full px-4 py-2 mr-2 bg-background-root dark:bg-background-dark-roottext-[#DDDDDD] ring-2 ring-[#383A40] focus:outline-none focus:ring-accent-primary"
                />
                <button
                  onClick={sendMessage}
                  className="bg-accent-primarytext-[#DDDDDD] rounded-full p-2 hover:bg-[#0d2f8e] flex items-center justify-center"
                >
                  <Send size={20} />
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex-grow flex-col flex items-center justify-center gap-y-3.5">
            <i
              className="bi bi-chat-left text-4xl text-foreground dark:text-foreground-dark"
              style={{
                WebkitTextStroke: "1.5px",
              }}
            ></i>
            <p className="text-foreground dark:text-foreground-dark">
              Select an existing chat or start a new one!
            </p>
            <Button
              variant="primary"
              className="rounded-full !p-2 text-sm bg-accent-primary text-[#DDDDDD] hover:bg-[#0d2f8e]"
              size="sm"
              onClick={() => setShowNewChatModal(true)}
            >
              <i className="bi bi-send-plus cursor-pointer mr-2 text-sm"></i>
              Start a new chat
            </Button>
          </div>
        )}
      </div>

      {/* New Chat Modal */}
      <Modal
        show={showNewChatModal}
        onHide={() => setShowNewChatModal(false)}
        contentClassName={
          "bg-background dark:bg-background-dark text-foreground dark:text-foreground-dark"
        }
        dialogClassName={"bg-background dark:bg-background-dark"}
      >
        <Modal.Header closeButton className="dark-modal-header">
          <Modal.Title className="text-foreground-dimmer dark:text-foreground-dark-dimmer">
            Matches
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="dark-modal-body mx-6">
          <ul className="space-y-6 w-full">
            {availableChannels.map((channel) => (
              <li
                key={channel.id}
                className="flex items-center w-full space-x-3"
              >
                <Avatar
                  name={channel.name}
                  variant="beam"
                  colors={["#003DF5", "#121416"]}
                  className="w-10 h-10 rounded-full"
                />
                <span className="text-[#DDDDDD]">
                  {channel?.name?.replace("Chat with ", "")}
                </span>
                <div className="flex-grow"></div>
                <div
                  className="flex items-center hover:bg-[#222222] rounded cursor-pointer size-10 justify-center text-center p-1"
                  onClick={() => startNewChat(channel)}
                  title="Start a new chat"
                >
                  <i className="bi bi-send-plus cursor-pointer text-xl text-accent-primary"></i>
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
}

// Channel dropdown (dark)
const channelDropdown = (
  <Popover id="popover-basic" className="dark-popover">
    <Popover.Body className="p-3 rounded-md text-[#DDDDDD]">
      <Form>
        <Form.Check type="checkbox">
          <Form.Check.Input
            type="checkbox"
            style={{ width: "1.25rem", height: "1.25rem", top: "0.5rem" }}
          />
          <Form.Check.Label className="ml-2 mt-[0.15rem] font-medium text-[#DDDDDD]">
            Referral Received
          </Form.Check.Label>
        </Form.Check>
        <Form.Check type="checkbox" className="mt-2">
          <Form.Check.Input
            type="checkbox"
            style={{ width: "1.25rem", height: "1.25rem", top: "0.5rem" }}
          />
          <Form.Check.Label className="ml-2 mt-[0.15em] font-medium text-[#DDDDDD]">
            Offer Received
          </Form.Check.Label>
        </Form.Check>
      </Form>
      <div className="mt-2">
        <div className="cursor-pointer font-medium text-[#DDDDDD] hover:text-foreground-dimmer dark:text-foreground-dark-dimmer hover:bg-background dark:bg-background-dark">
          View Profile
        </div>
        <div className="cursor-pointer font-medium text-[#DDDDDD] hover:text-foreground-dimmer dark:text-foreground-dark-dimmer hover:bg-background-root dark:bg-background-dark-rootmt-2">
          Mark Unread
        </div>
        <div className="cursor-pointer font-medium mt-2 hover:bg-background dark:bg-background-dark">
          <p className="text-[#FF0000] hover:text-[#FF3333]">Delete Chat</p>
        </div>
      </div>
    </Popover.Body>
  </Popover>
);
