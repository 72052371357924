// src/routes/profile/ChecklistCard.tsx
import React, { useState, useEffect } from "react";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { ChecklistCardProps, ChecklistItem } from "./interfaces";
import { ChevronDown, ChevronUp, ListChecks } from "lucide-react";

// ======================= ICON IMPORTS =======================
// For individual items (steps)
import CompleteIcon from "../../assets/icons/complete-circle.png";
import InProgressIcon from "../../assets/icons/in-progress-circle.png";
import NotStartedIcon from "../../assets/icons/not-started-circle.png";

// For section statuses
import CompleteSectionIcon from "../../assets/icons/complete-section.png";
import InProgressSectionIcon from "../../assets/icons/in-progress-section.png";
import NotStartedSectionIcon from "../../assets/icons/not-started-section.png";

// ======================= INTERFACES =======================
interface ExtendedChecklistCardProps extends ChecklistCardProps {
  completionPercentage?: number;
}

/**
 * Each section now holds an array of DecoratedItem, which includes
 * a localStatus: "Complete" | "In-Progress" | "Not Started"
 * for each item in that section.
 */
interface DecoratedItem extends ChecklistItem {
  localStatus: "Complete" | "In-Progress" | "Not Started";
}

interface ChecklistSection {
  id: string;
  title: string;
  status: "Complete" | "In-Progress" | "Not Started";
  items: DecoratedItem[];
  isExpanded: boolean;
}

const ChecklistCard: React.FC<ExtendedChecklistCardProps> = ({
  userId,
  completionPercentage = 40,
}) => {
  // ======================= DEFAULT CHECKLIST ITEMS =======================
  const [initialItems] = useState<ChecklistItem[]>([
    // Profile items
    { id: 2, text: "Upload Resume", completed: false, step: 1 },
    { id: 3, text: "Add Education History", completed: false, step: 2 },
    {
      id: 5,
      text: "Complete Job Preferences Survey",
      completed: false,
      step: 3,
    },
    { id: 8, text: "Link Social Profiles", completed: false, step: 4 },

    // Test Zone items
    { id: 11, text: "Take Background Test", completed: false, step: 5 },
    { id: 10, text: "Take Behavioral Test", completed: false, step: 6 },
    { id: 9, text: "Take Technical Test", completed: false, step: 7 },

    // Jobs items
    { id: 12, text: 'Rate a Job Posting as "Like"', completed: false, step: 8 },
    {
      id: 13,
      text: 'Rate a Job Posting as "Dislike"',
      completed: false,
      step: 9,
    },

    // Messages items
    { id: 14, text: "Match with a Recruiter", completed: false, step: 10 },
    { id: 15, text: "Get Referred by a Recruiter", completed: false, step: 11 },
  ]);

  const [items, setItems] = useState<ChecklistItem[]>(initialItems);
  const [sections, setSections] = useState<ChecklistSection[]>([]);
  const [assessmentCounts, setAssessmentCounts] = useState({
    technical_assessment_count: 0,
    behavioral_assessment_count: 0,
  });
  const [error, setError] = useState<string | null>(null);

  // ======================= DECORATION: localStatus PER ITEM =======================
  /**
   * For each item in a section, we determine localStatus:
   * - "Complete" if item.completed === true
   * - "In-Progress" for the first incomplete item
   * - "Not Started" for subsequent incomplete items
   *
   * We sort by step so we pick the earliest incomplete as "In-Progress."
   */
  function decorateSectionItems(
    itemsForSection: ChecklistItem[],
  ): DecoratedItem[] {
    const sorted = itemsForSection
      .slice()
      .sort((a, b) => (a.step || 0) - (b.step || 0));
    let firstIncompleteFound = false;

    return sorted.map((item) => {
      if (item.completed) {
        return { ...item, localStatus: "Complete" } as DecoratedItem;
      } else if (!firstIncompleteFound) {
        firstIncompleteFound = true;
        return { ...item, localStatus: "In-Progress" } as DecoratedItem;
      } else {
        return { ...item, localStatus: "Not Started" } as DecoratedItem;
      }
    });
  }

  /**
   * For the overall section, we now ignore "In-Progress" items
   * unless there's also a "Complete" item.
   *
   * The rules:
   * 1) If every item is "Complete" => section is "Complete"
   * 2) Else if there's at least one item "Complete" => section is "In-Progress"
   * 3) Otherwise => "Not Started"
   */
  function computeSectionStatus(
    decorated: DecoratedItem[],
  ): "Complete" | "In-Progress" | "Not Started" {
    const allComplete = decorated.every((it) => it.localStatus === "Complete");
    if (allComplete) {
      return "Complete";
    }
    const hasAtLeastOneComplete = decorated.some(
      (it) => it.localStatus === "Complete",
    );
    if (hasAtLeastOneComplete) {
      return "In-Progress";
    }
    // If no items are complete, we label the entire section as "Not Started"
    return "Not Started";
  }

  // ======================= TOGGLE ITEM COMPLETION =======================
  const toggleItem = async (id: number) => {
    try {
      const updatedItems = items.map((item) =>
        item.id === id ? { ...item, completed: !item.completed } : item,
      );
      setItems(updatedItems);

      // Update server
      await axiosInterceptor.post(`/update_checklist/${userId}/`, {
        checklist: updatedItems,
      });
    } catch (err) {
      console.error("Error updating checklist:", err);
    }
  };

  // ======================= FETCH & MERGE CHECKLIST DATA =======================
  useEffect(() => {
    const fetchChecklist = async () => {
      try {
        let currentItems = [...initialItems];

        // 1) Attempt to fetch existing checklist from server
        try {
          const response = await axiosInterceptor.get(
            `/get_checklist/${userId}/`,
          );
          if (response.data?.checklist?.length > 0) {
            const apiItems = response.data.checklist;
            currentItems = initialItems.map((init) => {
              const found = apiItems.find(
                (a: ChecklistItem) => a.id === init.id,
              );
              return found ? { ...found, step: init.step } : init;
            });
          }
        } catch (err) {
          console.log("Could not fetch checklist, using defaults");
        }

        // 2) If profile images exist, mark item #7 complete
        try {
          const imagesRes = await axiosInterceptor.get(
            `serve_private_doc/user_career_data/user_id=${userId}/images/`,
          );
          if (imagesRes.data?.private_docs?.length > 0) {
            currentItems = currentItems.map((it) =>
              it.id === 7 ? { ...it, completed: true } : it,
            );
          }
        } catch (err) {
          console.log("Could not fetch profile images");
        }

        // 3) Check user data for social, experience, education
        try {
          const userDataRes = await axiosInterceptor.get(`/getuser/${userId}/`);
          const userData = userDataRes.data;
          // Social
          if (
            userData.u_data.website &&
            userData.u_data.linkedin &&
            userData.u_data.github
          ) {
            currentItems = currentItems.map((it) =>
              it.id === 8 ? { ...it, completed: true } : it,
            );
          }
          // Experience
          if (userData.exp_data && userData.exp_data.length > 0) {
            currentItems = currentItems.map((it) =>
              it.id === 4 ? { ...it, completed: true } : it,
            );
          }
          // Education
          if (userData.ed_data && userData.ed_data.length > 0) {
            currentItems = currentItems.map((it) =>
              it.id === 3 ? { ...it, completed: true } : it,
            );
          }
        } catch (err) {
          console.log("Could not fetch user data");
        }

        // 4) Check for resumes
        try {
          const resumeRes = await axiosInterceptor.get(
            `/serve_private_doc/user_career_data/user_id=${userId}/resumes/`,
          );
          if (resumeRes.data?.private_docs?.length > 0) {
            currentItems = currentItems.map((it) =>
              it.id === 2 ? { ...it, completed: true } : it,
            );
          }
        } catch (err) {
          console.log("Could not fetch resume data");
        }

        // 5) Check for survey completions
        try {
          const surveyRes = await axiosInterceptor.get(
            `/save_or_update_user_surveys/${userId}/`,
          );
          if (surveyRes.data?.job_pref_survey) {
            currentItems = currentItems.map((it) =>
              it.id === 5 ? { ...it, completed: true } : it,
            );
          }
          if (surveyRes.data?.personality_survey) {
            currentItems = currentItems.map((it) =>
              it.id === 6 ? { ...it, completed: true } : it,
            );
          }
        } catch (err) {
          console.log("Could not fetch survey data");
        }

        // 6) Assessment stats
        try {
          const assessRes = await axiosInterceptor.get(
            `/assessment_stats/${userId}/`,
          );
          if (assessRes.data?.stats) {
            const techCount =
              assessRes.data.stats.technical_assessment_count || 0;
            const behCount =
              assessRes.data.stats.behavioral_assessment_count || 0;
            setAssessmentCounts({
              technical_assessment_count: techCount,
              behavioral_assessment_count: behCount,
            });
            // Mark item #9 complete if user took any technical test
            if (techCount > 0) {
              currentItems = currentItems.map((it) =>
                it.id === 9 ? { ...it, completed: true } : it,
              );
            }
            // Mark item #10 complete if user took any behavioral test
            if (behCount > 0) {
              currentItems = currentItems.map((it) =>
                it.id === 10 ? { ...it, completed: true } : it,
              );
            }
          }
        } catch (err) {
          console.log("Could not fetch assessment stats");
        }

        // Update state
        setItems(currentItems);

        // =============== DECORATE SECTIONS ===============
        const profileItems = currentItems.filter((i) =>
          [2, 3, 5, 8, 7, 4, 6].includes(i.id),
        );
        const testZoneItems = currentItems.filter((i) =>
          [11, 10, 9].includes(i.id),
        );
        const jobsItems = currentItems.filter((i) => [12, 13].includes(i.id));
        const messagesItems = currentItems.filter((i) =>
          [14, 15].includes(i.id),
        );

        const decoratedProfile = decorateSectionItems(profileItems);
        const decoratedTestZone = decorateSectionItems(testZoneItems);
        const decoratedJobs = decorateSectionItems(jobsItems);
        const decoratedMessages = decorateSectionItems(messagesItems);

        setSections([
          {
            id: "profile",
            title: "Profile",
            status: computeSectionStatus(decoratedProfile),
            items: decoratedProfile,
            isExpanded: false,
          },
          {
            id: "testZone",
            title: "Test Zone",
            status: computeSectionStatus(decoratedTestZone),
            items: decoratedTestZone,
            isExpanded: false,
          },
          {
            id: "jobs",
            title: "Jobs",
            status: computeSectionStatus(decoratedJobs),
            items: decoratedJobs,
            isExpanded: false,
          },
          {
            id: "messages",
            title: "Messages",
            status: computeSectionStatus(decoratedMessages),
            items: decoratedMessages,
            isExpanded: false,
          },
        ]);
      } catch (err) {
        console.error("Error in checklist workflow:", err);
        setSections([]);
        setError("Could not load checklist data. Please try again.");
      }
    };

    fetchChecklist();
  }, [userId, initialItems]);

  // ======================= RENDER SECTION ICONS =======================
  function renderSectionStatusIcon(
    status: "Complete" | "In-Progress" | "Not Started",
  ) {
    if (status === "Complete") {
      return (
        <div className="flex items-center">
          <img src={CompleteSectionIcon} alt="Complete" className="w-5 h-5" />
          <span className="ml-2 text-sm text-foreground-dimmer dark:text-foreground-dark-dimmer">
            Complete
          </span>
        </div>
      );
    } else if (status === "In-Progress") {
      return (
        <div className="flex items-center">
          <img
            src={InProgressSectionIcon}
            alt="In Progress"
            className="w-5 h-5"
          />
          <span className="ml-2 text-sm text-foreground-dimmer dark:text-foreground-dark-dimmer">
            In-Progress
          </span>
        </div>
      );
    } else {
      return (
        <div className="flex items-center">
          <img
            src={NotStartedSectionIcon}
            alt="Not Started"
            className="w-5 h-5"
          />
          <span className="ml-2 text-sm text-foreground-dimmer dark:text-foreground-dark-dimmer">
            Not Started
          </span>
        </div>
      );
    }
  }

  // ======================= RENDER A SINGLE CHECKLIST ITEM =======================
  function renderChecklistItem(item: DecoratedItem) {
    let iconSrc = NotStartedIcon;
    let iconAlt = "Not Started";
    if (item.localStatus === "Complete") {
      iconSrc = CompleteIcon;
      iconAlt = "Complete";
    } else if (item.localStatus === "In-Progress") {
      iconSrc = InProgressIcon;
      iconAlt = "In Progress";
    }

    return (
      <div
        key={item.id}
        className="flex items-start gap-3 py-2 px-1 hover:bg-background-dimmer dark:hover:bg-background-dark-dimmer rounded-md transition-colors cursor-pointer"
        onClick={() => toggleItem(item.id)}
      >
        <div className="flex-shrink-0 w-8 flex justify-center pt-0.5">
          <img src={iconSrc} alt={iconAlt} className="w-5 h-5" />
        </div>
        <div className="flex-grow">
          {item.step && (
            <span className="text-xs text-accent-primary font-medium">
              Step {item.step}
            </span>
          )}
          <span
            className={`block text-sm ${
              item.localStatus === "Complete"
                ? "text-foreground dark:text-foreground-dark line-through"
                : "text-foreground-dimmer dark:text-foreground-dark-dimmer"
            }`}
          >
            {item.text}
          </span>

          {/* Show # of completions if it's a test item */}
          {(item.id === 9 || item.id === 10) && (
            <span className="text-xs text-foreground dark:text-foreground-dark mt-1 block">
              {item.id === 9
                ? assessmentCounts.technical_assessment_count
                : assessmentCounts.behavioral_assessment_count}{" "}
              Completions
            </span>
          )}
        </div>
      </div>
    );
  }

  // ======================= MAIN RENDER =======================
  return (
    <div
      className="bg-background dark:bg-background-dark rounded-2xl overflow-hidden shadow-sm border-background-dimmest dark:border-background-dark-dimmest"
      style={{
        borderWidth: "0.5px",
        borderStyle: "solid",
      }}
    >
      <div className="p-4">
        {/* Header row */}
        <div className="flex justify-between items-center mb-3">
          <div className="flex items-center gap-2">
            <ListChecks className="w-5 h-5 text-accent-primary" />
            <div>
              <h3 className="text-base font-medium text-foreground-dimmer dark:text-foreground-dark-dimmer">
                Getting Started: Checklist
              </h3>
              <p className="text-xs text-foreground-dark-dimmest dark:text-foreground-dark-dimmest">
                Helping you navigate the platform and find your next job
              </p>
            </div>
          </div>
          <div className="text-foreground-dimmer dark:text-foreground-dark-dimmer text-sm font-medium">
            {completionPercentage}% Complete
          </div>
        </div>

        {/* Progress bar */}
        <div className="h-1.5 bg-background-dimmest dark:bg-background-dark-root rounded-full w-full mb-4">
          <div
            className="h-1.5 bg-accent-primary rounded-full"
            style={{ width: `${completionPercentage}%` }}
          />
        </div>

        {/* Sections */}
        <div className="space-y-2">
          {sections.map((section) => (
            <div
              key={section.id}
              className="rounded-lg overflow-hidden border-background-dimmest dark:border-background-dark-dimmest"
              style={{
                borderWidth: "0.5px",
                borderStyle: "solid",
              }}
            >
              {/* Section Header */}
              <div
                className="flex items-center justify-between p-3 cursor-pointer bg-background dark:bg-background-dark hover:bg-background-dimmer dark:hover:bg-background-dark-dimmer"
                onClick={() => {
                  setSections((prev) =>
                    prev.map((sec) =>
                      sec.id === section.id
                        ? { ...sec, isExpanded: !sec.isExpanded }
                        : sec,
                    ),
                  );
                }}
              >
                <span className="text-sm font-medium text-foreground-dimmer dark:text-foreground-dark-dimmer">
                  {section.title}
                </span>
                <div className="flex items-center gap-3">
                  {renderSectionStatusIcon(section.status)}
                  {section.isExpanded ? (
                    <ChevronUp className="w-4 h-4 text-foreground dark:text-foreground-dark" />
                  ) : (
                    <ChevronDown className="w-4 h-4 text-foreground dark:text-foreground-dark" />
                  )}
                </div>
              </div>

              {/* Collapsible Section Content */}
              {section.isExpanded && (
                <div className="bg-background dark:bg-background-dark px-2 py-1">
                  {section.items.map((item) => renderChecklistItem(item))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {error && (
        <div className="px-4 py-2 text-red-500">
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default ChecklistCard;
