// src/routes/profile/career/Education.tsx
import React, { useState, ChangeEvent, FormEvent } from "react";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Form } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import FormInput from "../../../components/FormInput";
import { SimpleDateInput } from "../../../components/DateInput";
import { EducationItem } from "../interfaces";
import { formatForDisplay, parseFromDisplay } from "../../../utils/dateUtils";

interface EducationProps {
  eduList: EducationItem[];
}

/** Display read-only education entries */
export default function Education({ eduList }: EducationProps): JSX.Element {
  function EduItem({ item }: { item: EducationItem }): JSX.Element {
    const { school, degree, aos, minor, gpa, start_date, end_date } = item;
    const startYear = new Date(start_date).getFullYear();
    const endYear = end_date ? new Date(end_date).getFullYear() : "Present";
    const dateRange = `${startYear} – ${endYear}`;

    return (
      <div className="ml-5 flex flex-row items-start gap-4 mb-6 last:mb-0">
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-base font-medium text-foreground-dimmer dark:text-foreground-dark-dimmer">
                {degree === "BS" ? "B.S." : degree} {aos}
                {minor && `, Minor: ${minor}`}
              </p>
              <h3 className="text-sm font-normal text-foreground-dimmest dark:text-foreground-dark-dimmest">
                {school}
              </h3>
              <div className="flex items-center gap-4 mt-1">
                <p className="text-sm text-foreground-dimmest dark:text-foreground-dark-dimmest">
                  {dateRange}
                </p>
                {gpa && (
                  <>
                    <span className="text-[#333333]">•</span>
                    <p className="text-sm text-foreground-dimmest dark:text-foreground-dark-dimmest">
                      GPA: {gpa}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Sort from most recent to oldest
  const sortedEduList = [...eduList].sort(
    (a, b) =>
      new Date(b.start_date).getTime() - new Date(a.start_date).getTime(),
  );

  return (
    <div>
      {sortedEduList.map((item) => (
        <EduItem key={item.id} item={item} />
      ))}
    </div>
  );
}

/** CreateEdu: for editing/adding education entries */
interface CreateEduProps {
  eduList: EducationItem[];
  setEducation: React.Dispatch<React.SetStateAction<EducationItem[]>>;
}

export function CreateEdu({
  eduList,
  setEducation,
}: CreateEduProps): JSX.Element {
  const BLANK_DATA: EducationItem = {
    id: -1,
    school: "",
    degree: "BS",
    aos: "",
    start_date: "",
    end_date: null,
  };

  const degreeOptions = [
    "B.S.",
    "B.A.",
    "M.S.",
    "M.A.",
    "M.B.A.",
    "M.Eng.",
    "Ph.D.",
  ];
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");

  /** Add a new blank row */
  function addNewEntry() {
    setEducation((prev) => [...prev, { ...BLANK_DATA, id: -1 }]);
  }

  /** Remove an entry from the list (and DB if it has an ID) */
  function handleRemove(itemToRemove: EducationItem) {
    if (itemToRemove.id !== -1) {
      try {
        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
        axiosInterceptor.delete(`/delete_edu_entry/${itemToRemove.id}/`, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        });
      } catch (error) {
        console.error("Error removing education:", error);
      }
    }
    setEducation((prev) => prev.filter((it) => it.id !== itemToRemove.id));
  }

  /** Show/hide a simple error modal */
  function handleShowModal(message: string) {
    setModalMessage(message);
    setShowModal(true);
  }
  function handleCloseModal() {
    setShowModal(false);
  }

  /** Validate required fields before saving */
  function validateFields(): boolean {
    for (const edu of eduList) {
      if (!edu.school.trim()) {
        handleShowModal("The 'school' field is required.");
        return false;
      }
      if (!edu.degree.trim()) {
        handleShowModal("The 'degree' field is required.");
        return false;
      }
      if (!edu.aos.trim()) {
        handleShowModal("The 'Area of Study' field is required.");
        return false;
      }
      if (!edu.start_date.trim()) {
        handleShowModal("The 'Start Date' field is required.");
        return false;
      }
    }
    return true;
  }

  /**
   * Switch-based approach to ensure correct types.
   * We store everything in DB format: "YYYY-MM-DD".
   */
  function updateEducationItem(
    index: number,
    field: keyof EducationItem,
    rawValue: string | null,
  ) {
    setEducation((prev) => {
      const updatedList = [...prev];
      const current = { ...updatedList[index] };

      switch (field) {
        case "school":
        case "degree":
        case "aos":
          current[field] = rawValue ?? "";
          break;

        case "minor":
        case "gpa":
          current[field] = rawValue; // can be string or null
          break;

        case "start_date":
          // store as "YYYY-MM-DD", so parse if the user typed "MM/DD/YYYY"
          current.start_date = rawValue ?? "";
          break;

        case "end_date":
          // can be string or null
          current.end_date = rawValue === "" ? null : rawValue;
          break;

        default:
          // ignore other fields
          break;
      }

      updatedList[index] = current;
      return updatedList;
    });
  }

  /**
   * Called when the user changes a text/select/textarea input
   * for fields other than start/end date
   */
  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    index: number,
  ) {
    const { name, value } = e.target;
    // For date fields, we want "YYYY-MM-DD" in state
    // so if user typed "03/19/2025", parse it to "2025-03-19".
    if (name === "start_date" || name === "end_date") {
      const parsed = parseFromDisplay(value);
      // If the user cleared the field, parsed might be ""
      updateEducationItem(
        index,
        name as keyof EducationItem,
        parsed === "" ? null : parsed,
      );
    } else {
      // Non-date fields
      updateEducationItem(index, name as keyof EducationItem, value);
    }
  }

  /**
   * On submit => validate => patch or post each item => reload
   */
  async function handleSubmit(event?: FormEvent) {
    if (event) event.preventDefault();
    if (!validateFields()) return;

    try {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
      const user_id = localStorage.getItem("user_id");
      if (!user_id) {
        handleShowModal("User not authenticated.");
        return;
      }

      const promises = eduList.map(async (edu) => {
        if (edu.id !== -1) {
          // Patch existing
          await axiosInterceptor.patch(`/update_edu/${edu.id}/`, edu, {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
          });
        } else {
          // Post new
          const newEdu = { ...edu, user_id };
          const response = await axiosInterceptor.post(
            `/create_edu/${user_id}/`,
            newEdu,
            {
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
              },
            },
          );
          const newId = response.data.id;
          setEducation((prev) =>
            prev.map((item) =>
              item.id === -1 ? { ...item, id: newId } : item,
            ),
          );
        }
      });

      await Promise.all(promises);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting education:", error);
      handleShowModal("An error occurred while submitting the form.");
    }
  }

  return (
    <div className="text-[#DDDDDD]">
      <Form onSubmit={handleSubmit}>
        {eduList.map((item, index) => {
          // Convert "YYYY-MM-DD" => "MM/DD/YYYY" for display
          const displayStart = formatForDisplay(item.start_date);
          const displayEnd = formatForDisplay(item.end_date ?? null);

          return (
            <div
              key={item.id}
              className="mb-2 p-3 bg-background-root dark:bg-background-dark-root rounded-xl"
            >
              <Stack gap={2}>
                <FormInput
                  name="school"
                  label="School"
                  type="text"
                  value={item.school}
                  handler={(e) => handleChange(e, index)}
                  required
                  dark
                />

                <Form.Label className="text-foreground dark:text-foreground-dark">
                  Degree:
                </Form.Label>
                <Form.Group controlId={`formDropdown-${item.id}`}>
                  <Form.Control
                    as="select"
                    name="degree"
                    value={item.degree}
                    onChange={(e) => handleChange(e, index)}
                    style={{
                      color: "#DDDDDD",
                      border: "1px solid #383A40",
                    }}
                    className="bg-inherit"
                  >
                    {degreeOptions.map((deg) => (
                      <option key={deg} value={deg}>
                        {deg}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <FormInput
                  name="aos"
                  label="Area of Study"
                  type="text"
                  value={item.aos}
                  handler={(e) => handleChange(e, index)}
                  required
                  dark
                />

                <FormInput
                  name="minor"
                  label="Minor"
                  type="text"
                  value={item.minor || ""}
                  handler={(e) => handleChange(e, index)}
                  dark
                />

                <FormInput
                  name="gpa"
                  label="GPA"
                  type="text"
                  value={item.gpa || ""}
                  handler={(e) => handleChange(e, index)}
                  dark
                />

                {/* Use the masked date input, passing display values */}
                <SimpleDateInput
                  sd={displayStart}
                  ed={displayEnd}
                  handler={(e) => handleChange(e, index)}
                />

                <Container style={{ textAlign: "right" }}>
                  <Button
                    onClick={() => handleRemove(item)}
                    style={{
                      padding: "6px 16px",
                      fontSize: "12px",
                      backgroundColor: "#FF0000",
                      borderColor: "#FF0000",
                    }}
                  >
                    Remove
                  </Button>
                </Container>
              </Stack>
            </div>
          );
        })}
      </Form>

      <Container className="d-flex justify-content-center align-items-center mt-3">
        <Button
          onClick={addNewEntry}
          style={{
            marginRight: "10px",
            backgroundColor: "#003DF5",
            borderColor: "#003DF5",
            color: "#DDDDDD",
          }}
        >
          Add New
        </Button>
        <Button
          onClick={handleSubmit}
          style={{
            backgroundColor: "#003DF5",
            borderColor: "#003DF5",
            color: "#DDDDDD",
          }}
        >
          Done
        </Button>
      </Container>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-background-root dark:bg-background-dark-rootp-6 rounded-lg text-[#DDDDDD]">
            <h4 className="mb-4 text-foreground-dimmer dark:text-foreground-dark-dimmer">
              Warning
            </h4>
            <p className="mb-4">{modalMessage}</p>
            <Button
              variant="secondary"
              onClick={handleCloseModal}
              style={{
                backgroundColor: "#333333",
                borderColor: "#333333",
                color: "#DDDDDD",
              }}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
