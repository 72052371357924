import { Offcanvas } from "react-bootstrap";
import { SelectedCourseDrawerProps } from "./types";
import ProgressIndicator from "../../components/ProgressIndicator";
import { PrimaryButton } from "../../components/Button";
import CourseStatusChip from "./CourseStatusChip";

const SelectedCourseDrawer: React.FC<SelectedCourseDrawerProps> = ({
  showDrawer,
  selectedCourse,
  selectedCoursePrerequisites,
  selectedCourseCitation,
  lecturePreview,
  lecturesProgress,
  courseStatusChipProps,
  handleHide,
  handleNavigateToCourse,
}) => {
  if (!selectedCourse) return null;

  return (
    <Offcanvas
      show={showDrawer}
      backdrop={false}
      placement="end"
      scroll={true}
      onHide={() => handleHide(selectedCourse)}
      className="dark-offcanvas" // Use a custom class for dark styling
    >
      {/* Header */}
      <Offcanvas.Header className="bg-accent-primary">
        <Offcanvas.Title className="text-foreground dark:text-foreground-dark text-xl font-semibold">
          Course Preview
        </Offcanvas.Title>
        <button
          onClick={() => handleHide(selectedCourse)}
          className="w-8 h-8 flex items-center justify-center text-foreground dark:text-foreground-dark hover:text-[#FFFFFF]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              d="M11 11.5L4 4.5M11 4.5L4 11.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </Offcanvas.Header>

      <Offcanvas.Body className="bg-background-root dark:bg-background-dark-root text-foreground dark:text-foreground-dark">
        <div className="flex flex-col gap-6 h-full">
          <div>
            <h3 className="text-2xl font-semibold mb-2">
              {selectedCourse.courseNumber} {selectedCourse.name}
            </h3>
            <CourseStatusChip courseStatusChipProps={courseStatusChipProps} />
            <p className="text-sm text-foreground-dimmest dark:text-foreground-dimmest mt-2">
              {selectedCourseCitation}
            </p>

            {selectedCoursePrerequisites.length > 0 && (
              <div className="mt-4">
                <p className="text-sm text-foreground-dimmest dark:text-foreground-dimmest font-medium mb-2">
                  Prerequisites:
                </p>
                <ul className="list-disc list-inside">
                  {selectedCoursePrerequisites.map((pr, idx) => (
                    <li
                      key={idx}
                      className="text-sm text-foreground-dimmest dark:text-foreground-dimmest"
                    >
                      {pr.courseNumber} {pr.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Lectures Progress */}
          <div>
            {lecturesProgress.map((lp, idx) => (
              <ProgressIndicator
                key={idx}
                progress={lp.progress}
                showConnectionLine={idx !== lecturesProgress.length - 1}
              >
                <p className="font-semibold text-sm text-foreground dark:text-foreground-dark">
                  {lp.lectureType}
                </p>
                <p className="text-sm text-foreground-dimmest dark:text-foreground-dimmest">
                  {Math.floor(lp.progress * lp.totalLectures)}/
                  {lp.totalLectures} completed
                </p>
              </ProgressIndicator>
            ))}
          </div>

          {/* Lecture Preview */}
          <div>
            <h3 className="text-xl font-semibold mb-2">Lecture Preview</h3>
            <div className="w-full min-h-[228px] bg-background-dimmer dark:bg-background-dark-dimmer rounded p-2 flex items-center justify-center">
              {lecturePreview}
            </div>
          </div>

          {/* Action Button */}
          <div className="mt-auto flex justify-center border-t border-background-deepest dark:border-background-dark-deepest pt-4">
            <PrimaryButton
              message="View Full Course"
              handleClick={() => handleNavigateToCourse(selectedCourse)}
            />
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SelectedCourseDrawer;
