/** Convert "YYYY-MM-DD" -> "MM/DD/YYYY" */
export function formatForDisplay(dbDate: string | null): string {
  if (!dbDate) return "";
  // e.g. "2025-03-19" => ["2025","03","19"]
  const [year, month, day] = dbDate.split("-");
  if (!year || !month || !day) return "";
  return `${month}/${day}/${year}`;
}

/** Convert "MM/DD/YYYY" -> "YYYY-MM-DD" */
export function parseFromDisplay(displayDate: string): string {
  if (!displayDate) return "";
  // e.g. "03/19/2025" => ["03","19","2025"]
  const [month, day, year] = displayDate.split("/");
  if (!year || !month || !day) return "";
  return `${year}-${month}-${day}`;
}
