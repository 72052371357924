import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  FormEventHandler,
  HTMLAttributes,
  KeyboardEvent,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ButtonProps } from "./Button";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import TopNavigation from "../routes/home/TopNavigation";

/**
 * LandingPrimaryButton component
 * @component
 * @param {Object} props - The props for the PrimaryButton component
 * @param {ReactElement<SVGElement>} [props.icon] - Optional SVG icon element to display in the button
 * @param {string} props.message - Text to display on the button
 * @param {MouseEventHandler<HTMLButtonElement>} [props.handleClick] - Optional click event handler for the button
 * @returns {ReactElement} A primary styled button element for the landing page
 */
export const LandingButtonPrimary: FC<ButtonProps> = ({
  message,
  icon,
  handleClick,
}) => {
  return (
    <button
      onClick={handleClick}
      className="bg-accent-primary text-background-root text-lg font-medium py-[0.8rem] px-4 rounded-md hover:bg-accent-primary-dimmer focus-visible:outline focus-visible:outline-background-dimmer focus-visible:outline-offset-2 focus-visible:outline-2 border-none transition-colors w-min h-min text-nowrap flex flex-nowrap gap-2 items-center"
    >
      {icon}
      {message}
    </button>
  );
};

/**
 * LandingFormButtonPrimary component
 * @component
 * @param {Object} props - The props for the PrimaryButton component
 * @param {ReactElement<SVGElement>} [props.icon] - Optional SVG icon element to display in the button
 * @param {string} props.message - Text to display on the button
 * @param {MouseEventHandler<HTMLButtonElement>} [props.handleClick] - Optional click event handler for the button
 * @returns {ReactElement} A primary styled button element for the landing page's login and signup
 */
export const LandingFormButtonPrimary: FC<
  ButtonProps & { disabled?: boolean }
> = ({ message, icon, disabled = false, handleClick }) => {
  return (
    <button
      onClick={handleClick}
      type="submit"
      disabled={disabled}
      className={`${disabled ? "bg-background-deepest text-foreground-dimmer" : "bg-accent-primary hover:bg-accent-primary-dimmer text-background-root"}  text-lg font-normal py-1 px-4 rounded-md  focus-visible:outline focus-visible:outline-background-dimmer focus-visible:outline-offset-2 focus-visible:outline-2 border-none transition-colors w-full h-min text-nowrap flex flex-nowrap gap-2 items-center justify-center`}
    >
      {icon}
      {message}
    </button>
  );
};

/**
 * LandingHollowButton component
 * @component
 * @param {Object} props - The props for the HollowButton component
 * @param {ReactElement<SVGElement>} [props.icon] - Optional SVG icon element to display in the button
 * @param {string} props.message - Text to display on the button
 * @param {MouseEventHandler<HTMLButtonElement>} [props.handleClick] - Optional click event handler for the button
 * @returns {ReactElement} A hollow styled button element for the landing page
 */
export const LandingButtonHollow: FC<ButtonProps> = ({
  message,
  icon,
  handleClick,
}) => {
  return (
    <button
      onClick={handleClick}
      className="bg-neutral-50 text-background-dimmer text-lg font-medium py-[0.8rem] px-4 rounded-md bg-opacity-0 hover:bg-opacity-10 focus-visible:outline focus-visible:outline-background-dimmer focus-visible:outline-offset-2 focus-visible:outline-2 transition-colors w-min h-min text-nowrap border border-solid border-background-deepest flex flex-nowrap gap-2 items-center"
    >
      {icon}
      {message}
    </button>
  );
};

/**
 * LandingButtonGlass component
 * @component
 * @param {Object} props - The props for the GlassButton component
 * @param {ReactElement<SVGElement>} [props.icon] - Optional SVG icon element to display in the button
 * @param {string} props.message - Text to display on the button
 * @param {MouseEventHandler<HTMLButtonElement>} [props.handleClick] - Optional click event handler for the button
 * @returns {ReactElement} A glass styled button element for the landing page
 */
export const LandingButtonGlass: FC<ButtonProps> = ({
  message,
  icon,
  handleClick,
}) => {
  return (
    <button
      onClick={handleClick}
      className="bg-neutral-50 bg-opacity-10 backdrop-filter backdrop-blur-sm text-background-dimmer text-lg font-medium py-[0.8rem] px-4 rounded-md hover:bg-opacity-100 hover:text-foreground focus-visible:outline focus-visible:outline-background-dimmer focus-visible:outline-offset-2 focus-visible:outline-2 transition-colors w-min h-min text-nowrap flex flex-nowrap gap-2 items-center"
    >
      {icon}
      {message}
    </button>
  );
};

/**
 * LandingButtonHollowGlass component
 * @component
 * @param {Object} props - The props for the HollowGlassButton component
 * @param {ReactElement<SVGElement>} [props.icon] - Optional SVG icon element to display in the button
 * @param {string} props.message - Text to display on the button
 * @param {MouseEventHandler<HTMLButtonElement>} [props.handleClick] - Optional click event handler for the button
 * @returns {ReactElement} A glass styled button element for the landing page
 */
export const LandingButtonHollowGlass: FC<ButtonProps> = ({
  message,
  icon,
  handleClick,
}) => {
  return (
    <button
      onClick={handleClick}
      className="bg-neutral-50 bg-opacity-10 backdrop-filter backdrop-blur-sm text-background-dimmer text-lg font-medium py-[0.8rem] px-4 rounded-md hover:bg-opacity-100 hover:text-foreground focus-visible:outline focus-visible:outline-background-dimmer focus-visible:outline-offset-2 focus-visible:outline-2 transition-colors w-min h-min text-nowrap flex flex-nowrap gap-2 items-center border border-solid border-background-deepest "
    >
      {icon}
      {message}
    </button>
  );
};

/**
 * LandingButtonInvisible component
 * @component
 * @param {Object} props - The props for the InvisibleButton component
 * @param {ReactElement<SVGElement>} [props.icon] - Optional SVG icon element to display in the button
 * @param {string} props.message - Text to display on the button
 * @param {MouseEventHandler<HTMLButtonElement>} [props.handleClick] - Optional click event handler for the button
 * @returns {ReactElement} A glass styled button element for the landing page
 */
export const LandingButtonInvisible: FC<ButtonProps> = ({
  message,
  icon,
  handleClick,
}) => {
  return (
    <button
      onClick={handleClick}
      className="bg-neutral-50 bg-opacity-0 text-background-dimmer text-lg font-medium py-[0.8rem] px-4 rounded-md hover:bg-opacity-10 focus-visible:outline focus-visible:outline-background-dimmer focus-visible:outline-offset-2 focus-visible:outline-2 transition-colors w-min h-min text-nowrap flex flex-nowrap gap-2 items-center"
    >
      {icon}
      {message}
    </button>
  );
};

interface CompanyCardProps {
  logoPath: string;
}

/**
 * @param {string} props.logoPath the path to an image file for a company's icon
 * @returns a semi-transparent card with the company's logo in the center
 */
export const CompanyCard: FC<CompanyCardProps> = ({ logoPath }) => {
  return (
    <div className="bg-neutral-50 bg-opacity-10 backdrop-filter backdrop-blur-sm flex items-center justify-center px-2 py-3 rounded-sm h-20 min-w-56 shadow-lg w-min hover:scale-105 hover:shadow-2xl transition-transform">
      <img
        src={logoPath}
        alt={`Company testimony card for ${logoPath}`}
        width={150}
      ></img>
    </div>
  );
};

interface LeftRightTimelineProps {
  leftChild?: ReactNode;
  rightChild?: ReactNode;
}

/**
 * @param {ReactNode} leftChild the child to be displayed on the left side of the timeline
 * @param {ReactNode} rightChild the child to be displayed on the right side of the timeline
 * @returns a vertical line with the provided children displayed on each side
 */
export const LeftRightTimeline: FC<LeftRightTimelineProps> = ({
  leftChild,
  rightChild,
}) => {
  return (
    <div className="w-full flex justify-center items-stretch gap-8">
      <div>{leftChild}</div>
      <div className="flex flex-col items-center justify-start">
        <div className="w-7 h-7 rounded-full border-[2px] border-background"></div>
        <div className="w-0 border-[1px] border-background flex-1"></div>
      </div>
      <div>{rightChild}</div>
    </div>
  );
};

interface ScrollLinkProps {
  href: string;
}

/**
 * @param {string} href the link that this should scroll to. Requires: link must be an id on current page
 * @returns a link that scrolls to a section on the same page when clicked
 */
export const ScrollLink: React.FC<
  ScrollLinkProps & HTMLAttributes<HTMLDivElement>
> = ({ href, children, className }) => {
  const navigate = useNavigate();
  return (
    <a
      href={href}
      className={className}
      onClick={(e) => {
        if (window.location.pathname === "/") {
          e.preventDefault();
          const element = document.querySelector(href);
          element?.scrollIntoView({ behavior: "smooth" });
        } else {
          navigate(`/${href}`);
        }
      }}
    >
      {children}
    </a>
  );
};

interface FormErrorMessageProps {
  message: string;
}

export const FormErrorMessage: FC<FormErrorMessageProps> = ({ message }) => {
  return <p className="text-sm text-accent-negative">{message}</p>;
};

interface HorizontalFormInputProps {
  id: string;
  name: string;
  type: string;
  placeholder?: string;
  value: string;
  labelText: string;
  error: boolean;
  errorMessage?: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

/**
 *
 * @param {string} props.id the id of the input element
 * @param {string} props.name the name of the input element
 * @param {string} props.type the type of the input element
 * @param {string} props.placeholder the placeholder value of the input element
 * @param {string} props.value the value of the input element
 * @param {boolean} props.error whether this input has an error or not
 * @param {string} props.handleChange callback run when input is changed
 * @returns a input element for a form
 */
export const HorizontalFormInput: FC<HorizontalFormInputProps> = ({
  id,
  name,
  type,
  placeholder = "",
  value,
  labelText,
  error,
  errorMessage,
  handleChange,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="space-y-1">
      <label
        htmlFor={id}
        className="block text-md font-light text-foreground-dimmest"
      >
        {labelText}
      </label>
      <div className="relative">
        <input
          id={id}
          name={name}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          placeholder={placeholder}
          className={`w-full px-3 py-2 bg-background text-foreground-dimmer border ${error ? "border-accent-negative" : "border-background-dimmest"} rounded-md focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-background focus-visible:border-none focus-visible:outline`}
          value={value}
          onChange={handleChange}
        />

        {type === "password" ? (
          <button
            type="button"
            className="absolute inset-y-0 right-0 pr-3 flex items-center"
            onClick={togglePasswordVisibility}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-gray-500"
            >
              {showPassword ? (
                <>
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                  <circle cx="12" cy="12" r="3" />
                </>
              ) : (
                <>
                  <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24" />
                  <line x1="1" y1="1" x2="23" y2="23" />
                </>
              )}
            </svg>
          </button>
        ) : (
          <></>
        )}
      </div>
      {error && errorMessage ? (
        <FormErrorMessage message={errorMessage} />
      ) : (
        <></>
      )}
    </div>
  );
};

interface AnnotatedCheckboxProps {
  id: string;
  name: string;
  checked: boolean;
  children: ReactNode;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

/**
 *
 * @param {boolean} props.checked whether the remember me checkbox is checked
 * @param {ReactNode} props.children the children to be rendered to the right of the checkbox
 * @param {Function} props.handleChange callback run when checked state is changed
 * @returns a checkbox that is labeled as a 'remember me' checkbox
 */
export const AnnotatedCheckbox: FC<AnnotatedCheckboxProps> = ({
  id,
  name,
  checked,
  children,
  handleChange,
}) => {
  return (
    <div className="flex items-center">
      <input
        id={id}
        name={name}
        type="checkbox"
        className="h-4 w-4 rounded-sm border text-blue-600 border-gray-300 bg-transparent bg-white"
        checked={checked}
        onChange={handleChange}
      />

      <label
        htmlFor={id}
        className="ml-2 block text-foreground-dimmer dark:text-foreground-dark-dimmer"
      >
        {children}
      </label>
    </div>
  );
};

interface OnboardingLinkProps {
  to: string;
  message: string;
  handleClick?: MouseEventHandler<HTMLAnchorElement>;
}

/**
 *
 * @param {string} to the destination that this link should navigate to
 * @param {string} message the text of this link
 * @param {Function} handleClick callback for when the user clicks on link
 * @returns a link to be used in the user onboarding process
 */
export const OnboardingLink: FC<OnboardingLinkProps> = ({
  to,
  message,
  handleClick,
}) => {
  return (
    <Link
      to={to}
      onClick={
        handleClick ??
        (() => {
          return;
        })
      }
      className="text-md font-normal text-blue-400 hover:text-blue-300 no-underline"
    >
      {message}
    </Link>
  );
};

export interface InputField {
  value: string;
  hasError: boolean;
  errorMessage?: string;
}

export interface LoginFields {
  email: string;
  password: string;
}

export interface LoginErrors {
  email: boolean;
  password: boolean;
}

export const BLANK_LOGIN: LoginFields = {
  email: "",
  password: "",
};

interface LoginModalProps {
  email: InputField;
  password: InputField;
  rememberMeChecked: boolean;
  errorMessage?: string;
  handleRememberMeChange: (value: boolean) => void;
  handleChange: ChangeEventHandler;
  handleSubmit: FormEventHandler;
}

export const LoginModal: FC<LoginModalProps> = ({
  email,
  password,
  rememberMeChecked,
  errorMessage,
  handleRememberMeChange,
  handleChange,
  handleSubmit,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="w-full flex flex-col gap-8 max-w-lg bg-neutral-50/10 backdrop-blur-lg rounded-lg shadow-xl overflow-hidden p-8">
        <div className="w-full flex flex-col gap-2">
          <h2 className="text-3xl font-medium text-background">
            Welcome Back!
          </h2>
          <p className="text-background-deepest text-lg font-light">
            Please enter your login details to access your account.
          </p>
        </div>
        <div className="w-full flex flex-col gap-3">
          <HorizontalFormInput
            id="email"
            name="email"
            type="email"
            placeholder="example@institution.edu"
            value={email.value}
            handleChange={handleChange}
            error={email.hasError}
            errorMessage={email.errorMessage ?? ""}
            labelText="Email"
          ></HorizontalFormInput>

          <HorizontalFormInput
            id="password"
            name="password"
            type={"password"}
            placeholder="enter password"
            value={password.value}
            handleChange={handleChange}
            error={password.hasError}
            errorMessage={password.errorMessage ?? ""}
            labelText="Password"
          />

          <div className="flex items-center justify-between">
            <AnnotatedCheckbox
              id="remember-me"
              name="remember-me"
              checked={rememberMeChecked}
              handleChange={() => handleRememberMeChange(!rememberMeChecked)}
            >
              <p className="text-md font-light text-foreground-dimmest">
                Remember Me
              </p>
            </AnnotatedCheckbox>
            <OnboardingLink to="../recover" message="Forgot Password?" />
          </div>

          {errorMessage && <FormErrorMessage message={errorMessage} />}
        </div>

        <div className="w-full flex flex-col gap-3">
          <LandingFormButtonPrimary message="Login"></LandingFormButtonPrimary>
          <div className="text-center">
            <p className="text-md text-background-deepest">
              Don't have an account?{" "}
              <OnboardingLink to="../signup" message="Sign Up"></OnboardingLink>
            </p>
          </div>
        </div>
      </div>
    </Form>
  );
};

export interface SignUpFields {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
}

export interface SignUpErrors {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
  confirmEmail?: boolean;
  password?: boolean;
  confirmPassword?: boolean;
  agreeTerms?: boolean;
}

export const BLANK_SIGNUP: SignUpFields = {
  firstName: "",
  lastName: "",
  email: "",
  confirmEmail: "",
  password: "",
  confirmPassword: "",
};

interface SignUpModalProps {
  firstName: InputField;
  lastName: InputField;
  email: InputField;
  confirmEmail: InputField;
  password: InputField;
  confirmPassword: InputField;
  agreeTerms: boolean;
  errorMessage?: string;
  isSubmitting: boolean;
  handleAgreeTermsChange: (value: boolean) => void;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleSubmit: FormEventHandler<HTMLFormElement>;
}

export const SignUpModal: FC<SignUpModalProps> = ({
  firstName,
  lastName,
  email,
  confirmEmail,
  password,
  confirmPassword,
  agreeTerms,
  errorMessage = "",
  handleAgreeTermsChange,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <Form onSubmit={handleSubmit} className="space-y-4">
      <div className="w-full flex flex-col gap-8 max-w-lg bg-neutral-50 bg-opacity-10 backdrop-blur-lg rounded-lg shadow-xl overflow-hidden p-8">
        <div className="w-full flex flex-col gap-2">
          <h2 className="text-3xl font-medium text-background">Sign up</h2>
          <p className="text-lg text-background-deepest font-light">
            Enter your details below to get started.
          </p>
        </div>

        <div className="w-full flex flex-col gap-3">
          <div className="w-full flex gap-4">
            <HorizontalFormInput
              id="firstName"
              name="firstName"
              type="text"
              placeholder="e.g. John"
              value={firstName.value}
              handleChange={handleChange}
              error={firstName.hasError}
              errorMessage={firstName.errorMessage}
              labelText="First Name"
            ></HorizontalFormInput>
            <HorizontalFormInput
              id="lastName"
              name="lastName"
              type="text"
              placeholder="e.g. Smith"
              value={lastName.value}
              handleChange={handleChange}
              error={lastName.hasError}
              errorMessage={lastName.errorMessage}
              labelText="Last Name"
            ></HorizontalFormInput>
          </div>

          <HorizontalFormInput
            id="email"
            name="email"
            type="email"
            placeholder="example@institution.edu"
            value={email.value}
            handleChange={handleChange}
            error={email.hasError}
            errorMessage={email.errorMessage}
            labelText="Email"
          />
          <HorizontalFormInput
            id="confirmEmail"
            name="confirmEmail"
            type="email"
            placeholder="retype email"
            value={confirmEmail.value}
            handleChange={handleChange}
            error={confirmEmail.hasError}
            errorMessage={confirmEmail.errorMessage}
            labelText="Confirm Email"
          />

          <HorizontalFormInput
            id="password"
            name="password"
            type="password"
            placeholder="enter password"
            value={password.value}
            handleChange={handleChange}
            error={password.hasError}
            labelText="Password"
            errorMessage={password.errorMessage}
          />

          <HorizontalFormInput
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            placeholder="retype password"
            value={confirmPassword.value}
            handleChange={handleChange}
            error={confirmPassword.hasError}
            labelText="Confirm Password"
            errorMessage={confirmPassword.errorMessage}
          />
          <AnnotatedCheckbox
            id="agreeTerms"
            name="agreeTerms"
            checked={agreeTerms}
            handleChange={() => handleAgreeTermsChange(!agreeTerms)}
          >
            <span className="text-md text-foreground-dimmest font-light">
              I agree to AlgoLink's{" "}
              <OnboardingLink
                to="/terms-of-use"
                message="Terms of Use"
              ></OnboardingLink>{" "}
              and{" "}
              <OnboardingLink
                to="/privacy-policy"
                message="Privacy Policy"
              ></OnboardingLink>{" "}
            </span>
          </AnnotatedCheckbox>

          {errorMessage && (
            <div className="text-red-500 text-sm">{errorMessage}</div>
          )}
        </div>
        <div className="w-full flex flex-col gap-2">
          <LandingFormButtonPrimary message="Continue"></LandingFormButtonPrimary>

          <div className="text-center">
            <span className="text-md text-background-deepest font-light">
              Already have an account?{" "}
              <OnboardingLink to="../login" message="Login"></OnboardingLink>
            </span>
          </div>
        </div>
      </div>
    </Form>
  );
};

interface VerifyModalProps {
  email: string;
  code: string[];
  handleCodeChange(newCode: string[]): void;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  handleRequestResend: () => void;
}

export const VerifyModal: FC<VerifyModalProps> = ({
  email,
  code,
  handleCodeChange,
  handleSubmit,
  handleRequestResend,
}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  // Handle changes in verification code inputs
  const handleCodeChangeLocal = (index: number, value: string) => {
    if (/^[0-9]?$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      handleCodeChange(newCode);

      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      } else if (!value && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  // Handle key down events in verification code inputs
  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const [currentTime, setCurrentTime] = useState<number>(new Date().getTime());

  // Timer effect to update currentTime every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime((prevTime) => prevTime + 1000);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const [timeLastSent, setTimeLastSent] = useState<number>(
    new Date().getTime(),
  );

  const secondsRemaining = useMemo<number>(
    () => Math.max(60 - Math.floor((currentTime - timeLastSent) / 1000), 0),
    [currentTime, timeLastSent],
  );

  return (
    <>
      <TopNavigation showCompleteNavigation={false} />
      <div className="max-w-3xl flex flex-col mx-auto w-[30rem] h-min justify-center bg-neutral-50 bg-opacity-10 backdrop:blur-md p-8 gap-6 rounded-lg">
        <h2 className={`text-3xl font-medium text-background`}>
          Account Verification
        </h2>
        <p className={`text-lg font-light text-background-dimmest`}>
          A 6-digit verification code has been sent to {email}.
        </p>

        <Form
          onSubmit={handleSubmit}
          className="flex flex-col gap-2 items-center"
        >
          <div className="flex gap-2 my-3.5">
            {[0, 1, 2, 3, 4, 5].map((index) => (
              <Form.Control
                key={index}
                ref={(el: HTMLInputElement) => {
                  inputRefs.current[index] = el;
                }}
                type="text"
                inputMode="numeric"
                pattern="[0-9]"
                maxLength={1}
                value={code[index]}
                onChange={(e) => handleCodeChangeLocal(index, e.target.value)}
                onKeyDown={(e) =>
                  handleKeyDown(index, e as KeyboardEvent<HTMLInputElement>)
                }
                className="aspect-square text-center border-none focus:ring-2 focus:ring-accent-primary focus:ring-offset-2"
              />
            ))}
          </div>
          <LandingFormButtonPrimary message="Submit"></LandingFormButtonPrimary>
        </Form>
        <p
          className={`text-md font-light text-foreground-dimmest text-center mt-1`}
        >
          Didn't receive an email?{" "}
          <button
            onClick={() => {
              handleRequestResend();
              setTimeLastSent(new Date().getTime());
            }}
            className={
              `${secondsRemaining > 0 ? "text-foreground-dimmer" : "text-blue-400 hover:text-blue-300"} font-light hover:underline` +
              (secondsRemaining > 0 ? "!cursor-not-allowed" : "cursor-pointer")
            }
            disabled={secondsRemaining > 0}
          >
            Resend
          </button>{" "}
          {secondsRemaining > 0 ? `(${secondsRemaining})` : ""}
        </p>
      </div>
    </>
  );
};
