import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/img/logos/white-logo-with-text.svg";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import { DefaultButton, PrimaryButton } from "../../../components/Button";

interface Answers {
  [key: string]: number | null;
}

const PersonalitySurvey: React.FC = () => {
  const [answers, setAnswers] = useState<Answers>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [message, setMessage] = useState<{ type: string; text: string } | null>(
    null,
  );
  const navigate = useNavigate();

  // Dark mode colors for the rating buttons
  const buttonColors = ["#003DF5", "#003DF5", "#003DF5", "#003DF5", "#003DF5"];
  const buttonSizes = [
    "w-12 h-12",
    "w-12 h-12",
    "w-12 h-12",
    "w-12 h-12",
    "w-12 h-12",
  ];

  const questions: string[] = [
    "I am the life of the party.", //1
    "I feel little concern for others.", //2
    "I am always prepared.", //3
    "I get stressed out easily.", //4
    "I have a rich vocabulary.", //5
    "I don't talk a lot.", //6
    "I am interested in people.", //7
    "I leave my belongings around.", //8
    "I am relaxed most of the time.", //9
    "I have difficulty understanding abstract ideas.", //10
    "I feel comfortable around people.", //11
    "I insult people.", //12
    "I pay attention to details.", //13
    "I worry about things.", //14
    "I have a vivid imagination.", //15
    "I keep in the background.", //16
    "I sympathize with others' feelings.", //17
    "I make a mess of things.", //18
    "I seldom feel blue.", //19
    "I am not interested in abstract ideas.", //20
    "I start conversations.", //21
    "I am not interested in other people's problems.", //22
    "I get chores done right away.", //23
    "I am easily disturbed.", //24
    "I have excellent ideas.", //25
    "I have little to say.", //26
    "I have a soft heart.", //27
    "I often forget to put things back in their proper place.", //28
    "I get upset easily.", //29
    "I do not have a good imagination.", //30
    "I talk to a lot of different people at parties.", //31
    "I am not really interested in others.", //32
    "I like order.", //33
    "I change my mood a lot.", //34
    "I am quick to understand things.", //35
    "I don't like to draw attention to myself.", //36
    "I take time out for others.", //37
    "I shirk my duties.", //38
    "I have frequent mood swings.", //39
    "I use difficult words.", //40
    "I don't mind being the center of attention.", //41
    "I feel others' emotions.", //42
    "I follow a schedule.", //43
    "I get irritated easily.", //44
    "I spend time reflecting on things.", //45
    "I am quiet around strangers.", //46
    "I make people feel at ease.", //47
    "I am exacting in my work.", //48
    "I often feel blue.", //49
    "I am full of ideas.", //50
  ];

  useEffect(() => {
    const fetchPreviousData = async () => {
      try {
        const userId = localStorage.getItem("user_id");
        console.log("Fetching previous data for user_id:", userId);
        if (userId) {
          const response = await axiosInterceptor.get(
            `/save_or_update_user_surveys/${userId}/`,
          );
          console.log("API response:", response);

          if (response.data?.personality_survey) {
            console.log(
              "Personality survey data found:",
              response.data.personality_survey,
            );
            let parsedData: any;
            try {
              parsedData = JSON.parse(response.data.personality_survey);
              console.log(
                "After first JSON.parse, typeof parsedData:",
                typeof parsedData,
              );
              console.log(
                "Parsed personality survey data after first parse:",
                parsedData,
              );
            } catch (parseError) {
              console.error(
                "Error parsing personality_survey JSON:",
                parseError,
              );
              setMessage({
                type: "error",
                text: "Invalid survey data format.",
              });
              setLoading(false);
              return;
            }

            if (typeof parsedData === "string") {
              try {
                parsedData = JSON.parse(parsedData);
                console.log(
                  "After second JSON.parse, typeof parsedData:",
                  typeof parsedData,
                );
                console.log(
                  "Parsed personality survey data after second parse:",
                  parsedData,
                );
              } catch (secondParseError) {
                console.error(
                  "Error parsing personality_survey JSON on second attempt:",
                  secondParseError,
                );
                setMessage({
                  type: "error",
                  text: "Invalid survey data format.",
                });
                setLoading(false);
                return;
              }
            }

            if (
              typeof parsedData === "object" &&
              parsedData !== null &&
              !Array.isArray(parsedData)
            ) {
              console.log("Parsed data is a valid object.");
              const initializedAnswers: Answers = {};
              let allKeysPresent = true;

              questions.forEach((q) => {
                const answer = parsedData[q];
                console.log(`Question: "${q}", Answer: ${answer}`);
                if (typeof answer === "number") {
                  initializedAnswers[q] = answer;
                } else {
                  console.warn(
                    `No valid answer found for question: "${q}", setting to null.`,
                  );
                  initializedAnswers[q] = null;
                  allKeysPresent = false;
                }
              });

              if (!allKeysPresent) {
                setMessage({
                  type: "warning",
                  text: "Some answers were missing or invalid. Please complete all questions.",
                });
              }

              console.log("Initialized answers:", initializedAnswers);
              setAnswers(initializedAnswers);
            } else {
              console.error(
                "Parsed personality_survey is neither an object nor an array.",
              );
              setMessage({
                type: "error",
                text: "Invalid survey data format.",
              });
            }
          } else {
            console.log(
              "No previous personality survey data found. Initializing with nulls.",
            );
            const initializedAnswers = questions.reduce<Answers>(
              (acc, q) => ({
                ...acc,
                [q]: null,
              }),
              {},
            );
            setAnswers(initializedAnswers);
          }
        } else {
          console.log("No user_id found in localStorage.");
          const initializedAnswers = questions.reduce<Answers>(
            (acc, q) => ({
              ...acc,
              [q]: null,
            }),
            {},
          );
          setAnswers(initializedAnswers);
        }
      } catch (error) {
        console.error("Error fetching survey data:", error);
      } finally {
        setLoading(false);
        console.log("Loading state set to false.");
      }
    };
    fetchPreviousData();
  }, []);

  const handleAnswerChange = (question: string, value: number) => {
    console.log(
      `Changing answer for question: "${question}" to value: ${value}`,
    );
    setAnswers((prev) => {
      const updatedAnswers = {
        ...prev,
        [question]: value,
      };
      return updatedAnswers;
    });
  };

  const handleSave = async () => {
    console.log("Initiating save process...");
    const sanitizedAnswers = Object.fromEntries(
      Object.entries(answers).filter(
        ([q, a]) => questions.includes(q) && a !== null,
      ),
    );
    console.log("Sanitized answers to be saved:", sanitizedAnswers);

    if (Object.keys(sanitizedAnswers).length !== questions.length) {
      console.error("Not all questions have been answered.");
      setMessage({
        type: "error",
        text: "Please answer all the questions before saving.",
      });
      return;
    }

    setSaving(true);
    setMessage(null);
    console.log("Saving state set to true.");

    const userId = localStorage.getItem("user_id");
    if (!userId) {
      console.error("User ID not found in localStorage.");
      setMessage({
        type: "error",
        text: "User ID not found. Please log in again.",
      });
      setSaving(false);
      return;
    }

    try {
      console.log(`Attempting to PATCH survey data for user_id: ${userId}`);
      const response = await axiosInterceptor.patch(
        `/save_or_update_user_surveys/${userId}/`,
        { personality_survey: JSON.stringify(sanitizedAnswers) },
      );
      console.log("PATCH response:", response);
      if (response.status === 200) {
        console.log(
          "Survey data successfully updated. Navigating to survey-success.",
        );
        navigate("/profile");
      }
    } catch (error: any) {
      console.error("Error during PATCH request:", error);
      if (error.response?.status === 404) {
        try {
          console.log(
            `PATCH failed with 404. Attempting to POST survey data for user_id: ${userId}`,
          );
          const postResponse = await axiosInterceptor.post(
            `/save_or_update_user_surveys/${userId}/`,
            { personality_survey: JSON.stringify(sanitizedAnswers) },
          );
          console.log("POST response:", postResponse);
          if (postResponse.status === 201 || postResponse.status === 200) {
            console.log(
              "Survey data successfully created. Navigating to survey-success.",
            );
            navigate("/profile");
          }
        } catch (postError) {
          console.error("Error during POST request:", postError);
          setMessage({
            type: "error",
            text: "Failed to submit survey responses.",
          });
        }
      } else {
        setMessage({
          type: "error",
          text: "Failed to update survey responses.",
        });
      }
    } finally {
      setSaving(false);
      console.log("Saving state set to false.");
    }
  };

  if (loading) {
    console.log("Loading survey...");
    return (
      <div className="flex items-center justify-center h-screen bg-background-root dark:bg-background-dark-root">
        <p className="text-xl text-foreground-dimmest dark:text-foreground-dimmest">
          Loading survey...
        </p>
      </div>
    );
  }

  return (
    <div className="bg-background-root dark:bg-background-dark-root max-w-4xl mx-auto p-8 text-foreground dark:text-foreground-dark">
      <div className="mb-8">
        <div className="flex items-center justify-start mb-2">
          <img src={Logo} alt="Logo" className="h-10" />
        </div>
        <h1 className="text-3xl font-bold mb-4">Personality Test</h1>
        <p className="text-foreground-dimmest dark:text-foreground-dimmest">
          <strong>Instructions:</strong> For each statement, rate how much you
          agree with it on a scale of 1–5, where:
        </p>
        <div className="text-foreground-dimmest dark:text-foreground-dimmest mt-2 space-y-1">
          <p>1 = Disagree</p>
          <p>2 = Slightly Disagree</p>
          <p>3 = Neutral</p>
          <p>4 = Slightly Agree</p>
          <p>5 = Agree</p>
        </div>
        <br />
        <p className="text-foreground-dimmest dark:text-foreground-dimmest">
          <strong>Note:</strong> The questions should be read starting with the
          word "I" to refer to yourself.
        </p>
      </div>

      {message && (
        <div
          className={`mb-6 p-4 rounded ${
            message.type === "success"
              ? "bg-green-900 text-green-300"
              : "bg-red-900 text-red-300"
          }`}
        >
          {message.text}
        </div>
      )}

      <div className="space-y-16">
        {questions.map((question, qIndex) => (
          <div key={qIndex} className="space-y-4">
            <h2 className="text-xl font-medium">{question}</h2>
            <div className="flex items-center justify-between px-4">
              <span className="text-sm font-medium">Disagree</span>
              <div className="flex-1 flex justify-center space-x-8">
                {[...Array(5)].map((_, i) => {
                  const isSelected = answers[question] === i + 1;
                  return (
                    <button
                      key={i}
                      onClick={() => handleAnswerChange(question, i + 1)}
                      className={`${buttonSizes[i]} rounded-full border-2 transition-colors duration-200 cursor-pointer ${isSelected ? "bg-accent-primary border-accent-primary" : "border-background-deepest dark:border-background-dark-deepest"}`}
                      aria-label={`Option ${i + 1} for "${question}"`}
                    />
                  );
                })}
              </div>
              <span className="text-sm font-medium text-accent-primary">
                Agree
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-16 mb-8">
        {saving ? (
          <button
            className="w-full bg-accent-primarytext-white py-4 rounded cursor-not-allowed opacity-75"
            disabled
          >
            Saving...
          </button>
        ) : (
          <button
            onClick={handleSave}
            className="w-full bg-accent-primarytext-white py-4 rounded hover:bg-[#0035D8] transition-colors"
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default PersonalitySurvey;
