import { FC, MouseEventHandler, ReactElement } from "react";

export interface ButtonProps {
  icon?: ReactElement<SVGElement>;
  message: string;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
}

export const PrimaryButton: FC<ButtonProps> = ({
  icon,
  message,
  handleClick,
}) => {
  return (
    <button
      className="rounded-sm bg-accent-primary hover:bg-accent-primary-dimmer  flex flex-row gap-2 items-center justify-center h-8 p-3 transition-colors w-min focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accent-primary focus-visible:ring-offset-2"
      onClick={handleClick}
    >
      {icon}
      <p className="text-background  whitespace-nowrap">{message}</p>
    </button>
  );
};

export const DefaultButton: FC<ButtonProps> = ({
  icon,
  message,
  handleClick,
}) => {
  return (
    <button
      className="rounded-sm bg-background-root dark:bg-background-dark-dimmer hover:bg-background-dimmer dark:hover:bg-background-dark-dimmest flex flex-row gap-2 items-center justify-center h-8 p-3 border-[1px] border-background-deepest dark:border-background-dark-dimmest transition-colors w-min focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-foreground-dimmest focus-visible:ring-offset-2"
      onClick={handleClick}
    >
      {icon}
      <p className="text-foreground dark:text-foreground-dark whitespace-nowrap">
        {message}
      </p>
    </button>
  );
};

export const PositiveButton: FC<ButtonProps> = ({
  icon,
  message,
  handleClick,
}) => {
  return (
    <button
      className="rounded-sm bg-accent-positive hover:bg-accent-positive-dimmer flex flex-row gap-2 items-center justify-center h-8 p-3 transition-colors w-min focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accent-positive focus-visible:ring-offset-2"
      onClick={handleClick}
    >
      {icon}
      <p className="text-background whitespace-nowrap">{message}</p>
    </button>
  );
};

export const NegativeButton: FC<ButtonProps> = ({
  icon,
  message,
  handleClick,
}) => {
  return (
    <button
      className="rounded-sm bg-accent-negative hover:bg-accent-negative-dimmer flex flex-row gap-2 items-center justify-center h-8 p-3 transition-colors w-min focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accent-negative focus-visible:ring-offset-2"
      onClick={handleClick}
    >
      {icon}
      <p className="text-background whitespace-nowrap">{message}</p>
    </button>
  );
};

export const LightNegativeButton: FC<ButtonProps> = ({
  icon,
  message,
  handleClick,
}) => {
  return (
    <button
      className="rounded-sm bg-background-root hover:bg-background-dimmer dark:hover:bg-background-dimmest flex flex-row gap-2 items-center justify-center h-8 p-3 transition-colors w-min border border-background-deepest focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accent-negative focus-visible:ring-offset-2"
      onClick={handleClick}
    >
      {icon}
      <p className="text-accent-negative whitespace-nowrap">{message}</p>
    </button>
  );
};
