// src/routes/profile/EditProfile.tsx
import React, { useState } from "react";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { Button, Container, Form, Stack } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { PersonalInfo } from "./interfaces";
import FormInput from "../../components/FormInput";
import { EditProfileProps, FormControlElement } from "./interfaces";

const EditProfile: React.FC<EditProfileProps> = ({ existingInfo }) => {
  const BLANK_PERSONAL: PersonalInfo = {
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
    street_addr: "",
    city_addr: "",
    state_addr: "",
    zip_code: "",
    country_addr: "",
    password: "",
    password_verify: "",
    dob: "",
    has_visa: "Yes",
    job_country: [],
    linkedin: "",
    github: "",
    website: "",
  };

  const initialInfo =
    existingInfo && JSON.stringify(existingInfo) !== "{}"
      ? existingInfo
      : BLANK_PERSONAL;

  const [user, setPersonal] = useState<PersonalInfo>(initialInfo);
  const [countries, setCountries] = useState<string[]>(initialInfo.job_country);

  const handleChange: React.ChangeEventHandler<FormControlElement> = (e) => {
    setPersonal({ ...user, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (selected: string[]) => {
    setCountries(selected);
    setPersonal({ ...user, job_country: selected });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const userId = localStorage.getItem("user_id");

    try {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
      await axiosInterceptor.patch(`/updateuser/${userId}/`, user, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken || "",
        },
      });
      window.location.reload();
    } catch (error: any) {
      if (error.response?.data?.email) {
        alert(
          "A user with this email already exists. Please use a different email.",
        );
      } else {
        console.error("Error updating user:", error);
      }
    }
  };

  const visaOptions = ["Yes", "No"];
  const countryOptions = [
    "United States",
    "Canada",
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and/or Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Croatia (Hrvatska)",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecudaor",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "France, Metropolitan",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard and Mc Donald Islands",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfork Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "St. Helena",
    "St. Pierre and Miquelon",
    "Sudan",
    "Suriname",
    "Svalbarn and Jan Mayen Islands",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States minor outlying islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City State",
    "Venezuela",
    "Vietnam",
    "Virigan Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna Islands",
    "Western Sahara",
    "Yemen",
    "Yugoslavia",
    "Zaire",
    "Zambia",
    "Zimbabwe",
  ];

  return (
    <Container className="bg-background-root dark:bg-background-dark-root text-foreground dark:text-foreground-dark">
      <Form onSubmit={handleSubmit}>
        <Stack gap={2}>
          <h3 className="text-foreground dark:text-foreground-dark">
            Personal Information
          </h3>
          <FormInput
            name="firstname"
            label="First Name"
            type="text"
            value={user.firstname}
            handler={handleChange}
            required
            dark
          />
          <FormInput
            name="lastname"
            label="Last Name"
            type="text"
            value={user.lastname}
            handler={handleChange}
            required
            dark
          />
          <FormInput
            name="email"
            label="Email"
            type="email"
            value={user.email}
            handler={handleChange}
            required
            dark
          />
          <FormInput
            name="phone_number"
            label="Phone Number"
            type="tel"
            value={user.phone_number}
            handler={handleChange}
            required
            dark
          />
          <Form.Label className="text-foreground-dimmest dark:text-foreground-dimmest m-0">
            Birthday
          </Form.Label>
          <Form.Control
            type="date"
            name="dob"
            placeholder="Birthday"
            onChange={handleChange}
            value={user.dob}
            className="bg-background dark:bg-background-dark"
            style={{
              color: "#DDDDDD",
              border: "1px solid #333",
            }}
          />
          <h3 className="text-foreground dark:text-foreground-dark mt-4">
            Address Information
          </h3>
          <FormInput
            name="street_addr"
            label="Street"
            type="text"
            value={user.street_addr}
            handler={handleChange}
            required
            dark
          />
          <FormInput
            name="city_addr"
            label="City"
            type="text"
            value={user.city_addr}
            handler={handleChange}
            required
            dark
          />
          <FormInput
            name="state_addr"
            label="State"
            type="text"
            value={user.state_addr}
            handler={handleChange}
            required
            dark
          />
          <FormInput
            name="zip_code"
            label="Zip Code"
            type="text"
            value={user.zip_code}
            handler={handleChange}
            required
            dark
          />
          <FormInput
            name="country_addr"
            label="Country"
            type="text"
            value={user.country_addr}
            handler={handleChange}
            required
            dark
          />
          <h3 className="text-foreground dark:text-foreground-dark mt-4">
            Work Information
          </h3>
          <Form.Label className="m-0 text-foreground-dimmest dark:text-foreground-dimmest">
            Have a US Work Visa:
          </Form.Label>
          <Form.Group controlId="formDropdown">
            <Form.Control
              as="select"
              name="has_visa"
              value={user.has_visa}
              onChange={handleChange}
              className="bg-background dark:bg-background-dark"
              style={{
                color: "#DDDDDD",
                border: "1px solid #333",
              }}
            >
              {visaOptions.map((visa) => (
                <option key={visa} value={visa}>
                  {visa}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="m-0">
            <Form.Label className="text-foreground-dimmest dark:text-foreground-dimmest">
              Countries Authorized to Work
            </Form.Label>
            <Typeahead
              id="country-select"
              multiple
              className="bg-background dark:bg-background-dark"
              onChange={(selected) => handleCountryChange(selected as string[])}
              options={countryOptions}
              placeholder="Select your Countries"
              selected={countries}
              allowNew
              newSelectionPrefix=""
            />
          </Form.Group>
          <h3 className="text-foreground dark:text-foreground-dark mt-4">
            Professional Info
          </h3>
          <FormInput
            name="linkedin"
            label="LinkedIn"
            type="url"
            value={user.linkedin}
            handler={handleChange}
            required
            dark
          />
          <FormInput
            name="github"
            label="GitHub"
            type="url"
            value={user.github}
            handler={handleChange}
            required
            dark
          />
          <FormInput
            name="website"
            label="Website"
            type="url"
            value={user.website}
            handler={handleChange}
            required
            dark
          />
          <Container style={{ textAlign: "center" }} className="mt-4">
            <Button
              type="submit"
              style={{
                backgroundColor: "#003DF5",
                borderColor: "#003DF5",
                color: "#DDDDDD",
              }}
            >
              Done
            </Button>
          </Container>
        </Stack>
      </Form>
    </Container>
  );
};

export default EditProfile;

// import React, { useState } from "react";
// import axiosInterceptor from "../../utils/axiosInterceptor";
// import { Button, Container, Form, Stack } from "react-bootstrap";
// import { Typeahead } from "react-bootstrap-typeahead";
// import { PersonalInfo } from "./interfaces";
// import FormInput from "../../components/FormInput";
// import { EditProfileProps, FormControlElement } from "./interfaces";

// const EditProfile: React.FC<EditProfileProps> = ({ existingInfo }) => {
//   const BLANK_PERSONAL: PersonalInfo = {
//     firstname: "",
//     lastname: "",
//     email: "",
//     phone_number: "",
//     street_addr: "",
//     city_addr: "",
//     state_addr: "",
//     zip_code: "",
//     country_addr: "",
//     password: "",
//     password_verify: "",
//     dob: "",
//     has_visa: "Yes",
//     job_country: [],
//     linkedin: "",
//     github: "",
//     website: "",
//   };

//   const initialInfo =
//     existingInfo && JSON.stringify(existingInfo) !== "{}"
//       ? existingInfo
//       : BLANK_PERSONAL;

//   const [user, setPersonal] = useState<PersonalInfo>(initialInfo);
//   const [countries, setCountries] = useState<string[]>(initialInfo.job_country);

//   const handleChange: React.ChangeEventHandler<FormControlElement> = (e) => {
//     setPersonal({ ...user, [e.target.name]: e.target.value });
//   };

//   const handleCountryChange = (selected: string[]) => {
//     setCountries(selected);
//     setPersonal({ ...user, job_country: selected });
//   };

//   const handleSubmit = async (event: React.FormEvent) => {
//     event.preventDefault();
//     const userId = localStorage.getItem("user_id");

//     try {
//       const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
//       await axiosInterceptor.patch(`/updateuser/${userId}/`, user, {
//         headers: {
//           "Content-Type": "application/json",
//           "X-CSRFToken": csrfToken || "",
//         },
//       });
//       window.location.reload();
//     } catch (error: any) {
//       if (error.response?.data?.email) {
//         alert(
//           "A user with this email already exists. Please use a different email.",
//         );
//       } else {
//         console.error("Error updating user:", error);
//       }
//     }
//   };

//   const visaOptions = ["Yes", "No"];
//   const countryOptions = [
//     "United States",
//     "Canada",
//     "Afghanistan",
//     "Albania",
//     "Algeria",
//     "American Samoa",
//     "Andorra",
//     "Angola",
//     "Anguilla",
//     "Antarctica",
//     "Antigua and/or Barbuda",
//     "Argentina",
//     "Armenia",
//     "Aruba",
//     "Australia",
//     "Austria",
//     "Azerbaijan",
//     "Bahamas",
//     "Bahrain",
//     "Bangladesh",
//     "Barbados",
//     "Belarus",
//     "Belgium",
//     "Belize",
//     "Benin",
//     "Bermuda",
//     "Bhutan",
//     "Bolivia",
//     "Bosnia and Herzegovina",
//     "Botswana",
//     "Bouvet Island",
//     "Brazil",
//     "British Indian Ocean Territory",
//     "Brunei Darussalam",
//     "Bulgaria",
//     "Burkina Faso",
//     "Burundi",
//     "Cambodia",
//     "Cameroon",
//     "Cape Verde",
//     "Cayman Islands",
//     "Central African Republic",
//     "Chad",
//     "Chile",
//     "China",
//     "Christmas Island",
//     "Cocos (Keeling) Islands",
//     "Colombia",
//     "Comoros",
//     "Congo",
//     "Cook Islands",
//     "Costa Rica",
//     "Croatia (Hrvatska)",
//     "Cuba",
//     "Cyprus",
//     "Czech Republic",
//     "Denmark",
//     "Djibouti",
//     "Dominica",
//     "Dominican Republic",
//     "East Timor",
//     "Ecudaor",
//     "Egypt",
//     "El Salvador",
//     "Equatorial Guinea",
//     "Eritrea",
//     "Estonia",
//     "Ethiopia",
//     "Falkland Islands (Malvinas)",
//     "Faroe Islands",
//     "Fiji",
//     "Finland",
//     "France",
//     "France, Metropolitan",
//     "French Guiana",
//     "French Polynesia",
//     "French Southern Territories",
//     "Gabon",
//     "Gambia",
//     "Georgia",
//     "Germany",
//     "Ghana",
//     "Gibraltar",
//     "Greece",
//     "Greenland",
//     "Grenada",
//     "Guadeloupe",
//     "Guam",
//     "Guatemala",
//     "Guinea",
//     "Guinea-Bissau",
//     "Guyana",
//     "Haiti",
//     "Heard and Mc Donald Islands",
//     "Honduras",
//     "Hong Kong",
//     "Hungary",
//     "Iceland",
//     "India",
//     "Indonesia",
//     "Iran (Islamic Republic of)",
//     "Iraq",
//     "Ireland",
//     "Israel",
//     "Italy",
//     "Ivory Coast",
//     "Jamaica",
//     "Japan",
//     "Jordan",
//     "Kazakhstan",
//     "Kenya",
//     "Kiribati",
//     "Korea, Democratic People's Republic of",
//     "Korea, Republic of",
//     "Kosovo",
//     "Kuwait",
//     "Kyrgyzstan",
//     "Lao People's Democratic Republic",
//     "Latvia",
//     "Lebanon",
//     "Lesotho",
//     "Liberia",
//     "Libyan Arab Jamahiriya",
//     "Liechtenstein",
//     "Lithuania",
//     "Luxembourg",
//     "Macau",
//     "Macedonia",
//     "Madagascar",
//     "Malawi",
//     "Malaysia",
//     "Maldives",
//     "Mali",
//     "Malta",
//     "Marshall Islands",
//     "Martinique",
//     "Mauritania",
//     "Mauritius",
//     "Mayotte",
//     "Mexico",
//     "Micronesia, Federated States of",
//     "Moldova, Republic of",
//     "Monaco",
//     "Mongolia",
//     "Montserrat",
//     "Morocco",
//     "Mozambique",
//     "Myanmar",
//     "Namibia",
//     "Nauru",
//     "Nepal",
//     "Netherlands",
//     "Netherlands Antilles",
//     "New Caledonia",
//     "New Zealand",
//     "Nicaragua",
//     "Niger",
//     "Nigeria",
//     "Niue",
//     "Norfork Island",
//     "Northern Mariana Islands",
//     "Norway",
//     "Oman",
//     "Pakistan",
//     "Palau",
//     "Panama",
//     "Papua New Guinea",
//     "Paraguay",
//     "Peru",
//     "Philippines",
//     "Pitcairn",
//     "Poland",
//     "Portugal",
//     "Puerto Rico",
//     "Qatar",
//     "Reunion",
//     "Romania",
//     "Russian Federation",
//     "Rwanda",
//     "Saint Kitts and Nevis",
//     "Saint Lucia",
//     "Saint Vincent and the Grenadines",
//     "Samoa",
//     "San Marino",
//     "Sao Tome and Principe",
//     "Saudi Arabia",
//     "Senegal",
//     "Seychelles",
//     "Sierra Leone",
//     "Singapore",
//     "Slovakia",
//     "Slovenia",
//     "Solomon Islands",
//     "Somalia",
//     "South Africa",
//     "South Georgia South Sandwich Islands",
//     "South Sudan",
//     "Spain",
//     "Sri Lanka",
//     "St. Helena",
//     "St. Pierre and Miquelon",
//     "Sudan",
//     "Suriname",
//     "Svalbarn and Jan Mayen Islands",
//     "Swaziland",
//     "Sweden",
//     "Switzerland",
//     "Syrian Arab Republic",
//     "Taiwan",
//     "Tajikistan",
//     "Tanzania, United Republic of",
//     "Thailand",
//     "Togo",
//     "Tokelau",
//     "Tonga",
//     "Trinidad and Tobago",
//     "Tunisia",
//     "Turkey",
//     "Turkmenistan",
//     "Turks and Caicos Islands",
//     "Tuvalu",
//     "Uganda",
//     "Ukraine",
//     "United Arab Emirates",
//     "United Kingdom",
//     "United States minor outlying islands",
//     "Uruguay",
//     "Uzbekistan",
//     "Vanuatu",
//     "Vatican City State",
//     "Venezuela",
//     "Vietnam",
//     "Virigan Islands (British)",
//     "Virgin Islands (U.S.)",
//     "Wallis and Futuna Islands",
//     "Western Sahara",
//     "Yemen",
//     "Yugoslavia",
//     "Zaire",
//     "Zambia",
//     "Zimbabwe",
//   ];

//   return (
//     <Container
//       style={{ maxWidth: 500, padding: 10 }}
//       className="bg-background dark:bg-background-dark text-foreground dark:text-foreground-dark"
//     >
//       <Form onSubmit={handleSubmit}>
//         <Stack gap={2}>
//           <h3 className="text-foreground dark:text-foreground-dark">Personal Information</h3>
//           <FormInput
//             name="firstname"
//             label="First Name"
//             type="text"
//             value={user.firstname}
//             handler={handleChange}
//             required
//             dark
//           />
//           <FormInput
//             name="lastname"
//             label="Last Name"
//             type="text"
//             value={user.lastname}
//             handler={handleChange}
//             required
//             dark
//           />
//           <FormInput
//             name="email"
//             label="Email"
//             type="email"
//             value={user.email}
//             handler={handleChange}
//             required
//             dark
//           />
//           <FormInput
//             name="phone_number"
//             label="Phone Number"
//             type="tel"
//             value={user.phone_number}
//             handler={handleChange}
//             required
//             dark
//           />
//           <Form.Label className="text-foreground-dimmest dark:text-foreground-dimmest m-0">Birthday</Form.Label>
//           <Form.Control
//             type="date"
//             name="dob"
//             placeholder="Birthday"
//             onChange={handleChange}
//             value={user.dob}
//             style={{
//               backgroundColor: "#1C1D21",
//               color: "#DDDDDD",
//               border: "1px solid #333",
//             }}
//           />
//           <h3 className="text-foreground dark:text-foreground-dark mt-4">Address Information</h3>
//           <FormInput
//             name="street_addr"
//             label="Street"
//             type="text"
//             value={user.street_addr}
//             handler={handleChange}
//             required
//             dark
//           />
//           <FormInput
//             name="city_addr"
//             label="City"
//             type="text"
//             value={user.city_addr}
//             handler={handleChange}
//             required
//             dark
//           />
//           <FormInput
//             name="state_addr"
//             label="State"
//             type="text"
//             value={user.state_addr}
//             handler={handleChange}
//             required
//             dark
//           />
//           <FormInput
//             name="zip_code"
//             label="Zip Code"
//             type="text"
//             value={user.zip_code}
//             handler={handleChange}
//             required
//             dark
//           />
//           <FormInput
//             name="country_addr"
//             label="Country"
//             type="text"
//             value={user.country_addr}
//             handler={handleChange}
//             required
//             dark
//           />
//           <h3 className="text-foreground dark:text-foreground-dark mt-4">Work Information</h3>
//           <Form.Label className="m-0 text-foreground-dimmest dark:text-foreground-dimmest">
//             Have a US Work Visa:
//           </Form.Label>
//           <Form.Group controlId="formDropdown">
//             <Form.Control
//               as="select"
//               name="has_visa"
//               value={user.has_visa}
//               onChange={handleChange}
//               style={{
//                 backgroundColor: "#1C1D21",
//                 color: "#DDDDDD",
//                 border: "1px solid #333",
//               }}
//             >
//               {visaOptions.map((visa) => (
//                 <option key={visa} value={visa}>
//                   {visa}
//                 </option>
//               ))}
//             </Form.Control>
//           </Form.Group>
//           <Form.Group className="m-0">
//             <Form.Label className="text-foreground-dimmest dark:text-foreground-dimmest">
//               Countries Authorized to Work
//             </Form.Label>
//             <Typeahead
//               id="country-select"
//               multiple
//               onChange={(selected) => handleCountryChange(selected as string[])}
//               options={countryOptions}
//               placeholder="Select your Countries"
//               selected={countries}
//               allowNew
//               newSelectionPrefix=""
//             />
//           </Form.Group>
//           <h3 className="text-foreground dark:text-foreground-dark mt-4">Professional Info</h3>
//           <FormInput
//             name="linkedin"
//             label="LinkedIn"
//             type="url"
//             value={user.linkedin}
//             handler={handleChange}
//             required
//             dark
//           />
//           <FormInput
//             name="github"
//             label="GitHub"
//             type="url"
//             value={user.github}
//             handler={handleChange}
//             required
//             dark
//           />
//           <FormInput
//             name="website"
//             label="Website"
//             type="url"
//             value={user.website}
//             handler={handleChange}
//             required
//             dark
//           />
//           <Container style={{ textAlign: "center" }} className="mt-4">
//             <Button
//               type="submit"
//               style={{
//                 backgroundColor: "#003DF5",
//                 borderColor: "#003DF5",
//                 color: "#DDDDDD",
//               }}
//             >
//               Done
//             </Button>
//           </Container>
//         </Stack>
//       </Form>
//     </Container>
//   );
// };

// export default EditProfile;
