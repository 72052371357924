import PointsGlobe from "../../assets/img/landing/points-globe.png";

const GuideTransition = () => {
  return (
    <div className="w-full flex justify-center py-20">
      <div
        style={{ backgroundImage: `url(${PointsGlobe})` }}
        className="w-[40rem] h-[30rem] flex flex-col justify-center items-center bg-contain bg-no-repeat"
      >
        <h2 className="text-background text-5xl text-center font-semibold">
          Here's How AlgoLink Guides <br /> You to Your Dream Career
        </h2>
      </div>
    </div>
  );
};

export default GuideTransition;
