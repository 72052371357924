import { questionMapping } from "./constants";

// Function to transform answers before sending to backend
export function transformAnswers(
  rawAnswers: Record<string, number>,
): Record<number, number> {
  const transformedAnswers: Record<number, number> = {};

  Object.entries(rawAnswers).forEach(([question, answer]) => {
    const questionNumber = questionMapping[question];
    if (questionNumber) {
      transformedAnswers[questionNumber] = answer;
    }
  });

  return transformedAnswers;
}

// Function to validate answers
export function validateAnswers(answers: Record<number, number>): boolean {
  // Check if we have all 50 questions
  if (Object.keys(answers).length !== 50) {
    return false;
  }

  // Check if all answers are between 1 and 5
  return Object.values(answers).every((value) => value >= 1 && value <= 5);
}
