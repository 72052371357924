/**
 * JobCard.tsx
 * Component for individual job listing in the left column
 * Updated to match Figma design
 */

import React from "react";
import { ConsolidatedJob } from "../interfaces";

const JobCard: React.FC<{
  job: ConsolidatedJob;
  isSelected: boolean;
  onSelect: () => void;
}> = ({ job, isSelected, onSelect }) => {
  // Format salary to display in $XXk format
  const formatSalary = (value: number) => {
    return `${Math.round(value / 1000)}k`;
  };

  return (
    <div
      className={`cursor-pointer transition-colors 
      ${isSelected ? "bg-background dark:bg-background-dark-dimmer border-background-dimmest dark:border-background-dark-deepest shadow-sm" : "bg-background-root dark:bg-background-dark border-background-dimmest  dark:border-background-dark-dimmest"} 
        w-[400px] p-4 rounded-sm border-[1px] border-solid `}
      onClick={onSelect}
    >
      <div>
        <h3 className="text-xl font-semibold text-foreground dark:text-foreground-dark mb-1">
          {job.title}
        </h3>

        <div className="flex items-center text-base">
          <span className="text-foreground-dimmest dark:text-foreground-dark-dimmest">
            {job.company_details?.company_name}
          </span>
          {job.city && (
            <>
              <span className="mx-2 text-foreground-dimmest dark:text-foreground-dark-dimmest">
                •
              </span>
              <span className="text-foreground-dimmest dark:text-foreground-dark-dimmest">
                {job.city}
              </span>
            </>
          )}
        </div>

        <div className="mt-1 flex flex-wrap text-base text-foreground-dimmest dark:text-foreground-dark-dimmest">
          <span>
            {formatSalary(job.compensationLowerBound)} -{" "}
            {formatSalary(job.compensationUpperBound)}
          </span>
          {job.eligible_for_visa_sponsorship && (
            <>
              <span className="mx-2 text-foreground-dimmest dark:text-foreground-dark-dimmest">
                •
              </span>
              <span>Visa Sponsorship</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobCard;
