import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import axiosInterceptor from "../../utils/axiosInterceptor";
import sendVerificationCode from "../../utils/sendEmailVerification";
import backgroundImage from "../../assets/img/landing/signup-background.png";
import {
  BLANK_SIGNUP,
  SignUpErrors,
  SignUpFields,
  SignUpModal,
} from "../../components/LandingComponents";
import TopNavigation from "../home/TopNavigation";

function SignUp(): JSX.Element {
  const navigate = useNavigate();
  const [signup, setSignup] = useState<SignUpFields>(BLANK_SIGNUP);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errors, setErrors] = useState<SignUpErrors>({});
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const isAuthenticated = !!localStorage.getItem("access_token");
    if (isAuthenticated) {
      navigate("/");
    }
  }, [navigate]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setSignup({ ...signup, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);

    const eduEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.edu$/;
    const isEmailValid = eduEmailRegex.test(signup.email);

    const newErrors: SignUpErrors = {
      firstName: !signup.firstName,
      lastName: !signup.lastName,
      email: !signup.email || !isEmailValid,
      confirmEmail:
        !signup.confirmEmail || signup.email !== signup.confirmEmail,
      password: !signup.password,
      confirmPassword:
        !signup.confirmPassword || signup.password !== signup.confirmPassword,
      agreeTerms: !agreeTerms,
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some(Boolean)) {
      setErrorMessage(
        isEmailValid
          ? "Please fill in all required fields correctly."
          : "Email must end in .edu",
      );
      setIsSubmitting(false);
      return;
    }

    try {
      const userRegistrationData = {
        firstname: signup.firstName,
        lastname: signup.lastName,
        email: signup.email,
        password: signup.password,
        password_verify: signup.confirmPassword,
      };

      await axiosInterceptor.post("/basic_register/", userRegistrationData);

      const loginData = {
        email: signup.email,
        password: signup.password,
      };
      const loginResponse = await axiosInterceptor.post("/login/", loginData);

      const {
        refresh,
        access,
        UserID,
        is_email_verified,
        is_verified_by_algolink,
      } = loginResponse.data;

      localStorage.setItem("access_token", access);
      localStorage.setItem("refresh_token", refresh);
      localStorage.setItem("user_id", UserID);
      localStorage.setItem("is_email_verified", is_email_verified);
      localStorage.setItem("is_algolink_admin", "false");
      localStorage.setItem("is_verified_by_algolink", is_verified_by_algolink);
      localStorage.setItem(
        "user_name",
        `${signup.firstName} ${signup.lastName}`,
      );

      await sendVerificationCode(signup.email);
      navigate("/account/verify");
    } catch (error: any) {
      console.error("Error:", error);

      if (error.response && error.response.data) {
        const errorData = error.response.data;
        let localErrorMessage = "";

        if (
          Array.isArray(errorData) &&
          errorData.length > 1 &&
          typeof errorData[1] === "object"
        ) {
          const errorObj = errorData[1];
          for (const [field, messages] of Object.entries(errorObj)) {
            if (Array.isArray(messages)) {
              localErrorMessage += `${capitalizeFirstLetter(field)}: ${messages.join(", ")}\n`;
            }
          }
        } else if (typeof errorData === "string") {
          localErrorMessage = errorData;
        } else {
          localErrorMessage =
            "An unexpected error occurred during registration.";
        }
        setErrorMessage(localErrorMessage.trim());
      } else {
        setErrorMessage("An error occurred during registration.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className="flex flex-col min-h-screen bg-landing-background relative">
      <TopNavigation showCompleteNavigation={false} />
      {/* Background with particles effect */}
      <div
        className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat opacity-20"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "50% auto",
        }}
      ></div>

      <div className="relative z-10 flex flex-row flex-grow items-center justify-center px-4">
        <SignUpModal
          firstName={{
            value: signup.firstName,
            hasError: errors.firstName ? true : false,
            errorMessage: "First name is required",
          }}
          lastName={{
            value: signup.lastName,
            hasError: errors.lastName ? true : false,
            errorMessage: "Last name is required",
          }}
          email={{
            value: signup.email,
            hasError: errors.email ? true : false,
            errorMessage: "An institutional .edu email is required",
          }}
          confirmEmail={{
            value: signup.confirmEmail,
            hasError: errors.confirmEmail ? true : false,
            errorMessage:
              signup.email !== signup.confirmEmail
                ? "Emails do not match"
                : "Please confirm your email",
          }}
          password={{
            value: signup.password,
            hasError: errors.password ? true : false,
            errorMessage: "A password is required",
          }}
          confirmPassword={{
            value: signup.confirmPassword,
            hasError: errors.confirmPassword ? true : false,
            errorMessage:
              signup.password !== signup.confirmPassword
                ? "Passwords do not match"
                : "Please confirm your password",
          }}
          isSubmitting={isSubmitting}
          agreeTerms={agreeTerms}
          errorMessage={errorMessage}
          handleAgreeTermsChange={(agreementValue) =>
            setAgreeTerms(agreementValue)
          }
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}

export default SignUp;
