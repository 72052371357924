import React, { useState, useEffect } from "react";
import CareerCard from "./career/CareerCard";
import { PDFViewer } from "./career/DocumentViewer";
import EducationComponent, { CreateEdu } from "./career/Education";
import ExperienceComponent, { CreateExp } from "./career/Experience";
import { ExternalCourse, CreateCourse } from "./career/ExternalCourses";
import { CreateSkill } from "./career/Skills";
import ResumeComponent, { CreateRes } from "./career/Resume";
import TranscriptComponent, { CreateTrans } from "./career/Transcript";
import PersonalityScoreCard from "./surveys/PersonalityScoreCard";
import axiosInterceptor from "../../utils/axiosInterceptor";

// For the "Top 5 Jobs" display
import Badge from "../../components/Badge";
import { SurveyLink } from "./surveys/SurveyLink";

import {
  Skill,
  EducationItem,
  ExperienceInfo,
  Course,
  ResumeItem,
  TranscriptItem,
  PersonalityScores,
  FullUserData,
} from "./interfaces";

interface CareerProfileProps {
  userData: FullUserData;
  personalityScores?: PersonalityScores;
  userId: string;
}

const BLANK_SKILLS: Skill[] = [];
const BLANK_EDUCATION: EducationItem[] = [];
const BLANK_COURSES: Course[] = [];
const BLANK_EXPERIENCE: ExperienceInfo[] = [];
const BLANK_RESUME: ResumeItem[] = [];
const BLANK_TRANSCRIPT: TranscriptItem[] = [];

const CareerProfile: React.FC<CareerProfileProps> = ({
  userData,
  personalityScores,
  userId,
}) => {
  // States for arrays
  const [education, setEducation] = useState<EducationItem[]>(BLANK_EDUCATION);
  const [experiences, setExperiences] =
    useState<ExperienceInfo[]>(BLANK_EXPERIENCE);
  const [courses, setCourses] = useState<Course[]>(BLANK_COURSES);
  const [skills, setSkills] = useState<Skill[]>(BLANK_SKILLS);
  const [resume, setResume] = useState<ResumeItem[]>(BLANK_RESUME);
  const [transcript, setTranscript] =
    useState<TranscriptItem[]>(BLANK_TRANSCRIPT);

  // Loading states
  const [isResumeLoading, setIsResumeLoading] = useState(false);
  const [isTranscriptLoading, setIsTranscriptLoading] = useState(false);
  const [resumeRefreshTrigger, setResumeRefreshTrigger] = useState(0);
  const [transcriptRefreshTrigger, setTranscriptRefreshTrigger] = useState(0);

  // Refresh logic
  const refreshResume = async () => {
    try {
      setIsResumeLoading(true);
      const response = await axiosInterceptor.get<FullUserData>(
        `/getuser/${userId}/`,
      );
      setResume(response.data.resume || []);
      setResumeRefreshTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Error refreshing resume:", error);
    } finally {
      setIsResumeLoading(false);
    }
  };

  const refreshTranscript = async () => {
    try {
      setIsTranscriptLoading(true);
      const response = await axiosInterceptor.get<FullUserData>(
        `/getuser/${userId}/`,
      );
      setTranscript(response.data.transcript || []);
      setTranscriptRefreshTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Error refreshing transcript:", error);
    } finally {
      setIsTranscriptLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [allDataResponse] = await Promise.all([
          axiosInterceptor.get<FullUserData>(`/getuser/${userId}/`),
        ]);
        const uData = allDataResponse.data;
        setEducation(uData.ed_data || []);
        setCourses(uData.course_data || []);
        setExperiences(uData.exp_data || []);
        setSkills(uData.skill_data || []);
        setResume(uData.resume || []);
        setTranscript(uData.transcript || []);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  // For associating courses with correct education ID
  const userEds = education.map(({ id, school, degree }) => ({
    id,
    school,
    degree,
  }));

  // A dummy list of top 5 roles
  const topJobs = [
    "Software Engineer",
    "Product Designer",
    "UI/UX Designer",
    "Visual Designer",
    "Graphic Designer",
  ];

  return (
    <div className="grid grid-cols-12 gap-5">
      {/* LEFT COLUMN */}
      <div className="col-span-8 space-y-5">
        {/* Education */}
        <CareerCard title="Education" showAddButton={false}>
          <EducationComponent eduList={education} />
          <CreateEdu eduList={education} setEducation={setEducation} />
        </CareerCard>

        {/* Experience */}
        <CareerCard title="Experience" showAddButton={false}>
          <ExperienceComponent expList={experiences} />
          <CreateExp expList={experiences} setExperiences={setExperiences} />
        </CareerCard>

        {/* Skills */}
        <CareerCard title="Skills" showAddButton={false}>
          <div className="mt-2 flex flex-wrap gap-2">
            {skills.map((skill, idx) => (
              <Badge key={idx} text={skill.name} variant="blue" />
            ))}
          </div>
          <CreateSkill skills={skills} setSkills={setSkills} />
        </CareerCard>
      </div>

      {/* RIGHT COLUMN */}
      <div className="col-span-4 space-y-5">
        {/* Resume/CV */}
        <CareerCard
          title="Resume/CV"
          showAddButton={false}
          showDeleteButton={false}
          modalTitle="Manage Resumes"
          isLoading={isResumeLoading}
          onEditModalClose={refreshResume}
        >
          <div>
            <PDFViewer
              userId={userId}
              documentType="Resume"
              refreshTrigger={resumeRefreshTrigger}
            />
            <div className="mt-2">
              <ResumeComponent resume={resume || []} />
            </div>
          </div>
          <CreateRes resume={resume || []} userId={userId} />
        </CareerCard>

        {/* Transcript */}
        <CareerCard
          title="Transcript"
          showAddButton={false}
          showDeleteButton={false}
          modalTitle="Manage Transcripts"
          isLoading={isTranscriptLoading}
          onEditModalClose={refreshTranscript}
        >
          <div>
            <PDFViewer
              userId={userId}
              documentType="Transcript"
              refreshTrigger={transcriptRefreshTrigger}
            />
            <div className="mt-2">
              <TranscriptComponent transcript={transcript || []} />
            </div>
          </div>
          <CreateTrans transcript={transcript || []} userId={userId} />
        </CareerCard>

        {/* Job Preference */}
        <CareerCard
          title="Job Preference"
          showAddButton={false}
          directEditPath="/job-preferences-survey"
        >
          <div>
            <div className="flex flex-wrap gap-2 mt-2">
              {topJobs.map((job, idx) => (
                <Badge key={idx} text={job} variant="blue" />
              ))}
            </div>
            <div className="mt-4"></div>
          </div>
          <div></div>
        </CareerCard>

        {/* Personality */}
        <CareerCard
          title="Personality"
          showAddButton={false}
          directEditPath="/personality-survey"
        >
          <div>
            <PersonalityScoreCard scores={personalityScores} />
            <div className="mt-4"></div>
          </div>
          <div></div>
        </CareerCard>
      </div>
    </div>
  );
};

export default CareerProfile;
