import { useLoaderData } from "react-router-dom";
import Lecture from "./Lecture";
import SEOWrapper from "../../../components/SEOWrapper";
import { LectureContent } from "../types";

export default function LectureWrapper() {
  const content = useLoaderData() as LectureContent;
  const courseName = content.courseId.replaceAll("-", " ");

  let title: string;
  let description: string;

  if (content.type === "pdf") {
    title = `${courseName} - PDF Lecture - AlgoLink`;
    description =
      "Access PDF lecture materials for your AlgoLink course. Enhance your learning with supplementary resources and discussion.";
  } else if (content.type === "video") {
    title = `${courseName} - Video Lecture - AlgoLink`;
    description =
      "Watch video lectures for your AlgoLink course. Access supplementary resources and engage in course discussions.";
  } else {
    title = `${courseName} - AlgoLink`;
    description =
      "Access course materials and resources for your AlgoLink course.";
  }

  return (
    <SEOWrapper title={title} description={description}>
      <Lecture content={content} />
    </SEOWrapper>
  );
}
