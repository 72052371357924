import { LoaderFunctionArgs } from "react-router-dom";
import {
  fetchCourseContent,
  fetchLectureContent,
  fetchNewCourseContent,
} from "./courseUtils";

export const courseOverviewLoader = async ({ params }: LoaderFunctionArgs) => {
  if (params.courseId) return fetchCourseContent(params.courseId);
  else {
    throw new Error(
      "course ID not provided in data loader params, returning default object",
    );
  }
};

export const newCourseOverviewLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  if (
    typeof params.courseId === "string" &&
    params.lectureId !== null &&
    params.lectureId !== undefined &&
    typeof parseInt(params.lectureId) === "number"
  ) {
    return await fetchNewCourseContent(
      params.courseId!,
      parseInt(params.lectureId!),
    );
  } else
    throw new Error(
      "Cannot fetch course content because url parameters are missing. Required: courseId, lectureId",
    );
};

export const lectureContentLoader = async ({ params }: LoaderFunctionArgs) => {
  if (
    typeof params.courseId === "string" &&
    params.lectureId !== null &&
    params.lectureId !== undefined &&
    typeof parseInt(params.lectureId) === "number"
  ) {
    return await fetchLectureContent(
      params.courseId!,
      parseInt(params.lectureId!) - 1,
    );
  } else
    throw new Error(
      "Cannot fetch course content because url parameters are missing. Required: courseId, lectureId",
    );
};
