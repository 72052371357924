import React, { FC } from "react";
import messagesExample from "../../assets/img/landing/message-example.png";
import profileExample from "../../assets/img/landing/profile-example.png";
import testCenterExample from "../../assets/img/landing/test-center-example.png";
import courseMapExample from "../../assets/img/landing/course-map-example.png";
import companyJobsExample from "../../assets/img/landing/company-jobs-example.png";
import AbstractDisplacementMesh from "../../assets/img/landing/abstract-displacement-mesh.png";
import AbstractLandscapeMesh from "../../assets/img/landing/abstract-landscape-mesh.png";
import { LeftRightTimeline } from "../../components/LandingComponents";

type ProcessItem = {
  imageSource: string;
  title: string;
  body: string;
};

const ProcessSteps: React.FC = () => {
  const processItems: ProcessItem[] = [
    {
      imageSource: profileExample,
      title: "Make Your First Impression Count",
      body: "Create a personalized profile that highlights your skills, experiences, and career aspirations. This is your chance to stand out and show recruiters exactly what makes you unique.",
    },
    {
      imageSource: testCenterExample,
      title: "Unlock Your True Potential",
      body: "Start your journey with the Test Center. Complete targeted assessments to reveal your strengths and growth areas. With clear insights, you can chart a course for success and retake the assessments to track your progress. ",
    },
    {
      imageSource: courseMapExample,
      title: "Master New Skills and Stay Ahead",
      body: "Knowledge is your edge. AlgoLink's crash courses are built to help you tackle today's challenges and prepare for tomorrow's opportunities.",
    },
    {
      imageSource: companyJobsExample,
      title: "Find the Right Job, Right Now",
      body: "AlgoLink's smart job-matching system connects you with opportunities that align with your strengths and goals. You'll be matched with roles that are the best fit, giving you a higher chance of success.",
    },
    {
      imageSource: messagesExample,
      title: "Stay In Control of Your Journey",
      body: "Track every step of your recruitment process with ease. Our progress tracker centralizes your applications, interviews, and recruiter feedback in one place. Plus, with the Messages feature, you can communicate directly with recruiters for timely updates, questions, and insights—keeping you proactive and in the loop at every stage.",
    },
  ];

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div
        className="w-full bg-[948px_auto] bg-no-repeat bg-center bg-transparent bg-opacity-10"
        style={{
          backgroundImage: `url(${AbstractDisplacementMesh})`,
        }}
      >
        <ProcessBlock processItems={processItems.slice(0, 2)}></ProcessBlock>
      </div>
      <div
        className="w-full bg-[100%_auto] bg-[left_top_15rem] bg-no-repeat"
        style={{
          backgroundImage: `url(${AbstractLandscapeMesh})`,
        }}
      >
        <ProcessBlock processItems={processItems.slice(2)}></ProcessBlock>
      </div>
      <div className="relative flex flex-col">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="27"
          viewBox="0 0 16 27"
          fill="none"
        >
          <path
            d="M9 1C9 0.447715 8.55228 2.41411e-08 8 0C7.44772 -2.41411e-08 7 0.447715 7 1L9 1ZM7.29289 26.7071C7.68342 27.0976 8.31658 27.0976 8.70711 26.7071L15.0711 20.3431C15.4616 19.9526 15.4616 19.3195 15.0711 18.9289C14.6805 18.5384 14.0474 18.5384 13.6569 18.9289L8 24.5858L2.34314 18.9289C1.95262 18.5384 1.31946 18.5384 0.928931 18.9289C0.538407 19.3195 0.538407 19.9526 0.928931 20.3431L7.29289 26.7071ZM7 1L7 26L9 26L9 1L7 1Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

interface ProcessBlockProps {
  processItems: ProcessItem[];
}

const ProcessBlock: FC<ProcessBlockProps> = ({ processItems }) => {
  return (
    <>
      {processItems.map((processItem, index) =>
        index % 2 === 0 ? (
          <LeftRightTimeline
            leftChild={
              <ProcessImage
                imageSource={processItem.imageSource}
              ></ProcessImage>
            }
            rightChild={
              <ProcessText
                title={processItem.title}
                body={processItem.body}
              ></ProcessText>
            }
            key={index}
          ></LeftRightTimeline>
        ) : (
          <LeftRightTimeline
            rightChild={
              <ProcessImage
                imageSource={processItem.imageSource}
              ></ProcessImage>
            }
            leftChild={
              <ProcessText
                title={processItem.title}
                body={processItem.body}
                rightAlign={true}
              ></ProcessText>
            }
            key={index}
          ></LeftRightTimeline>
        ),
      )}
    </>
  );
};

interface ProcessTextProps {
  title: string;
  body: string;
  rightAlign?: boolean;
}

const ProcessText: FC<ProcessTextProps> = ({
  title,
  body,
  rightAlign = false,
}) => {
  return (
    <div className="flex flex-col gap-8 max-w-[34rem] w-[34rem] -translate-y-2">
      <div
        className="flex items-center justify-center px-6 py-4 bg-neutral-50 bg-opacity-10 rounded-full
       backdrop-filter backdrop-blur-sm"
      >
        <h3 className="text-background text-3xl font-medium text-nowrap">
          {title}
        </h3>
      </div>

      <p
        className={`text-background-dimmest text-lg font-light ${rightAlign ? "text-right pr-8" : "text-left pl-8"}`}
      >
        {body}
      </p>
    </div>
  );
};

interface ProcessImageProps {
  imageSource: string;
}

const ProcessImage: FC<ProcessImageProps> = ({ imageSource }) => {
  return (
    <img
      src={imageSource}
      alt="a career and abilities profile containing education, experience, and external courses"
      className="max-w-[34rem] w-[34rem] mb-32 shadow-lg hover:shadow-2xl hover:scale-[1.02] transition-all duration-300"
    ></img>
  );
};

export default ProcessSteps;
