import { forwardRef, HTMLAttributes } from "react";
import { Dropdown } from "react-bootstrap";

/**
 * Interface for DropdownToggleProps
 * @interface DropdownToggleProps
 * @property {string} message Text to display on the button
 * @property {boolean} isOpen Whether the dropdown is currently open
 */
interface DropdownToggleProps extends HTMLAttributes<HTMLButtonElement> {
  message: string;
  isOpen?: boolean;
}

/**
 * DropdownToggle component
 * @component
 */
export const DropdownToggle = forwardRef<
  HTMLButtonElement,
  DropdownToggleProps
>(({ message, isOpen = false, onClick, ...props }, ref) => {
  return (
    <Dropdown.Toggle
      ref={ref}
      as="button"
      className="rounded-sm bg-background dark:bg-background-dark hover:bg-background-root hover:dark:bg-background-dark-dimmer flex flex-row gap-2 items-center justify-center h-8 p-3 border-[1px] border-background-dimmest dark:border-background-dark-dimmest border-solid transition-colors 
          focus-visible:outline focus-visible:outline-2 focus-visible:outline-[#0059EC] focus-visible:outline-offset-2"
      {...props}
    >
      <p className="text-foreground-dimmer dark:text-foreground-dark-dimmer text-wrap">
        {message}
      </p>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        className={`${
          isOpen ? "-rotate-180" : ""
        } transition-transform ease-out-expo duration-700`}
      >
        <path
          d="M3.50049 5.74996L8.00049 10.25L12.5005 5.74996"
          stroke="#DDDDDD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg> */}
    </Dropdown.Toggle>
  );
});
