/**
 * JobsList.tsx
 * Component for the left column job listings
 * Updated to match Figma design
 */

import React from "react";
import { ConsolidatedJob } from "../interfaces";
import JobCard from "./JobCard";

interface JobsListProps {
  jobs: ConsolidatedJob[];
  selectedJobId: number | null;
  onSelectJob: (jobId: number) => void;
}

const JobsList: React.FC<JobsListProps> = ({
  jobs,
  selectedJobId,
  onSelectJob,
}) => {
  if (jobs.length === 0) {
    return (
      <div className="p-6 text-center">
        <p className="text-foreground-dimmest dark:text-foreground-dark-dimmest text-lg">
          No jobs found in this category.
        </p>
        <p className="text-foreground-dimmest dark:text-foreground-dark-dimmest text-base mt-2">
          Try adjusting your search filters or selecting a different category.
        </p>
      </div>
    );
  }

  return (
    <div className="p-4 flex flex-col items-center gap-2">
      {jobs.map((job) => (
        <JobCard
          key={job.id}
          job={job}
          isSelected={job.id === selectedJobId}
          onSelect={() => onSelectJob(job.id)}
        />
      ))}
    </div>
  );
};

export default JobsList;
