import React from "react";

const RecruitmentSection = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="flex flex-col items-center justify-center max-w-3xl gap-8 text-center px-4">
        <h2 className="font-semibold text-5xl text-[#FFFFFF]">
          <span className="text-white">Smarter Recruiting</span>,
          <br /> Tailored to You.
        </h2>
        <p className="text-white text-lg font-light max-w-2xl">
          The job market is crowded, but your path shouldn't be. AlgoLink
          transforms recruitment into a clear, personalized journey. Whether
          you're assessing your strengths or building new ones, we give you the
          tools to succeed with purpose and precision.
        </p>
      </div>
    </div>
  );
};

export default RecruitmentSection;
