import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GripVertical, Check } from "lucide-react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import HorizontalSelection from "../../../components/HorizontalSelection";

// Core types for the survey
type QuestionType = "multiSelect" | "ranking";
type CategoryType =
  | "industries"
  | "jobTitles"
  | "locations"
  | "companySize"
  | "technologies"
  | "salaryRanges"
  | "jobLevels";

interface QuestionSection {
  title: string;
  subtitle?: string;
  options: readonly string[];
  type: QuestionType;
}

interface MultiSelectAnswers {
  [option: string]: boolean;
}

interface RankingAnswers {
  [option: string]: number;
}

type CategoryAnswers = MultiSelectAnswers | RankingAnswers;
type SurveyAnswers = Partial<Record<CategoryType, CategoryAnswers>>;

interface MessageState {
  type: "success" | "error" | "warning";
  text: string;
}

const borderStyle = {
  borderWidth: "0.5px",
  borderStyle: "solid",
  borderColor: "rgba(56, 58, 64, 1)",
};

const hoverBorderStyle = {
  borderWidth: "0.5px",
  borderStyle: "solid",
  borderColor: "rgba(0, 61, 245, 1)",
};

// Custom checkbox component
const CustomCheckbox: React.FC<{
  id: string;
  checked: boolean;
  onChange: () => void;
}> = ({ id, checked, onChange }) => {
  return (
    <div className="relative mr-3 flex-shrink-0">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className="sr-only" // Hide the actual input
      />
      <div
        className={`h-5 w-5 rounded flex items-center justify-center ${checked ? "bg-accent-primary" : "bg-background dark:bg-background-dark"}`}
        style={{
          borderWidth: "0.5px",
          borderStyle: "solid",
          borderColor: checked ? "#003DF5" : "rgba(56, 58, 64, 1)",
        }}
      >
        {checked && (
          <Check className="h-3.5 w-3.5 text-white" strokeWidth={3} />
        )}
      </div>
    </div>
  );
};

const JobPreferencesSurvey: React.FC = () => {
  const [answers, setAnswers] = useState<SurveyAnswers>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [message, setMessage] = useState<MessageState | null>(null);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const navigate = useNavigate();

  const questions: Record<CategoryType, QuestionSection> = {
    jobLevels: {
      title: "What job levels would you interview for?",
      subtitle: "Select all that apply",
      type: "multiSelect",
      options: [
        "Entry (0-2 years, just starting)",
        "Standard (3-5 years, some experience)",
        "Senior (5+ years, managing team)",
        "Staff (10+ years, managing department)",
        "Principal (15+ years, managing departments)",
        "Distinguished (20+ years, managing organization)",
      ],
    },
    salaryRanges: {
      title: "What salary ranges would you consider?",
      subtitle: "Select all that apply",
      type: "multiSelect",
      options: [
        "60,000-80,000",
        "80,000-100,000",
        "100,000-125,000",
        "125,000-150,000",
        "150,000-175,000",
        "175,000-200,000",
        "200,000-250,000",
        "250,000-300,000",
        "300,000-400,000",
        "400,000-500,000",
        "500,000+",
      ],
    },
    industries: {
      title: "Rank these industries by preference",
      subtitle: "Drag to reorder from most to least preferred",
      type: "ranking",
      options: [
        "Proprietary Trading Firms",
        "Hedge Funds",
        "Big Tech",
        "Tech Startups",
        "BioTech & Healthcare",
        "Finance & Investment Banks",
        "Aerospace & Defense",
        "Entertainment & Gaming",
        "Automotive",
        "Energy",
        "Telecommunications",
        "Consulting",
        "E-Commerce",
        "Manufacturing",
        "Education",
        "Government & Public Sector",
      ],
    },
    jobTitles: {
      title: "Rank your interest in these job titles",
      subtitle: "Order from most to least preferred",
      type: "ranking",
      options: [
        "Quantitative Researcher",
        "Quantitative Trader",
        "Quantitative Developer",
        "Research Scientist",
        "Machine Learning Engineer",
        "Data Scientist",
        "Backend Engineer",
        "Frontend Engineer",
        "Product Manager",
        "Data Analyst",
        "UX/UI Designer",
      ],
    },
    locations: {
      title: "Rate your interest in these locations",
      subtitle: "Order from most to least preferred",
      type: "ranking",
      options: [
        "Remote",
        "Silicon Valley, CA",
        "San Diego, CA",
        "Los Angeles, CA",
        "New York City, NY",
        "Boston, MA",
        "Seattle, WA",
        "Austin, TX",
        "Washington D.C.",
        "Atlanta, GA",
        "Raleigh-Durham, NC",
        "Chicago, IL",
        "Pittsburgh, PA",
        "Minneapolis-St. Paul, MN",
        "Salt Lake City, UT",
      ],
    },
    companySize: {
      title: "Rate your interest in these company sizes",
      subtitle: "Order from most to least preferred",
      type: "ranking",
      options: [
        "2-10 employees",
        "11-50 employees",
        "51-200 employees",
        "201-500 employees",
        "501-1000 employees",
        "1001-5000 employees",
        "5001-10000 employees",
        "10001-50000 employees",
        "50000+ employees",
      ],
    },
    technologies: {
      title: "Rate your interest in these technologies",
      subtitle: "Order from most to least preferred",
      type: "ranking",
      options: [
        "Python",
        "R",
        "C++",
        "Java",
        "Javascript",
        "HTML & CSS",
        "Web Frameworks (Django, Flask)",
        "Distributed Compute (Spark, Ray, Dask)",
        "Cloud Services (AWS, Azure, GCP)",
        "ML Libraries (Scikit, PyTorch, TensorFlow)",
        "Scientific Compute Libraries (Numpy, Pandas/Polars)",
        "Databases (SQL, Cassandra, Kdb)",
        "Data Lakes (Delta Lake, Apache Hudi)",
      ],
    },
  };

  // Handlers for multi-select and ranking
  const handleMultiSelectChange = (
    category: CategoryType,
    option: string,
  ): void => {
    setAnswers((prev) => ({
      ...prev,
      [category]: {
        ...(prev[category] ?? {}),
        [option]: !(prev[category]?.[option] ?? false),
      },
    }));
  };

  // const handleSelectAll = (
  //   category: CategoryType,
  //   options: readonly string[],
  // ): void => {
  //   const allSelected = options.every((opt) => answers[category]?.[opt]);
  //   const newValue = !allSelected;

  //   setAnswers((prev) => ({
  //     ...prev,
  //     [category]: options.reduce(
  //       (acc, opt) => ({
  //         ...acc,
  //         [opt]: newValue,
  //       }),
  //       {},
  //     ),
  //   }));
  // };

  const handleRankingChange = (
    category: CategoryType,
    result: DropResult,
  ): void => {
    if (!result.destination) return;
    const { source, destination } = result;
    const options = questions[category].options;

    const currentOrder = Object.entries(
      (answers[category] as RankingAnswers) || {},
    )
      .sort(([, rankA], [, rankB]) => rankA - rankB)
      .map(([option]) => option);

    if (currentOrder.length === 0) {
      currentOrder.push(...options);
    }

    const [reorderedItem] = currentOrder.splice(source.index, 1);
    currentOrder.splice(destination.index, 0, reorderedItem);

    const newRanking = currentOrder.reduce(
      (acc, option, index) => ({
        ...acc,
        [option]: index + 1,
      }),
      {},
    );

    setAnswers((prev) => ({
      ...prev,
      [category]: newRanking,
    }));
  };

  const handleSave = async (): Promise<void> => {
    setSaving(true);
    setMessage(null);

    const userId = localStorage.getItem("user_id");
    if (!userId) {
      setMessage({
        type: "error",
        text: "User ID not found. Please log in again.",
      });
      setSaving(false);
      return;
    }

    try {
      const response = await axiosInterceptor.patch(
        `/save_or_update_user_surveys/${userId}/`,
        { job_pref_survey: JSON.stringify(answers) },
      );

      if (response.status === 200) {
        navigate("/profile");
      }
    } catch (error: any) {
      if (error.response?.status === 404) {
        try {
          const postResponse = await axiosInterceptor.post(
            `/save_or_update_user_surveys/${userId}/`,
            { job_pref_survey: JSON.stringify(answers) },
          );
          if (postResponse.status === 201 || postResponse.status === 200) {
            navigate("/profile");
          }
        } catch (postError) {
          setMessage({
            type: "error",
            text: "Failed to submit survey responses.",
          });
        }
      } else {
        setMessage({
          type: "error",
          text: "Failed to update survey responses.",
        });
      }
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    const fetchPreviousData = async (): Promise<void> => {
      try {
        const userId = localStorage.getItem("user_id");
        if (!userId) throw new Error("User ID not found");

        const response = await axiosInterceptor.get(
          `/save_or_update_user_surveys/${userId}/`,
        );
        if (response.data?.job_pref_survey) {
          let parsedData = JSON.parse(response.data.job_pref_survey);
          if (typeof parsedData === "string") {
            parsedData = JSON.parse(parsedData);
          }
          setAnswers(parsedData);
        }
      } catch (error) {
        console.error("Error fetching survey data:", error);
        setMessage({
          type: "error",
          text: "Failed to load previous survey data.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchPreviousData();
  }, []);

  // Multi-select question component
  const MultiSelectQuestion: React.FC<{
    category: CategoryType;
    section: QuestionSection;
  }> = ({ category, section: { title, subtitle, options } }) => (
    <div className="mb-12">
      <h2 className="text-lg font-medium mb-2">{title}</h2>
      {subtitle && (
        <p className="text-sm text-foreground dark:text-foreground-dark mb-4">
          {subtitle}
        </p>
      )}

      <div className="grid grid-cols-2 gap-3">
        {options.map((option) => {
          const itemId = `${category}-${option}`;
          const isHovered = hoveredItem === itemId;
          const isSelected =
            (answers[category] as MultiSelectAnswers)?.[option] ?? false;

          return (
            <div
              key={option}
              className="flex items-center bg-background dark:bg-background-dark rounded-md p-3"
              style={isHovered || isSelected ? hoverBorderStyle : borderStyle}
              onMouseEnter={() => setHoveredItem(itemId)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <CustomCheckbox
                id={`option-${itemId}`}
                checked={isSelected}
                onChange={() => handleMultiSelectChange(category, option)}
              />
              <label
                htmlFor={`option-${itemId}`}
                className="text-sm truncate cursor-pointer"
              >
                {option}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );

  // Ranking question component with updated styling
  const RankingQuestion: React.FC<{
    category: CategoryType;
    section: QuestionSection;
  }> = ({ category, section: { title, subtitle, options } }) => {
    const currentOrder = Object.entries(
      (answers[category] as RankingAnswers) || {},
    )
      .sort(([, rankA], [, rankB]) => rankA - rankB)
      .map(([option]) => option);

    const displayOrder = currentOrder.length === 0 ? options : currentOrder;

    return (
      <div className="mb-12">
        <h2 className="text-lg font-medium mb-2">{title}</h2>
        {subtitle && (
          <p className="text-sm text-foreground dark:text-foreground-dark mb-4">
            {subtitle}
          </p>
        )}

        <DragDropContext
          onDragEnd={(result) => handleRankingChange(category, result)}
        >
          <Droppable droppableId={category}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="space-y-3 max-w-[50%]"
              >
                {displayOrder.map((option, index) => (
                  <Draggable key={option} draggableId={option} index={index}>
                    {(provided, snapshot) => {
                      const style = { ...provided.draggableProps.style };
                      const itemId = `rank-${category}-${option}`;
                      const isHovered =
                        hoveredItem === itemId || snapshot.isDragging;

                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...style,
                            ...(isHovered ? hoverBorderStyle : borderStyle),
                          }}
                          className={`flex items-center p-3 bg-background dark:bg-background-dark rounded-md`}
                          onMouseEnter={() => setHoveredItem(itemId)}
                          onMouseLeave={() => setHoveredItem(null)}
                        >
                          <div className="flex items-center w-full">
                            <span className="w-6 h-6 flex items-center justify-center bg-background-root dark:bg-background-dark-rootrounded-full mr-3 text-foreground-dimmer dark:text-foreground-dark-dimmer text-sm font-medium">
                              {index + 1}
                            </span>
                            <span className="flex-1 text-sm text-foreground-dimmer dark:text-foreground-dark-dimmer truncate">
                              {option}
                            </span>
                            <div
                              {...provided.dragHandleProps}
                              className="text-foreground dark:text-foreground-dark hover:text-foreground-dimmer hover:dark:text-foreground-dark-dimmer cursor-grab active:cursor-grabbing p-1"
                            >
                              <GripVertical className="w-5 h-5" />
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-background dark:bg-background-dark">
        <p className="text-xl text-foreground dark:text-foreground-dark">
          Loading survey...
        </p>
      </div>
    );
  }

  return (
    <div className="bg-background-root dark:bg-background-dark-root min-h-screen text-foreground-dimmer dark:text-foreground-dark-dimmer">
      {message && (
        <div
          className={`fixed top-4 right-4 p-4 rounded-lg ${
            message.type === "error"
              ? "bg-[#660000] text-[#FFAAAA]"
              : message.type === "success"
                ? "bg-[#004400] text-[#AAFFAA]"
                : "bg-[#665500] text-[#FFEE88]"
          }`}
        >
          {message.text}
        </div>
      )}

      <div className="p-8">
        <div className="mb-12">
          <h1 className="text-2xl font-bold mb-2">Job Preferences Survey</h1>
          <p className="text-foreground dark:text-foreground-dark">
            The Job Preferences Survey will help us understand your job
            preferences and match you with the most relevant opportunities on
            AlgoLink. Read each question carefully and select all options that
            apply to you.
          </p>
        </div>

        {(Object.entries(questions) as [CategoryType, QuestionSection][]).map(
          ([category, section]) =>
            section.type === "multiSelect" ? (
              <MultiSelectQuestion
                key={category}
                category={category}
                section={section}
              />
            ) : (
              <RankingQuestion
                key={category}
                category={category}
                section={section}
              />
            ),
        )}

        <div className="mt-16 mb-8">
          <button
            onClick={handleSave}
            disabled={saving}
            className="w-full bg-accent-primarytext-white py-4 rounded hover:bg-[#0035D8] transition-colors disabled:opacity-70 disabled:cursor-not-allowed"
          >
            {saving ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobPreferencesSurvey;
