import { useEffect, useState } from "react";
import ScreenSpinner from "../../components/ScreenSpinner";
import axiosInterceptor from "../../utils/axiosInterceptor";

// Interface for our Users
interface User {
  user_id: number;
  full_name: string;
  email: string;
  resume_link?: string;
  joined: string;
}

export default function AdminDashboard() {
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [userData, setUserData] = useState<User[]>([]);

  const loadUserData = () => {
    // Trigger whichever endpoint you need
    axiosInterceptor.get("load_all_data/");
  };

  const accept = async (user: User) => {
    try {
      await axiosInterceptor.patch(`/management/verify_user/${user.user_id}/`);
      setUserData((prevData) =>
        prevData.filter(
          (existingUser) => existingUser.user_id !== user.user_id,
        ),
      );
    } catch (err) {
      console.error("Error accepting user:", err);
    }
  };

  const reject = async (user: User) => {
    try {
      await axiosInterceptor.delete(`/management/reject_user/${user.user_id}/`);
      setUserData((prevData) =>
        prevData.filter(
          (existingUser) => existingUser.user_id !== user.user_id,
        ),
      );
    } catch (err) {
      console.error("Error rejecting user:", err);
    }
  };

  useEffect(() => {
    axiosInterceptor
      .get("/management/get_users_pending_verification/")
      .then((res) => {
        // Sort by full_name for consistency
        const sortedUsers: User[] = res.data.sort((a: User, b: User) =>
          a.full_name.localeCompare(b.full_name),
        );
        setUserData(sortedUsers);
        setFinishedLoading(true);
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
        setFinishedLoading(true);
      });
  }, []);

  if (!finishedLoading) {
    return <ScreenSpinner />;
  }

  return (
    <div className="min-h-screen bg-background-root dark:bg-background-dark-root text-foreground dark:text-foreground-dark flex flex-col pt-4 px-10 gap-4">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Pending User Verifications</h1>
        <div className="text-base text-foreground-dimmest dark:text-foreground-dimmest">
          {userData.length} pending requests
        </div>
      </div>

      {/* Table Container */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-background dark:bg-background-dark shadow-lg rounded-sm">
          <thead>
            <tr className="">
              <th className="py-3 px-4 text-center text-lg font-semibold text-foreground dark:text-foreground-dark">
                #
              </th>
              <th className="py-3 px-4 text-left text-lg font-semibold text-foreground dark:text-foreground-dark">
                Name
              </th>
              <th className="py-3 px-4 text-left text-lg font-semibold text-foreground dark:text-foreground-dark">
                Email
              </th>
              <th className="py-3 px-4 text-left text-lg font-semibold text-foreground dark:text-foreground-dark">
                Resume
              </th>
              <th className="py-3 px-4 text-left text-lg font-semibold text-foreground dark:text-foreground-dark">
                Joined
              </th>
              <th className="py-3 px-4 text-right text-lg font-semibold text-foreground dark:text-foreground-dark">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-[#333333]">
            {userData.map((user, index) => (
              <tr
                key={user.user_id}
                className="hover:bg-background-dimmer dark:hover:bg-background-dark-dimmer transition-colors"
              >
                <td className="py-3 px-4 text-center text-lg text-foreground-dimmer dark:text-foreground-dark-dimmer">
                  {index + 1}
                </td>
                <td className="py-3 px-4 text-lg font-medium text-foreground dark:text-foreground-dark">
                  {user.full_name}
                </td>
                <td className="py-3 px-4 text-lg text-foreground-dimmest dark:text-foreground-dimmest">
                  {user.email}
                </td>
                <td className="py-3 px-4 text-lg">
                  {user.resume_link ? (
                    <a
                      href={user.resume_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-accent-primary hover:text-[#A6C8FF] hover:underline"
                    >
                      View Resume
                    </a>
                  ) : (
                    <span className="text-[#666666]">No Resume</span>
                  )}
                </td>
                <td className="py-3 px-4 text-lg text-foreground-dimmest dark:text-foreground-dimmest">
                  {new Date(user.joined).toLocaleDateString()}
                </td>
                <td className="py-3 px-4 text-right">
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you want to approve ${user.full_name}?`,
                          )
                        ) {
                          accept(user);
                        }
                      }}
                      className="px-3 py-1 text-lg text-foreground dark:text-foreground-dark bg-green-600 hover:bg-green-500 rounded-md transition-colors"
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you want to reject ${user.full_name}?`,
                          )
                        ) {
                          reject(user);
                        }
                      }}
                      className="px-3 py-1 text-lg text-foreground dark:text-foreground-dark bg-red-600 hover:bg-red-500 rounded-md transition-colors"
                    >
                      Reject
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* "Load Data Into Vectors" Button */}
      <div className="mt-40 mb-4">
        <button
          onClick={loadUserData}
          className="px-4 py-2 text-base text-foreground dark:text-foreground-dark bg-accent-primary hover:bg-accent-primary-dimmer  rounded-md transition-colors"
        >
          Load Data Into Vectors
        </button>
      </div>

      {/* If no pending requests */}
      {userData.length === 0 && (
        <div className="text-center py-8 text-foreground-dimmest dark:text-foreground-dimmest">
          No pending verification requests
        </div>
      )}
    </div>
  );
}
