// src/components/Badge.tsx
import React from "react";

interface BadgeProps {
  text: string;
  variant?: "blue" | "green" | "gray"; // Extend as needed
}

const variantStyles = {
  blue: "bg-accent-primary text-background",
  green: "bg-green-50 text-green-600",
  gray: "bg-gray-100 text-gray-600",
};

const Badge: React.FC<BadgeProps> = ({ text, variant = "blue" }) => {
  return (
    <span
      className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium ${variantStyles[variant]}`}
    >
      {text}
    </span>
  );
};

export default Badge;
