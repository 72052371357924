import React, { HtmlHTMLAttributes, MouseEventHandler } from "react";

/**
 *
 * Interface for a vertical progress indicator item. Can be used to make a chain of circles that change depending on its progress
 * @interface ProgressIndicatorProps
 * @property {number} progress Number in range [0, 1] representing the progress for this element
 * @property {boolean} showConnectionLine Whether or not to display a line below this element. Used for drawing connected chains of progress indicators, where all but the last element in a vertical list has showConnectionLine set to true
 */
export interface ProgressIndicatorProps {
  progress: number;
  showConnectionLine: boolean;
  handleClick?: MouseEventHandler<HTMLDivElement>;
}

/**
 * ProgressIndicators are component that display a circular progress indicator. Progress values between (0, 1) produce a semi-shaded circle, a progress value of 0 (not started) produces an empty circle, and a progress value of 1(complete) produces a checked circle
 */

/**
 * @component
 * @param {ProgressIndicatorProps & React.HTMLAttributes<HTMLDivElement>} props
 * @param {number} props.progress A value between 0 and 1 representing the progress. If progress value is negative, then the progress indicator will display a gray dot, which can be useful for disabled states
 * @param {boolean} props.showConnectionLine Whether to show a connection line between this progress component and the next.
 * @param {Function} props.handleClick Callback function triggered on component click. If provided, the progress indicator will have a hover indication and display a pointer instead of the default cursor
 * @param {React.ReactNode} props.children - Content to be rendered inside the component.
 *
 * @example
 * <ProgressIndicator
 *   progress={0.5}
 *   showConnectionLine={true}
 *   handleClick={() => console.log('Clicked!')}
 * >
 *   Course Progress
 * </ProgressIndicator>
 *
 * @returns {React.ReactElement} The rendered ProgressIndicator component
 */
const ProgressIndicator: React.FC<
  ProgressIndicatorProps & HtmlHTMLAttributes<HTMLDivElement>
> = ({ progress, showConnectionLine, handleClick, children }) => {
  const isComplete = progress === 1;
  const isStarted = progress > 0;
  const isDisabled = progress < 0;

  return (
    <div className="flex min-h-fit">
      <div className="flex flex-col items-center">
        <div
          className={`relative w-6 h-6 flex-shrink-0 ${
            handleClick ? "hover:scale-90 cursor-pointer" : ""
          }`}
          onClick={handleClick}
        >
          <div className="absolute inset-0 border-2 border-gray-300 dark:border-foreground-dark/30 rounded-full"></div>

          {isDisabled ? (
            <div className="absolute inset-2 bg-background-dimmest rounded-full"></div>
          ) : (
            isStarted &&
            !isComplete && (
              <div className="absolute inset-2 bg-accent-positive rounded-full scale-90"></div>
            )
          )}

          {!isDisabled && isComplete && (
            <div className="absolute inset-0 bg-accent-positive rounded-full flex items-center justify-center">
              <svg
                className="w-6 h-6 text-foreground dark:text-foreground-dark"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          )}
        </div>
        {showConnectionLine && (
          <div
            className={`w-0.5 h-full ${
              isComplete
                ? "bg-accent-positive"
                : "bg-background-dimmest dark:bg-background-dark-dimmest"
            }`}
          ></div>
        )}
      </div>
      <div className="flex flex-col ml-3 flex-grow">{children}</div>
    </div>
  );
};

export default ProgressIndicator;
