// TODO: convert this to typescript once there are no remaining js or jsx files. will likely need some extra steps to complete the conversion process from a react app to a react typescript app (ex: deployment?)
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element not found");
}
ReactDOM.createRoot(rootElement).render(<App />);
