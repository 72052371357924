// src/routes/profile/career/Skills.tsx
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css"; // base Typeahead styles
import "./DarkTypeahead.css"; // our custom dark overrides

import axiosInterceptor from "../../../utils/axiosInterceptor";
import possibleSkills from "../../../lib/skills";
import { Button } from "react-bootstrap";
import { Skill, CreateSkillProps } from "../interfaces";

/** We no longer display a separate Skills read-only component.
 *  Instead, the user sees only the Typeahead tokens.
 *  If you still want a read-only list somewhere else, keep your old <Skills> component.
 */

/** The main "CreateSkill" for editing user skills in dark mode. */
export const CreateSkill: React.FC<CreateSkillProps> = ({
  skills,
  setSkills,
}) => {
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>(skills);

  // Full list of suggestions
  const options: Skill[] = possibleSkills;

  // Called when user picks or removes skills in the Typeahead
  const handleSkillsChange = (selected: Array<any>) => {
    const converted: Skill[] = selected.map((item) => {
      if (typeof item === "string") {
        return { name: item };
      } else if (item && "name" in item) {
        return { name: (item as Skill).name };
      }
      return { name: String(item) };
    });
    setSelectedSkills(converted);
  };

  // Save button => post newly added skills, delete removed skills, then update parent
  const handleSave = async () => {
    const addedSkills = selectedSkills.filter(
      (skill) => !skills.some((existing) => existing.name === skill.name),
    );
    const removedSkills = skills.filter(
      (existing) =>
        !selectedSkills.some((skill) => skill.name === existing.name),
    );

    try {
      await Promise.all([
        ...addedSkills.map(handleAdd),
        ...removedSkills.map(handleDelete),
      ]);
      setSkills(selectedSkills);
      window.location.reload();
    } catch (error) {
      console.error("Error updating skills:", error);
    }
  };

  // POST a new skill to the backend
  const handleAdd = async (itemToAdd: Skill) => {
    const userId = localStorage.getItem("user_id");
    const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
    try {
      await axiosInterceptor.post(`/create_skill/${userId}/`, itemToAdd, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      });
      // If you have a separate endpoint for "create_skill_vec", do that too
      await axiosInterceptor.post(`/create_skill_vec/${userId}/`, itemToAdd, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      });
    } catch (error) {
      console.error("Error adding skill:", error);
    }
  };

  // DELETE an existing skill from the backend
  const handleDelete = async (itemToRemove: Skill) => {
    const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
    // We need the skill ID if it’s stored
    const skillToDelete = skills.find((s) => s.name === itemToRemove.name);
    if (skillToDelete && skillToDelete.id) {
      try {
        await axiosInterceptor.delete(`/delete_skill/${skillToDelete.id}/`, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        });
      } catch (error) {
        console.error("Error deleting skill:", error);
      }
    }
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()} className="mt-3 text-[#DDDDDD]">
      <Stack gap={2}>
        <Form.Group className="m-0">
          <Typeahead
            id="skill-select"
            className="dark-typeahead"
            labelKey="name"
            multiple
            onChange={handleSkillsChange}
            options={options}
            placeholder="Select your skills"
            selected={selectedSkills}
            allowNew
            newSelectionPrefix="Add a new skill: "
            inputProps={{
              // Force dark styling on the text input
              style: {
                backgroundColor: "#1C1D21",
                color: "#DDDDDD",
                border: "1px solid #383A40",
              },
            }}
            renderMenuItemChildren={(option, props) => {
              if (
                typeof option === "object" &&
                option !== null &&
                "name" in option
              ) {
                return (
                  <span className="text-[#DDDDDD]">
                    {(option as Skill).name}
                  </span>
                );
              }
              return <span className="text-[#DDDDDD]">{String(option)}</span>;
            }}
          />
          {/* No second row of badges; the tokens appear inside the Typeahead itself */}
          <Button
            onClick={handleSave}
            className="ml-auto mt-4 float-end"
            variant="dark"
            style={{
              backgroundColor: "#003DF5",
              borderColor: "#003DF5",
              color: "#DDDDDD",
            }}
          >
            Save
          </Button>
        </Form.Group>
      </Stack>
    </Form>
  );
};
