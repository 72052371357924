// src/components/DateInput.tsx
import React from "react";
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import "./DarkDateInput.css";

interface SimpleDateInputProps {
  sd: string; // The "MM/DD/YYYY" display string for start date
  ed: string; // The "MM/DD/YYYY" display string for end date
  handler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEndDateReadOnly?: boolean;
}

export const SimpleDateInput: React.FC<SimpleDateInputProps> = ({
  sd,
  ed,
  handler,
  isEndDateReadOnly = false,
}) => {
  return (
    <div>
      {/* START DATE */}
      <Form.Group controlId="startDate" className="mb-3 ">
        <Form.Label className="text-foreground-dimmer dark:text-foreground-dark-dimmer">
          Start Date
        </Form.Label>
        <InputMask mask="99/99/9999" value={sd} onChange={handler} maskChar="_">
          {(inputProps: any) => (
            <Form.Control
              {...inputProps}
              name="start_date"
              placeholder="MM/DD/YYYY"
              className="form-control w-full bg-background dark:bg-background-dark text-foreground-dimmer dark:text-foreground-dark-dimmer border-background-dimmest dark:border-background-dark-dimmest"
            />
          )}
        </InputMask>
      </Form.Group>

      {/* END DATE */}
      <Form.Group controlId="endDate" className="mb-3">
        <Form.Label className="text-foreground-dimmer dark:text-foreground-dark-dimmer">
          End Date
        </Form.Label>
        <InputMask
          mask="99/99/9999"
          value={ed}
          onChange={handler}
          maskChar="_"
          readOnly={isEndDateReadOnly}
        >
          {(inputProps: any) => (
            <Form.Control
              {...inputProps}
              name="end_date"
              placeholder="MM/DD/YYYY"
              className="form-control w-full bg-background dark:bg-background-dark text-foreground-dimmer dark:text-foreground-dark-dimmer border-background-dimmest dark:border-background-dark-dimmest"
            />
          )}
        </InputMask>
      </Form.Group>
    </div>
  );
};

// // src/components/DateInput.tsx

// import React from "react";
// import FloatingLabel from "react-bootstrap/FloatingLabel";
// import Form from "react-bootstrap/Form";

// interface DateInputProps {
//   sd: string; // Start Date
//   ed?: string; // End Date, optional
//   pos?: boolean; // Position or other flag, optional
//   handler: (
//     e: React.ChangeEvent<
//       HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
//     >,
//   ) => void;
// }

// export const DateInput: React.FC<DateInputProps> = ({
//   sd,
//   ed,
//   pos,
//   handler,
// }) => {
//   return (
//     <div>
//       <FloatingLabel label="Start Date">
//         <Form.Control
//           type="date"
//           name="start_date"
//           value={sd}
//           onChange={handler}
//         />
//       </FloatingLabel>
//       <FloatingLabel label="End Date" className="mt-2">
//         <Form.Control
//           type="date"
//           name="end_date"
//           value={ed || ""}
//           onChange={handler}
//         />
//       </FloatingLabel>
//     </div>
//   );
// };
