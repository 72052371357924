import React from "react";
import Form from "react-bootstrap/Form";

interface FormInputProps {
  name: string;
  label: string;
  type?: string;
  value: string;
  handler: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void;
  required?: boolean;
  placeholder?: string;
  dark?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  type = "text",
  value,
  handler,
  required = false,
  placeholder,
  dark = false,
}) => {
  // Classes to match your dark-mode styling from the design system
  const labelClass = dark
    ? "text-foreground-dimmer dark:text-foreground-dark-dimmer"
    : "";
  const controlClass = dark
    ? "bg-background dark:bg-background-dark text-[#DDDDDD] border-[#383A40]"
    : "";

  return (
    <Form.Group controlId={`formInput-${name}`} className="mb-3">
      <Form.Label className={labelClass}>
        {label}
        {required && <span className="text-[#FF4D4F] ml-1">*</span>}
      </Form.Label>
      <Form.Control
        name={name}
        type={type}
        value={value}
        onChange={handler}
        placeholder={placeholder}
        required={required}
        className={controlClass}
      />
    </Form.Group>
  );
};

export default FormInput;
