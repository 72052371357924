import { FC } from "react";

interface HorizontalProgressProps {
  progress: number;
}

/**
 * @component HorizontalProgress
 * @param {number} props.progress a value between [0, 1] that indicates the progress of this progressbar
 * @returns {ReactElement} a component that renders a horizontal, positive-accented progress bar with the given progress value
 */
const HorizontalProgress: FC<HorizontalProgressProps> = ({ progress }) => {
  return (
    <div className="bg-background-dimmest w-full h-3 flex flex-row items-center justify-start rounded-full overflow-clip">
      <div
        className="h-full bg-accent-positive transition-[width] ease-out duration-100"
        style={{ width: `${progress * 100}%` }}
      ></div>
    </div>
  );
};

export default HorizontalProgress;
