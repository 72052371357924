import {
  forwardRef,
  ForwardRefRenderFunction,
  useState,
  useEffect,
  HTMLAttributes,
} from "react";
import { CourseCardProps } from "./types";
import { Dropdown } from "react-bootstrap";
import CourseStatusChip from "./CourseStatusChip";

/**
 * @component CourseCard
 * @description Renders a card displaying course information and interactive elements
 *
 * @param {Object} props The component props
 * @param {Course} props.course The Course object containing information about the course
 * @param {boolean} props.isDisabled Indicates whether the course card should be displayed in a disabled state
 * @param {boolean} props.isSelected Indicates whether the course is currently selected
 * @param {boolean} props.isHighlighted Indicates whether the course should be visually highlighted
 * @param {boolean} props.isRecommended Indicates whether the course is recommended for the user
 * @param {boolean} props.isLoaded Indicates whether the course data has finished loading
 * @param {boolean} props.showPrerequisiteLabel Indicates whether to display a prerequisite label on the course card
 * @param {boolean} props.needsMorePrerequisites Indicates whether the user needs to complete more prerequisites for this course
 * @param {number} props.progress Number in range [0, 1] representing the user's progress in the course, with 0 being not started and 1 being completed
 * @param {function} props.handleNavigateToCourse Function to handle navigation to the course page
 * @param {function} props.handleCourseSelectedChange Function to handle when a course is selected
 * @param {function} props.handleCourseFocusChange Function to handle when the course card gains or loses focus (hover state)
 * @param {function} props.handleUpdateCourseProgress Function to handle updating the course progress
 * @param {React.Ref<HTMLDivElement>} ref Reference to the course card DOM element
 * @returns {ForwardRefRenderFunction<HTMLDivElement, CourseCardProps>} A React component that renders a course card
 */
const CourseCard: ForwardRefRenderFunction<HTMLDivElement, CourseCardProps> = (
  {
    course,
    isDisabled,
    isSelected,
    isHighlighted,
    isRecommended,
    isLoaded,
    showPrerequisiteLabel,
    needsMorePrerequisites,
    progress,
    handleNavigateToCourse,
    handleCourseSelectedChange,
    handleCourseFocusChange,
    handleUpdateCourseProgress,
  },
  ref,
) => {
  const [leftBarColor, setLeftBarColor] = useState<string>();
  const [renderedProgress, setRenderedProgress] = useState<number>(progress);

  useEffect(() => {
    setRenderedProgress(progress);
  }, [progress]);

  useEffect(() => {
    if (renderedProgress >= 1) setLeftBarColor("bg-accent-primary");
    else if (isRecommended) setLeftBarColor("bg-accent-positive");
    else if (needsMorePrerequisites) setLeftBarColor("bg-accent-negative");
    else setLeftBarColor("bg-foreground-dimmest");
  }, [isRecommended, needsMorePrerequisites, renderedProgress]);

  return (
    <div
      ref={ref}
      tabIndex={0}
      onClick={(e) => {
        e.stopPropagation();
        handleCourseSelectedChange(course);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          e.stopPropagation();
          handleCourseSelectedChange(course);
        }
      }}
      onMouseEnter={() => handleCourseFocusChange(course)}
      onMouseLeave={() => handleCourseFocusChange(null)}
      onFocus={() => handleCourseFocusChange(course)}
      onBlur={() => handleCourseFocusChange(null)}
      className={`cursor-pointer [&>*]:cursor-pointer transition-all w-80 h-32 p-4 min-h-32 max-h-32 flex flex-col items-center justify-center rounded-md bg-background dark:bg-background-dark hover:-translate-y-1 focus:-translate-y-1
        ${
          isHighlighted
            ? "shadow-sm z-50 duration-100"
            : isDisabled
              ? "opacity-40 translate-y-1 duration-100"
              : "duration-300"
        }
            ${
              isSelected
                ? "border-2 border-accent-positive"
                : "border-[1px] border-background-dimmest dark:border-background-dark-dimmest"
            }
        `}
    >
      <div className="flex flex-row gap-4 items-center justify-between w-full relative">
        <div
          onClick={(e) => e.stopPropagation()}
          className="absolute -top-2 -right-2"
        >
          <Dropdown>
            <Dropdown.Toggle as={ForwardedHorizontalEllipses}></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => handleNavigateToCourse(course)}>
                Go To Course
              </Dropdown.Item>
              {isLoaded ? (
                <Dropdown.Item
                  onClick={(e) => {
                    if (renderedProgress < 1) {
                      setRenderedProgress(1);
                      handleUpdateCourseProgress(course, true);
                    } else {
                      setRenderedProgress(0);
                      handleUpdateCourseProgress(course, false);
                    }
                  }}
                >
                  {renderedProgress < 1
                    ? "Mark as Completed"
                    : "Reset Progress"}
                </Dropdown.Item>
              ) : (
                <></>
              )}
              <Dropdown.Item onClick={() => handleCourseSelectedChange(course)}>
                Show Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className={`h-20 w-[0.1875rem] ${leftBarColor} rounded-lg`}></div>
        <div className="flex min-h-16 flex-col gap-2 items-start justify-between w-72">
          <div className="flex flex-col ">
            {showPrerequisiteLabel ? (
              <p className="text-xs text-foreground-dimmest font-light">
                Prerequisite
              </p>
            ) : (
              <></>
            )}

            <p className="text-foreground dark:text-foreground-dark-dimmer font-medium">
              {course.name}
            </p>
          </div>
          <CourseStatusChip
            courseStatusChipProps={
              isLoaded
                ? {
                    isRecommended: isRecommended,
                    needsMorePrerequisites: needsMorePrerequisites,
                    courseProgress: progress,
                  }
                : null
            }
          ></CourseStatusChip>
        </div>
      </div>
    </div>
  );
};

const HorizontalEllipses: ForwardRefRenderFunction<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { onClick: (e: MouseEvent) => void }
> = ({ children, onClick }, ref) => {
  return (
    <div
      ref={ref}
      className=""
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        className="hover:fill-foreground-dimmer fill-foreground-dimmest transition-colors"
      >
        <path d="M8 9.5C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5Z" />
        <path d="M13 9.5C13.8284 9.5 14.5 8.82843 14.5 8C14.5 7.17157 13.8284 6.5 13 6.5C12.1716 6.5 11.5 7.17157 11.5 8C11.5 8.82843 12.1716 9.5 13 9.5Z" />
        <path d="M3 9.5C3.82843 9.5 4.5 8.82843 4.5 8C4.5 7.17157 3.82843 6.5 3 6.5C2.17157 6.5 1.5 7.17157 1.5 8C1.5 8.82843 2.17157 9.5 3 9.5Z" />
      </svg>
    </div>
  );
};
const ForwardedHorizontalEllipses = forwardRef(HorizontalEllipses);

export default forwardRef(CourseCard);
