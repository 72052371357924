import React, { useState, useEffect } from "react";
import { ResumeProps, CreateResProps } from "../interfaces";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Upload } from "../../../components/Upload";
import { File, X } from "lucide-react";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import { Tabs, Tab } from "react-bootstrap";

const ResumeComponent: React.FC<ResumeProps> = ({ resume }) => {
  return (
    <div className="flex flex-col gap-3 text-foreground dark:text-foreground-dark">
      {/* If resume array has items, display them; otherwise, show an empty message. */}
      {resume && resume.length > 0 ? (
        resume.map((res, index) => (
          <div key={index} className="flex flex-row items-start gap-4">
            <div className="flex-shrink-0 w-12 h-12 rounded-xl bg-background-root dark:bg-background-dark-root flex items-center justify-center">
              <File className="w-6 h-6 text-accent-primary" />
            </div>
            <div className="flex-grow">
              <a
                href="https://google.com"
                target="_blank"
                rel="noopener noreferrer"
                className="group"
              >
                <h3 className="text-base font-normal text-foreground dark:text-foreground-dark hover:text-[#A6C8FF] flex items-center gap-1 mt-1 mb-4">
                  {res.title}
                </h3>
                <p className="text-base font-normal text-foreground-dimmest dark:text-foreground-dimmest hover:text-[#A6C8FF] flex items-center gap-1 mt-1 mb-4">
                  Click to view document
                </p>
              </a>
            </div>
          </div>
        ))
      ) : (
        /* Show an empty fallback instead of "No resume on file" */
        <div className="text-base text-foreground-dimmest dark:text-foreground-dimmest"></div>
      )}
    </div>
  );
};

const UploadResume: React.FC<{ userId: string }> = ({ userId }) => {
  return (
    <Form>
      <Stack gap={2}>
        <Upload
          userId={userId}
          rootDirectory="resumes"
          uploadEndpoint="file_upload/"
          dark
        />
      </Stack>
    </Form>
  );
};

const DeleteResume: React.FC<{
  userId: string;
  onDelete?: () => void;
}> = ({ userId, onDelete }) => {
  const [currentResumes, setCurrentResumes] = useState<[string, string][]>([]);
  const [error, setError] = useState<string | null>(null);

  const fetchResumes = async () => {
    try {
      const filepath = `user_career_data/user_id=${userId}/resumes/`;
      const response = await axiosInterceptor.get<{
        private_docs: [string, string][];
      }>(`/serve_private_doc/${filepath}`);
      setCurrentResumes(response.data.private_docs || []);
    } catch (err) {
      console.error("Error fetching resumes:", err);
      setError("Failed to fetch resumes");
    }
  };

  useEffect(() => {
    fetchResumes();
  }, [userId]);

  const handleDelete = async (fileUrl: string) => {
    try {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
      await axiosInterceptor.delete(`/api/delete_resume/${userId}/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRFToken": csrfToken,
        },
        data: { fileUrl },
      });
      // Refresh the list
      await fetchResumes();
      if (onDelete) onDelete();
    } catch (err) {
      console.error("Error deleting resume:", err);
      setError("Failed to delete resume");
    }
  };

  if (error) {
    return <p className="text-[#FF0000] text-sm">{error}</p>;
  }

  return (
    <div className="p-4 text-foreground dark:text-foreground-dark">
      <h5 className="mb-4 text-lg font-medium text-foreground dark:text-foreground-dark">
        Select Resumes to Delete
      </h5>
      {currentResumes.length > 0 ? (
        <div className="space-y-2">
          {currentResumes.map(([url, fileName], index) => (
            <div
              key={index}
              className="flex items-center justify-between p-3 bg-background-root dark:bg-background-dark-root rounded-lg"
            >
              <div className="flex items-center gap-3">
                <File className="w-5 h-5 text-accent-primary" />
                <span className="text-sm font-medium text-foreground-dimmer dark:text-foreground-dark-dimmer">
                  {fileName}
                </span>
              </div>
              <button
                className="p-2 hover:bg-[#333333] rounded-full transition-colors"
                title="Delete resume"
                onClick={() => handleDelete(url)}
              >
                <X className="w-5 h-5 text-[#FF0000]" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-foreground-dimmest dark:text-foreground-dimmest text-sm">
          No resumes to delete
        </p>
      )}
    </div>
  );
};

const ManageResumes: React.FC<{ userId: string; onDelete?: () => void }> = ({
  userId,
  onDelete,
}) => {
  return (
    <Tabs
      defaultActiveKey="upload"
      className="mb-3"
      fill
      style={{ backgroundColor: "#1C1D21", color: "#EEEEEE" }}
    >
      <Tab eventKey="upload" title="Upload Resume">
        <UploadResume userId={userId} />
      </Tab>
      <Tab eventKey="manage" title="Manage Resumes">
        <DeleteResume userId={userId} onDelete={onDelete} />
      </Tab>
    </Tabs>
  );
};

const CreateRes: React.FC<CreateResProps> = ({ resume, userId }) => {
  return (
    <ManageResumes
      userId={userId}
      onDelete={async () => {
        // The parent component (CareerProfile) will handle the refresh
      }}
    />
  );
};

export default ResumeComponent;
export { CreateRes };
