import React, { useState } from "react";
import AbstractDisplacementLandscape2 from "../../assets/img/landing/abstract-displacement-landscape-2.png";

interface FAQItem {
  question: string;
  answer: string;
}

const FAQItemComponent: React.FC<FAQItem> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4 last:mb-0">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full p-6 flex items-center justify-between text-left bg-neutral-50 bg-opacity-0 hover:bg-opacity-10 rounded-2xl transition-all"
        style={{
          borderWidth: "0.5px",
          borderStyle: "solid",
          borderColor: "rgba(255, 255, 255, 0.5)",
        }}
      >
        <span className="text-[#FFFFFF] text-xl font-light">{question}</span>
        <div className="flex items-center justify-center w-6 h-6 ml-4">
          {isOpen ? (
            <svg
              width="16"
              height="2"
              viewBox="0 0 16 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1H15"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          ) : (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1V15M1 8H15"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          )}
        </div>
      </button>

      {isOpen && (
        <div
          className="px-6 py-4 mt-2 bg-neutral-50 bg-opacity-0 rounded-2xl"
          style={{
            borderWidth: "0.5px",
            borderStyle: "solid",
            borderColor: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <p className="text-foreground-dimmest dark:text-foreground-dark-dimmest text-lg font-light">
            {answer}
          </p>
        </div>
      )}
    </div>
  );
};

interface FAQSectionProps {
  limit?: number;
  showHeader?: boolean;
}

const FAQSection: React.FC<FAQSectionProps> = ({
  limit = 20,
  showHeader = true,
}) => {
  const faqs: FAQItem[] = [
    {
      question: "What is AlgoLink, and what makes it unique?",
      answer:
        "AlgoLink connects technical talent with recruiters using advanced algorithms. With features like tailored profiles, skill assessments, curated courses, and a smart job search system, it personalizes your career journey and simplifies hiring for all parties.",
    },
    {
      question: "How does AlgoLink personalize my career journey?",
      answer:
        "AlgoLink uses your profile—including experience, education, and goals—to match you with roles and recruiters aligned with your aspirations, ensuring opportunities tailored to you.",
    },
    {
      question: "What is the purpose of the Test Center?",
      answer:
        "The Test Center evaluates your Behavioral, Background, and Technical skills, providing insights into your strengths and giving recruiters measurable data to assess your fit for roles.",
    },
    {
      question: "How does AlgoLink support continuous professional growth?",
      answer:
        "AlgoLink offers a library of technical courses and career paths to help you expand your skills, stay competitive, and demonstrate your commitment to continuous learning.",
    },
    {
      question: "What makes AlgoLink's job-matching system effective?",
      answer:
        "The Job Finder matches you with opportunities based on your preferences—like role type, industry, and location—ensuring relevant and personalized recommendations.",
    },
    {
      question: "Why is the Messages feature valuable for candidates?",
      answer:
        "Messages enable direct communication with recruiters, helping you get timely updates, role insights, and build strong connections, fostering alignment throughout the process.",
    },
    {
      question: "I want to recruit/refer candidates. Where should I go?",
      answer:
        "Check out our Find Talent page, designed specifically for recruiters and referrers.",
    },
    {
      question: "Can I use AlgoLink as both a recruiter and a job seeker?",
      answer:
        "Yes! Whether you're hiring, referring, or seeking a job, AlgoLink adapts to your needs. Both our consumer and enterprise pages include tools and features to support dual participation.",
    },
  ];

  return (
    <div
      className="w-full relative min-h-[600px] bg-left-top lg:bg-[1500px_auto] bg-no-repeat"
      style={{
        backgroundImage: `url(${AbstractDisplacementLandscape2})`,
      }}
    >
      {/* FAQ Content */}
      <div className="relative z-20 px-4 py-20 max-w-6xl mx-auto">
        <div className="grid grid-cols-12 gap-8">
          {showHeader && (
            <h2 className="col-span-4 text-5xl font-semibold text-[#FFFFFF]">
              Frequently <br />
              Asked Questions
            </h2>
          )}
          <div className="col-span-8">
            {faqs.slice(0, limit).map((faq, i) => (
              <FAQItemComponent key={i} {...faq} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
