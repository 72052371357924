import React, { useState, useEffect } from "react";
import { TranscriptProps, CreateTransProps } from "../interfaces";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Upload } from "../../../components/Upload";
import { File, X } from "lucide-react";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import { Tabs, Tab } from "react-bootstrap";

const TranscriptComponent: React.FC<TranscriptProps> = ({ transcript }) => {
  return (
    <div className="flex flex-col gap-3 text-foreground dark:text-foreground-dark">
      {transcript && transcript.length > 0 ? (
        transcript.map((trans, index) => (
          <div key={index} className="flex flex-row items-start gap-4">
            <div className="flex-shrink-0 w-12 h-12 rounded-xl bg-background-root dark:bg-background-dark-root flex items-center justify-center">
              <File className="w-6 h-6 text-accent-primary" />
            </div>
            <div className="flex-grow">
              <a
                href="https://google.com"
                target="_blank"
                rel="noopener noreferrer"
                className="group"
              >
                <h3 className="text-base font-semibold text-foreground dark:text-foreground-dark group-hover:text-[#A6C8FF]">
                  {trans.course}
                </h3>
                <p className="text-sm text-foreground-dimmest dark:text-foreground-dimmest mt-1 group-hover:text-[#A6C8FF]">
                  Click to view document
                </p>
              </a>
            </div>
          </div>
        ))
      ) : (
        /* Show an empty fallback instead of "No transcript on file" */
        <div className="text-sm text-foreground-dimmest dark:text-foreground-dimmest"></div>
      )}
    </div>
  );
};

const UploadTranscript: React.FC<{ userId: string }> = ({ userId }) => {
  return (
    <Form>
      <Stack gap={2}>
        <Upload
          userId={userId}
          rootDirectory="transcripts"
          uploadEndpoint="file_upload/"
          dark
        />
      </Stack>
    </Form>
  );
};

const DeleteTranscript: React.FC<{
  userId: string;
  onDelete?: () => void;
}> = ({ userId, onDelete }) => {
  const [currentTranscripts, setCurrentTranscripts] = useState<
    [string, string][]
  >([]);
  const [error, setError] = useState<string | null>(null);

  const fetchTranscripts = async () => {
    try {
      const filepath = `user_career_data/user_id=${userId}/transcripts/`;
      const response = await axiosInterceptor.get<{
        private_docs: [string, string][];
      }>(`/serve_private_doc/${filepath}`);
      setCurrentTranscripts(response.data.private_docs || []);
    } catch (err) {
      console.error("Error fetching transcripts:", err);
      setError("Failed to fetch transcripts");
    }
  };

  useEffect(() => {
    fetchTranscripts();
  }, [userId]);

  const handleDelete = async (fileUrl: string) => {
    try {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
      await axiosInterceptor.delete(`/api/delete_transcript/${userId}/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRFToken": csrfToken,
        },
        data: { fileUrl },
      });
      await fetchTranscripts();
      if (onDelete) onDelete();
    } catch (err) {
      console.error("Error deleting transcript:", err);
      setError("Failed to delete transcript");
    }
  };

  if (error) {
    return <p className="text-[#FF0000] text-sm">{error}</p>;
  }

  return (
    <div className="p-4 text-foreground dark:text-foreground-dark">
      <h5 className="mb-4 text-lg font-medium text-foreground dark:text-foreground-dark">
        Select Transcripts to Delete
      </h5>
      {currentTranscripts.length > 0 ? (
        <div className="space-y-2">
          {currentTranscripts.map(([url, fileName], index) => (
            <div
              key={index}
              className="flex items-center justify-between p-3 bg-background-root dark:bg-background-dark-root rounded-lg"
            >
              <div className="flex items-center gap-3">
                <File className="w-5 h-5 text-accent-primary" />
                <span className="text-sm font-medium text-foreground-dimmer dark:text-foreground-dark-dimmer">
                  {fileName}
                </span>
              </div>
              <button
                className="p-2 hover:bg-[#333333] rounded-full transition-colors"
                title="Delete transcript"
                onClick={() => handleDelete(url)}
              >
                <X className="w-5 h-5 text-[#FF0000]" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-foreground-dimmest dark:text-foreground-dimmest text-sm">
          No transcripts to delete
        </p>
      )}
    </div>
  );
};

const ManageTranscripts: React.FC<{
  userId: string;
  onDelete?: () => void;
}> = ({ userId, onDelete }) => {
  return (
    <Tabs
      defaultActiveKey="upload"
      className="mb-3"
      fill
      style={{ backgroundColor: "#1C1D21", color: "#EEEEEE" }}
    >
      <Tab eventKey="upload" title="Upload Transcript">
        <UploadTranscript userId={userId} />
      </Tab>
      <Tab eventKey="manage" title="Manage Transcripts">
        <DeleteTranscript userId={userId} onDelete={onDelete} />
      </Tab>
    </Tabs>
  );
};

const CreateTrans: React.FC<CreateTransProps> = ({ transcript, userId }) => {
  return (
    <ManageTranscripts
      userId={userId}
      onDelete={async () => {
        // The parent component (CareerProfile) will handle the refresh
      }}
    />
  );
};

export default TranscriptComponent;
export { CreateTrans };
