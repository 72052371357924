// src/components/SegmentedControl.tsx
import { FC, ReactElement, useState } from "react";

/**
 * Type definition for a SegmentedControlItem.
 * Either a string or an object with value and icon
 */
export type SegmentedControlItem =
  | { value: string; icon: ReactElement<SVGElement> }
  | string;

/**
 * Interface for SegmentedControl props
 */
export interface SegmentedControlProps {
  defaultValue?: string;
  controlItems: SegmentedControlItem[];
  handleValueSelected: (value: string) => void;
}

/**
 * SegmentedControl component
 */
const SegmentedControl: FC<SegmentedControlProps> = ({
  defaultValue,
  controlItems,
  handleValueSelected,
}) => {
  const [selectedValue, setSelectedValue] = useState<SegmentedControlItem>(
    () => {
      // Find item matching defaultValue, or fallback to first item
      if (defaultValue) {
        return (
          controlItems.find((item) => {
            if (typeof item === "object") return item.value === defaultValue;
            return item === defaultValue;
          }) ?? defaultValue
        );
      } else {
        return controlItems[0];
      }
    },
  );

  return (
    <div className="flex flex-row items-center gap-1 bg-background dark:bg-background-dark-root border-[1px] border-background-dimmest dark:border-background-dark-dimmest rounded-lg h-min p-[0.125rem] w-min">
      {controlItems.map((item, index) => {
        const value = typeof item === "string" ? item : item.value;
        const isSelected =
          (typeof selectedValue === "string" && selectedValue === value) ||
          (typeof selectedValue === "object" && selectedValue.value === value);

        return (
          <button
            key={index}
            onClick={() => {
              handleValueSelected(value);
              setSelectedValue(value);
            }}
            className={`flex flex-row h-7 min-w-[6rem] items-center justify-center gap-2 rounded-md text-sm font-medium p-2 transition-colors
              ${
                isSelected
                  ? "bg-background-dimmer dark:bg-background-dark-dimmer text-foreground dark:text-foreground-dark"
                  : "bg-background dark:bg-background-dark text-foreground-dimmest dark:text-foreground-dimmest hover:bg-background-dimmer dark:hover:bg-background-dark-dimmer"
              }`}
          >
            {typeof item === "object" ? item.icon : null}
            <span>{value}</span>
          </button>
        );
      })}
    </div>
  );
};

export default SegmentedControl;
