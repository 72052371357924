import { FC } from "react";

/**
 * Interface for chip props
 * @interface ChipProps
 * @property {string} message Text to display in the chip
 * @property {boolean} [rounded=true] Whether the chip should have rounded corners
 */
export interface ChipProps {
  message: string;
  rounded?: boolean;
}

/**
 * Chips are small, rectangular boxes of text used to indicate the status of an item or action. These are not clickable and usually contain a short message describing the current state.
 * Chips are available for the Positive, Primary, Negative, and Default styles. They have rounded borders by default
 */

/**
 * DefaultChip component
 * @component
 * @param {Object} props The props for the DefaultChip component
 * @param {string} props.message Text to display in the chip
 * @param {boolean} [props.rounded=true] Whether the chip should have rounded corners
 * @returns {ReactElement} A default styled chip element
 */
export const DefaultChip: FC<ChipProps> = ({ message, rounded = true }) => {
  return (
    <div
      className={`flex items-center justify-center px-3 py-1 w-min bg-background-dimmest dark:bg-background-dimmest/5 dark:border dark:border-background-dark-deepest/30  ${
        rounded ? "rounded-[16rem]" : "rounded-[0.25rem]"
      }`}
    >
      <p
        className={`text-sm font-medium text-nowrap text-foreground-dimmest dark:text-foreground-dimmest-dimmest`}
      >
        {message}
      </p>
    </div>
  );
};

/**
 * PrimaryChip component
 * @component
 * @param {Object} props The props for the PrimaryChip component
 * @param {string} props.message Text to display in the chip
 * @param {boolean} [props.rounded=true] Whether the chip should have rounded corners
 * @returns {ReactElement} A primary styled chip element
 */
export const PrimaryChip: FC<ChipProps> = ({ message, rounded = true }) => {
  return (
    <div
      className={`flex items-center justify-center px-3 py-1 w-min bg-accent-primary-higher dark:border dark:border-accent-primary/30 dark:bg-accent-primary/5 ${
        rounded ? "rounded-[16rem]" : "rounded-[0.25rem]"
      }`}
    >
      <p className={`text-sm font-medium text-nowrap text-accent-primary`}>
        {message}
      </p>
    </div>
  );
};

/**
 * NegativeChip component
 * @component
 * @param {Object} props The props for the NegativeChip component
 * @param {string} props.message Text to display in the chip
 * @param {boolean} [props.rounded=true] Whether the chip should have rounded corners
 * @returns {ReactElement} A negative styled chip element
 */
export const NegativeChip: FC<ChipProps> = ({ message, rounded = true }) => {
  return (
    <div
      className={`flex items-center justify-center px-3 py-1 w-min bg-accent-negative-higher dark:bg-accent-negative/5 dark:border dark:border-accent-negative/30 ${
        rounded ? "rounded-[16rem]" : "rounded-[0.25rem]"
      }`}
    >
      <p className={`text-sm font-medium text-nowrap text-accent-negative`}>
        {message}
      </p>
    </div>
  );
};

/**
 * PositiveChip component
 * @component
 * @param {Object} props The props for the PositiveChip component
 * @param {string} props.message Text to display in the chip
 * @param {boolean} [props.rounded=true] Whether the chip should have rounded corners
 * @returns {ReactElement} A positive styled chip element
 */
export const PositiveChip: FC<ChipProps> = ({ message, rounded = true }) => {
  return (
    <div
      className={`flex items-center justify-center px-3 py-1 w-min bg-accent-positive-higher dark:bg-accent-positive/5 dark:border dark:border-accent-positive/30 ${
        rounded ? "rounded-[16rem]" : "rounded-[0.25rem]"
      }`}
    >
      <p className={`text-sm font-medium text-nowrap text-accent-positive`}>
        {message}
      </p>
    </div>
  );
};
