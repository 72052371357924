import { FC } from "react";
import {
  DefaultChip,
  NegativeChip,
  PrimaryChip,
  PositiveChip,
} from "../../components/Chip";
import { CourseStatusChipProps } from "./types";

/**
 * For the path cards, we only want:
 *   - "Completed"
 *   - "Recommended"
 *   - "Need More Prereqs"
 * Anything else shows no chip.
 */
const CourseStatusChip: FC<CourseStatusChipProps> = ({
  courseStatusChipProps,
}) => {
  if (!courseStatusChipProps) {
    return null; // or return <DefaultChip message="Loading..." />
  }
  const { courseProgress, isRecommended, needsMorePrerequisites } =
    courseStatusChipProps;
  if (courseProgress >= 1) {
    return <PrimaryChip message="Completed" />;
  }
  // Recommended
  else if (isRecommended) {
    return <PositiveChip message="Recommended" />;
  }
  // Needs More Prereqs
  else if (needsMorePrerequisites) {
    return <NegativeChip message="Needs More Prereqs" />;
  }
  // Otherwise, no chip (so there's no confusion with top aggregator)
  return null;
};

export default CourseStatusChip;
