import { FC, useEffect, useState } from "react";
import { DefaultButton } from "./Button";
import { Moon, Sun } from "lucide-react";

const DarkModeSwitch: FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(true);

  useEffect(() => {
    if (darkMode) document.documentElement.classList.add("dark");
    else document.documentElement.classList.remove("dark");
  }, [darkMode]);

  return (
    <div className="fixed right-2 bottom-2">
      <DefaultButton
        message={darkMode ? "switch to light mode" : "switch to dark mode"}
        icon={
          <>
            {darkMode ? (
              <Moon className="stroke-foreground-dark"></Moon>
            ) : (
              <Sun></Sun>
            )}
          </>
        }
        handleClick={() => setDarkMode(!darkMode)}
      ></DefaultButton>
    </div>
  );
};

export default DarkModeSwitch;
