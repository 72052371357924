/**
 * CategoryTabs.tsx
 * Component for job category tabs (Recommended, Interested, etc.)
 * Updated to match Figma design
 */

import React from "react";
import {
  ConsolidatedCategory,
  CONSOLIDATED_CATEGORY_ORDER,
} from "../interfaces";

interface CategoryTabsProps {
  activeCategory: ConsolidatedCategory;
  setActiveCategory: (category: ConsolidatedCategory) => void;
  categoryCounts: Record<ConsolidatedCategory, number>;
}

const CategoryTabs: React.FC<CategoryTabsProps> = ({
  activeCategory,
  setActiveCategory,
  categoryCounts,
}) => {
  // Convert category names to match the design
  const getCategoryDisplayName = (category: ConsolidatedCategory): string => {
    if (category === "Not Interested") return "Not Interested";
    if (category === "Matches") return "Matches";
    return category;
  };

  return (
    <div className="flex w-full border-b border-background-deepest dark:border-background-dark-deepest">
      {CONSOLIDATED_CATEGORY_ORDER.map((category) => {
        const isActive = activeCategory === category;
        const count = categoryCounts[category] || 0;

        return (
          <button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={`relative flex-1 whitespace-nowrap px-4 py-3 text-sm font-medium transition-colors flex items-center justify-center
              ${isActive ? "text-foreground dark:text-foreground-dark" : "text-foreground-dimmest dark:text-foreground-dark-dimmest hover:text-foreground-dimmest hover:dark:text-foreground-dark-dimmest"}`}
            style={{
              borderBottom: isActive ? "2px solid #0032DF" : "none",
            }}
          >
            <span className="flex items-center gap-2">
              {getCategoryDisplayName(category)}
              {count > 0 && (
                <span
                  className={`inline-flex items-center justify-center h-6 w-6 rounded-full text-xs
                    ${
                      isActive
                        ? "bg-background dark:bg-background-dark text-accent-primary font-bold"
                        : "bg-background dark:bg-background-dark text-foreground-dimmest dark:text-foreground-dark-dimmest font-bold"
                    }`}
                >
                  {count}
                </span>
              )}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default CategoryTabs;
