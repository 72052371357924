import { ReactElement } from "react";

// In icons.ts, let's first define a type for our course categories
export type CourseCategory =
  | "Theoretical Computer Science"
  | "Computer Engineering"
  | "Computational Biology"
  | "Probability & Statistics"
  | "Artificial Intelligence"
  | "Computer Science"
  | "Optimization"
  | "Mathematical Finance"
  | "Foundational Math"
  | "Interview Preparation";

export enum LectureContentType {
  PDF = "pdf",
  VIDEO = "video",
  UNSUPPORTED = "unsupported",
}

export interface Course {
  id: string;
  name: string;
  courseNumber: string;
  courseType: string;
  /**
   * a list of courseNumbers
   */
  prerequisites?: string[];
}

export interface CourseProgress {
  courseNumber: string;
  progress: number;
}

export interface SavedCourseTypesResponse {
  saved_course_types: string[];
}

export interface EvaluatePrerequisiteResponse {
  /**
   * a list containing the course numbers of recommended courses
   */
  recommendations: string[];
}

export interface CourseMapProps {
  courses: Course[];
  recommendations: Course[];
  isRecommendationDataLoaded: boolean;
  selectedCourseTypes: Record<string, boolean>;
  coursesProgress: CourseProgress[];
  selectedCourse: Course | null;
  focusedCourse: Course | null;
  handleNavigateToCourse: (course: Course) => void;
  handleCourseFocusChange: (course: Course | null) => void;
  handleCourseSelectedChange: (course: Course | null) => void;
  handleUpdateCourseProgress: (course: Course, completed: boolean) => void;
}

export interface CoursePathsProps {
  courses: Course[];
  isProgressDataLoaded: boolean;
  coursesProgress: CourseProgress[];
  selectedCourseTypes: Record<CourseCategory, boolean>; // Update this
  handleNavigateToCourse: (course: Course) => void;
  handleUpdateCourseProgress: (course: Course, completed: boolean) => void;
}

export interface CourseColumnProps {
  enabledCourseCardProps: CourseCardProps[];
  disabledCourseCardProps: CourseCardProps[];
  allCourseCardProps: CourseCardProps[];
  overlayHighlighted: boolean;
}

export interface CourseContent {
  id: string;
  title: string;
  author: string;
  term: string;
  contentGroups: CourseContentGroup[];
}

export type CourseContentType = string;

export interface CourseContentGroup {
  contentType: CourseContentType;
  contentList: string[];
}

/**
 * Interface for a component that renders a course status chip component
 * @interface CourseStatusChipProps
 * @property {LoadedCourseStatusChipProps | null} courseStatusChipProps the props for a course status chip, or null if you want the chip to display as loading
 */
export interface CourseStatusChipProps {
  courseStatusChipProps: LoadedCourseStatusChipProps | null;
}

/**
 * Interface for a loaded course status chip
 * @interface LoadedCourseStatusChipProps
 * @param {number} courseProgress the progress for a given course
 * @param {boolean} needsMorePrerequisites whether a given course needs more prerequisites
 * @param {boolean} isRecommended whether the user is recommended to take a given course
 */
export interface LoadedCourseStatusChipProps {
  courseProgress: number;
  needsMorePrerequisites: boolean;
  isRecommended: boolean;
}

/**
 * Interface for a course category card
 * @interface CourseCategoryCardProps
 * @param {string} categoryName The name of this course category
 * @param {Course[]} courses The courses that belong in this course category
 * @param {Record<string, number>} coursesProgress A map that maps course numbers to a progress value in [0, 1]
 */

// In CourseCategoryCard.tsx, update the props interface to use the CourseCategory type
export interface CourseCategoryCardProps {
  categoryName: CourseCategory; // Update this line
  courses: Course[];
  coursesProgress: Record<string, number>;
  isLoaded: boolean;
  handleUpdateCourseProgress: (course: Course, completed: boolean) => void;
  handleNavigateToCourse: (course: Course) => void;
}

/**
 * Interface for course card props
 * @interface CourseCardProps
 * @property {Course} course The Course object containing information about the course
 * @property {boolean} isDisabled Indicates whether the course card should be displayed in a disabled state
 * @property {boolean} isSelected Indicates whether the course is currently selected
 * @property {boolean} isHighlighted Indicates whether the course should be visually highlighted
 * @property {boolean} isRecommended Indicates whether the course is recommended for the user
 * @property {boolean} isLoaded Indicates whether the course data has finished loading
 * @property {boolean} showPrerequisiteLabel Indicates whether to display a prerequisite label on the course card
 * @property {boolean} needsMorePrerequisites Indicates whether the user needs to complete more prerequisites for this course
 * @property {number} progress Number in range [0, 1] representing the user's progress in the course
 * @property {function} handleNavigateToCourse Function to handle navigation to the course page
 * @property {function} handleCourseFocusChange Function to handle when the course card gains or loses focus
 * @property {function} handleCourseSelectedChange Function to handle when a course is selected
 * @property {function} handleUpdateCourseProgress Function to handle updating the course progress
 */
export interface CourseCardProps {
  course: Course;
  isDisabled: boolean;
  isSelected: boolean;
  isHighlighted: boolean;
  isRecommended: boolean;
  isLoaded: boolean;
  showPrerequisiteLabel: boolean;
  needsMorePrerequisites: boolean;
  progress: number;
  handleNavigateToCourse: (course: Course) => void;
  handleCourseFocusChange: (course: Course | null) => void;
  handleCourseSelectedChange: (course: Course | null) => void;
  handleUpdateCourseProgress: (course: Course, completed: boolean) => void;
}
/**
 *
 * Interface for selected course drawer props
 * @interface SelectedCourseDrawerProps
 * @property {boolean} showDrawer Indicates whether the drawer should be displayed
 * @property {Course} selectedCourse The Course object containing information about the selected course
 * @property {Course[]} selectedCoursePrerequisites Array of Course objects representing the prerequisites for the selected course
 * @property {string} selectedCourseCitation Citations for the selected course
 * @property {ReactElement<HTMLElement>} lecturePreview React element representing a preview of the course lectures
 * @property {LecturesProgress[]} lecturesProgress Array of LecturesProgress objects representing the progress for each lecture
 * @property {function} handleHide Function to handle hiding the drawer, takes the selected course as an argument
 * @property {function} handleNavigateToCourse Function to handle navigation to the course page
 */
export interface SelectedCourseDrawerProps extends CourseStatusChipProps {
  showDrawer: boolean;
  selectedCourse: Course;
  selectedCoursePrerequisites: Course[];
  selectedCourseCitation: string;
  lecturePreview: ReactElement<HTMLElement>;
  lecturesProgress: LecturesProgress[];
  handleHide: (selectedCourse: Course) => void;
  handleNavigateToCourse: (course: Course) => void;
}

export interface ProgressChipsProps {
  courses: Course[];
  coursesProgress: Record<string, number>;
  isLoaded: boolean;
  minified?: boolean;
}

export interface LecturesProgress {
  lectureType: string;
  progress: number;
  totalLectures: number;
}

export interface PrerequisiteConnection {
  course: Course;
  prerequisite: Course;
}

export interface LectureContentTabs {
  name: string;
  description: string[]; // Ensured to be an array of strings
}

export interface LectureContent {
  courseId: string;
  type: LectureContentType;
  mainFilesList: string[];
  tabs: LectureContentTabs[];
}

export interface PathCourse {
  courseid: string; // always a string for direct matching
}

export interface NewCourseContent {
  id: string;
  title: string;
  lectureContent: CourseContentGroup;
  lectureFilePath: string;
  type: LectureContentType;
}
