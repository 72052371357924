import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../../assets/img/logos/white-logo-with-text.svg";
import {
  LandingButtonHollow,
  LandingButtonInvisible,
  LandingButtonPrimary,
  ScrollLink,
} from "../../components/LandingComponents";

interface TopNavigationProps {
  showCompleteNavigation?: boolean;
}

const TopNavigation: FC<TopNavigationProps> = ({
  showCompleteNavigation = true,
}) => {
  const navigate = useNavigate();
  return (
    <nav className="top-0 z-50 w-full center py-8 flex items-center justify-center fixed ">
      <div className="flex items-center justify-between max-w-[1400px] w-full">
        {/* Logo */}
        <Link
          to="/"
          className="flex flex-1 justify-start items-start no-underline"
        >
          <LogoIcon className="h-12 w-48"></LogoIcon>
        </Link>

        {/* middle navigation */}
        {showCompleteNavigation ? (
          <div className="flex items-center justify-center gap-1 p-1 bg-neutral-50 bg-opacity-10 backdrop-filter backdrop-blur-sm rounded-md ">
            <ScrollLink href="#who-we-are">
              <LandingButtonInvisible message="Who We Are"></LandingButtonInvisible>
            </ScrollLink>
            <ScrollLink href="#getting-started">
              <LandingButtonInvisible message="Getting Started"></LandingButtonInvisible>
            </ScrollLink>
            <ScrollLink href="#testimonials">
              <LandingButtonInvisible message="Testimonials"></LandingButtonInvisible>
            </ScrollLink>
            <ScrollLink href="#faqs">
              <LandingButtonInvisible message="FAQs"></LandingButtonInvisible>
            </ScrollLink>

            <Link to="https://enterprise.algolink.net">
              <LandingButtonHollow
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.4988 9.05046C11.764 9.05046 12.0184 8.94511 12.2059 8.75757C12.3934 8.57003 12.4988 8.31568 12.4988 8.05046C12.4988 7.78525 12.3934 7.53089 12.2059 7.34336C12.0184 7.15582 11.764 7.05046 11.4988 7.05046H8.99881V5.25746C8.99881 3.92046 7.3828 3.25046 6.4388 4.19746L1.6428 8.98846C1.50342 9.12776 1.39284 9.29317 1.3174 9.47522C1.24196 9.65727 1.20313 9.8524 1.20313 10.0495C1.20312 10.2465 1.24196 10.4417 1.3174 10.6237C1.39284 10.8058 1.50342 10.9712 1.6428 11.1105L6.4368 15.9035C7.3818 16.8485 8.99681 16.1785 8.99681 14.8425V13.0505H15.9988C16.264 13.0505 16.5184 12.9451 16.7059 12.7576C16.8934 12.57 16.9988 12.3157 16.9988 12.0505V10.4635L20.5848 14.0505L16.9988 17.6365V16.0505C16.9988 15.7852 16.8934 15.5309 16.7059 15.3434C16.5184 15.1558 16.264 15.0505 15.9988 15.0505H12.4988C12.2336 15.0505 11.9792 15.1558 11.7917 15.3434C11.6042 15.5309 11.4988 15.7852 11.4988 16.0505C11.4988 16.3157 11.6042 16.57 11.7917 16.7576C11.9792 16.9451 12.2336 17.0505 12.4988 17.0505H14.9988V18.8435C14.9988 20.1795 16.6138 20.8485 17.5588 19.9035L22.3528 15.1105C22.634 14.8292 22.792 14.4477 22.792 14.05C22.792 13.6522 22.634 13.2708 22.3528 12.9895L17.5588 8.19646C16.6138 7.25046 14.9988 7.92046 14.9988 9.25646V11.0505H7.99881C7.73359 11.0505 7.47923 11.1558 7.2917 11.3434C7.10416 11.5309 6.99881 11.7852 6.99881 12.0505V13.6365L3.4118 10.0505L6.99881 6.46446V8.05046C6.99881 8.31568 7.10416 8.57003 7.2917 8.75757C7.47923 8.94511 7.73359 9.05046 7.99881 9.05046H11.4988Z"
                      fill="white"
                    />
                  </svg>
                }
                message="Find Talent"
              ></LandingButtonHollow>
            </Link>
          </div>
        ) : (
          <></>
        )}

        {/* Authentication Buttons */}
        <div className="flex items-center gap-2 p-1 flex-1 justify-end">
          <LandingButtonHollow
            message="Log In"
            handleClick={() => navigate("/account/login")}
          ></LandingButtonHollow>
          <LandingButtonPrimary
            message="Sign Up"
            handleClick={() => navigate("/account/signup")}
          ></LandingButtonPrimary>
        </div>
      </div>
    </nav>
  );
};

export default TopNavigation;
