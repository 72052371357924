/**
 * JobDetail.tsx
 * Component for the right column detailed job view
 * Updated to match Figma design - using only real API data
 */

import React, { useState } from "react";
import { ConsolidatedJob } from "../interfaces";
import JobRequirementsDisplay, {
  dummyJobRequirements,
} from "./JobRequirementsDisplay";
import RatingDropdown from "./RatingDropdown";

interface JobDetailProps {
  job: ConsolidatedJob;
  onRateJob: (jobId: number, rating: number) => Promise<void>;
}

const JobDetail: React.FC<JobDetailProps> = ({ job, onRateJob }) => {
  const [expandedDescription, setExpandedDescription] =
    useState<boolean>(false);

  // Determine if rating should be locked (for Matches and Referral categories)
  const isRatingLocked = ["Matches", "Referral"].includes(
    job.consolidatedCategory,
  );

  // Format description for display
  const maxLen = 300;
  const fullDesc = job.description || "";
  const shortDesc =
    fullDesc.length > maxLen ? `${fullDesc.substring(0, maxLen)}...` : fullDesc;
  const displayDesc = expandedDescription ? fullDesc : shortDesc;

  // Get company details
  const companyName = job.company_details?.company_name || "AlgoLink";
  const companyDescription =
    job.company_details?.company_description ||
    "No company description available.";

  return (
    <div className="p-6 bg-background dark:bg-background-dark text-foreground dark:text-foreground-dark h-full overflow-auto">
      {/* Company header */}
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-semibold text-foreground dark:text-foreground-dark">
          {companyName}
        </h2>

        <RatingDropdown
          jobId={job.id}
          currentRating={job.user_rating_of_job || 0}
          onRateJob={onRateJob}
          disabled={isRatingLocked}
        />
      </div>

      {/* Job title */}
      <h1 className="text-2xl font-bold mb-3">{job.title}</h1>

      {/* Job details */}
      <div className="flex flex-wrap gap-2 items-center mb-6 text-base">
        <span className="text-foreground-dimmest dark:text-foreground-dark-dimmest">
          {job.city || "Remote"}
        </span>
        <span className="text-foreground-dimmest dark:text-foreground-dark-dimmest">
          •
        </span>
        <span className="text-foreground-dimmest dark:text-foreground-dark-dimmest">
          ${Math.round(job.compensationLowerBound / 1000)}k - $
          {Math.round(job.compensationUpperBound / 1000)}k
        </span>
        {job.eligible_for_visa_sponsorship && (
          <>
            <span className="text-foreground-dimmest dark:text-foreground-dark-dimmest">
              •
            </span>
            <span className="text-foreground-dimmest dark:text-foreground-dark-dimmest">
              Visa sponsorship
            </span>
          </>
        )}
      </div>

      {/* Background section */}
      <div className="mb-6">
        <h3 className="text-base font-semibold mb-2">Background:</h3>
        <JobRequirementsDisplay requirements={dummyJobRequirements} />
      </div>

      {/* Separator */}
      <div className="h-px bg-background-deepest dark:bg-background-dark-deepest my-6"></div>

      {/* About Us Section */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-4 ">About Us</h2>
        <div className="whitespace-pre-line text-sm leading-relaxed text-foreground-dimmer dark:text-foreground-dark-dimmer">
          {companyDescription}
        </div>
      </div>

      {/* Job Description */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-4">Job Description</h2>
        <div className="whitespace-pre-line text-sm leading-relaxed text-foreground-dimmer dark:text-foreground-dark-dimmer">
          {displayDesc}
          {fullDesc.length > maxLen && (
            <button
              onClick={() => setExpandedDescription(!expandedDescription)}
              className="ml-2 text-[#3175E3] hover:text-[#0032DF]"
            >
              {expandedDescription ? "View less" : "More"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobDetail;
