// src/routes/profile/career/Experience.tsx
import React, { useState, ChangeEvent, FormEvent } from "react";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Row } from "react-bootstrap";
import Modal from "../../../components/Modal";
import FormInput from "../../../components/FormInput";
import { SimpleDateInput } from "../../../components/DateInput";
import { ExperienceInfo, CreateExpProps } from "../interfaces";
import { formatForDisplay, parseFromDisplay } from "../../../utils/dateUtils";
import {
  DefaultButton,
  NegativeButton,
  PrimaryButton,
} from "../../../components/Button";
import { DefaultTitleModel } from "survey-core";

interface ExperienceProps {
  expList: ExperienceInfo[];
}

/** Read-only display of experiences */
const ExperienceComponent: React.FC<ExperienceProps> = ({ expList }) => {
  function ExpItem({ info }: { info: ExperienceInfo }): JSX.Element {
    const {
      position,
      company,
      employ_type,
      start_date,
      end_date,
      description,
    } = info;
    const startYear = new Date(start_date).getFullYear();
    const endYear = end_date ? new Date(end_date).getFullYear() : "Present";
    const dateRange = `${startYear} – ${endYear}`;

    return (
      <div className="ml-5 flex flex-row items-start gap-4 mb-6 last:mb-0">
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-base font-medium text-foreground-dimmer dark:text-foreground-dark-dimmer">
                {position}
              </h3>
              <p className="text-sm font-normal text-foreground-dimmest dark:text-foreground-dark-dimmest">
                {company} <span className="mx-1 text-[#333333]">•</span>{" "}
                {employ_type}
              </p>
              <div className="flex items-center gap-4 mt-1">
                <p className="text-sm text-foreground-dimmest dark:text-foreground-dark-dimmest">
                  {dateRange}
                </p>
              </div>
              {description && (
                <p className="text-sm text-foreground-dimmest dark:text-foreground-dark-dimmest mt-2">
                  {description}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const sortedExpList = [...expList].sort(
    (a, b) =>
      new Date(b.start_date).getTime() - new Date(a.start_date).getTime(),
  );

  return (
    <div>
      {sortedExpList.map((info) => (
        <ExpItem key={info.id} info={info} />
      ))}
    </div>
  );
};

export default ExperienceComponent;

/** 2) CreateExp for adding/editing experiences */
export const CreateExp: React.FC<CreateExpProps> = ({
  expList,
  setExperiences,
}) => {
  const BLANK_DATA: ExperienceInfo = {
    relevance: -1,
    position: "",
    company: "",
    employ_type: "Internship",
    start_date: "",
    end_date: null, // null => "current position"
    description: "",
  };

  // Local state for experiences
  const [experiences, setExp] = useState<ExperienceInfo[]>(
    expList.length ? expList : [BLANK_DATA],
  );

  // For warnings/error messages
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");

  function handleShowModal(message: string) {
    setModalMessage(message);
    setShowModal(true);
  }
  function handleCloseModal() {
    setShowModal(false);
  }

  /**
   * We'll store the typed date in "YYYY-MM-DD".
   * If the user toggles "Current Position," we set end_date to null
   * but keep the old date in a hidden property, so we can restore it.
   */
  function updateExperienceItem(
    index: number,
    field: keyof ExperienceInfo,
    rawValue: string | null,
  ) {
    setExp((prev) => {
      const updatedList = [...prev];
      const current = { ...updatedList[index] } as any;

      switch (field) {
        case "start_date":
          current.start_date = rawValue ?? "";
          break;

        case "end_date":
          if (rawValue === null) {
            // user wants "current position"
            // store old date in a hidden property so we can restore if unchecked
            current._prevEndDate = current.end_date;
            current.end_date = null;
          } else {
            // user unchecks or modifies the end date
            // if we previously stored a date in _prevEndDate, let's restore it
            // only if rawValue is "" meaning user toggled from current => not current
            if (rawValue === "" && current._prevEndDate) {
              current.end_date = current._prevEndDate;
              delete current._prevEndDate;
            } else {
              // otherwise, just set the new date
              current.end_date = rawValue;
              delete current._prevEndDate; // no longer needed
            }
          }
          break;

        case "position":
          current.position = rawValue ?? "";
          break;

        case "company":
          current.company = rawValue ?? "";
          break;

        case "employ_type":
          current.employ_type = (rawValue ??
            "Internship") as ExperienceInfo["employ_type"];
          break;

        case "description":
          current.description = rawValue ?? "";
          break;

        default:
          // relevance or unknown
          break;
      }

      updatedList[index] = current;
      return updatedList;
    });
    // Also update the parent's state
    setExperiences(experiences);
  }

  /**
   * For text/select/textarea changes, parse date fields from "MM/DD/YYYY" => "YYYY-MM-DD".
   */
  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    index: number,
  ) {
    const field = e.target.name as keyof ExperienceInfo;
    const displayVal = e.target.value;
    if (field === "start_date" || field === "end_date") {
      const dbVal = parseFromDisplay(displayVal);
      // If parseFromDisplay is "" => user cleared => store null?
      const finalVal = dbVal === "" ? null : dbVal;
      updateExperienceItem(index, field, finalVal);
    } else {
      updateExperienceItem(index, field, displayVal);
    }
  }

  /** Toggle "Current Position" */
  function handleToggleCurrentPosition(
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) {
    const isChecked = e.target.checked;
    if (isChecked) {
      // user wants current => set end_date = null
      updateExperienceItem(index, "end_date", null);
    } else {
      // user unchecks => end_date = "" => restore old date if any
      updateExperienceItem(index, "end_date", "");
    }
  }

  /** Add a new blank row */
  const addNewEntry = () => {
    setExp((prev) => [...prev, { ...BLANK_DATA }]);
    setExperiences([...experiences, { ...BLANK_DATA }]);
  };

  /** Remove an item from local & DB if it has an id */
  const handleRemove = async (itemToRemove: ExperienceInfo) => {
    if (itemToRemove.id) {
      try {
        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
        await axiosInterceptor.delete(`/delete_exp_entry/${itemToRemove.id}/`, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        });
      } catch (error) {
        console.error("Error deleting experience:", error);
      }
    }
    const updatedList = experiences.filter((it) => it !== itemToRemove);
    setExp(updatedList);
    setExperiences(updatedList);
  };

  /** Validate required fields */
  const validateFields = (): boolean => {
    for (const exp of experiences) {
      if (!exp.position.trim()) {
        handleShowModal("The field 'Position' is required.");
        return false;
      }
      if (!exp.company.trim()) {
        handleShowModal("The field 'Company' is required.");
        return false;
      }
      if (!exp.employ_type.trim()) {
        handleShowModal("The field 'Employment Type' is required.");
        return false;
      }
      if (!exp.start_date.trim()) {
        handleShowModal("The field 'Start Date' is required.");
        return false;
      }
      if (!exp.description.trim()) {
        handleShowModal("The field 'Description' is required.");
        return false;
      }
    }
    return true;
  };

  /** On submit => patch or post => reload */
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!validateFields()) return;

    try {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
      const user_id = localStorage.getItem("user_id") || "";
      for (const exp of experiences) {
        if (exp.id) {
          await axiosInterceptor.patch(`/update_exp/${exp.id}/`, exp, {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
          });
        } else {
          await axiosInterceptor.post(
            `/create_exp/${user_id}/`,
            { ...exp, user_id },
            {
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
              },
            },
          );
        }
      }
      // If success => reload
      window.location.reload();
    } catch (error) {
      console.error("Error submitting experiences:", error);
      handleShowModal("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="text-[#DDDDDD] ">
      {/* Error modal */}
      <Modal show={showModal} setShow={setShowModal} title="Warning" dark>
        <p>{modalMessage}</p>
        <DefaultButton
          message="Close"
          handleClick={() => handleCloseModal()}
        ></DefaultButton>
      </Modal>

      {/* Main form */}
      <Form
        onSubmit={handleSubmit}
        className="p-3 bg-background-root dark:bg-background-dark-root rounded-xl"
      >
        <Stack gap={3}>
          {experiences.map((item, index) => {
            const isCurrent = item.end_date === null;
            // Convert to "MM/DD/YYYY" for display
            const displayStart = formatForDisplay(item.start_date);
            // If end_date is null => show empty => readOnly
            const displayEnd = isCurrent
              ? ""
              : formatForDisplay(item.end_date ?? "");

            return (
              <div key={item.id || index} className="mb-2 p-3">
                <Stack gap={2}>
                  <FormInput
                    name="position"
                    label="Position"
                    type="text"
                    value={item.position}
                    handler={(e) => handleChange(e, index)}
                    required
                    dark
                  />

                  <Form.Group controlId={`formDropdown${index}`}>
                    <Form.Label className="text-foreground dark:text-foreground-dark m-0">
                      Employment Type
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="employ_type"
                      value={item.employ_type}
                      onChange={(e) => handleChange(e, index)}
                      required
                      style={{
                        color: "#DDDDDD",
                        border: "1px solid rgba(56,58,64,1)", // #383A40
                      }}
                    >
                      <option value="Internship">Internship</option>
                      <option value="New Grad">New Graduate</option>
                      <option value="Full Time">Full Time</option>
                      <option value="Part Time">Part Time</option>
                    </Form.Control>
                  </Form.Group>

                  <FormInput
                    name="company"
                    label="Company Name"
                    type="text"
                    value={item.company}
                    handler={(e) => handleChange(e, index)}
                    required
                    dark
                  />

                  {/* Start/End date fields, in "MM/DD/YYYY" format */}
                  <SimpleDateInput
                    sd={displayStart}
                    ed={displayEnd}
                    handler={(e) => handleChange(e, index)}
                    isEndDateReadOnly={isCurrent}
                  />

                  {/* Toggle "Current Position" */}
                  <Form.Check
                    type="checkbox"
                    label="Current Position"
                    checked={isCurrent}
                    onChange={(e) => handleToggleCurrentPosition(e, index)}
                    className="mt-2 text-foreground dark:text-foreground-dark"
                  />

                  <Form.Label className="text-foreground dark:text-foreground-dark m-0">
                    Description
                  </Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    onChange={(e) => handleChange(e, index)}
                    value={item.description}
                    required
                    style={{
                      color: "#DDDDDD",
                      border: "1px solid rgba(56,58,64,1)",
                    }}
                  />

                  <Container style={{ textAlign: "right" }}>
                    <NegativeButton
                      message="Remove"
                      handleClick={() => handleRemove(item)}
                    ></NegativeButton>
                  </Container>
                </Stack>
              </div>
            );
          })}
        </Stack>
      </Form>

      {/* Add + Done buttons */}
      <Container className="d-flex justify-content-center align-items-center mt-3">
        <DefaultButton
          message="Add New"
          handleClick={() => addNewEntry()}
        ></DefaultButton>
        <PrimaryButton
          message="Save"
          handleClick={(e) => handleSubmit(e)}
        ></PrimaryButton>
      </Container>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-background-root dark:bg-background-dark-root p-6 rounded-lg text-[#DDDDDD]">
            <h4 className="mb-4 text-foreground-dimmer dark:text-foreground-dark-dimmer">
              Warning
            </h4>
            <p className="mb-4">{modalMessage}</p>
            <Button
              variant="secondary"
              onClick={handleCloseModal}
              style={{
                backgroundColor: "#333333",
                borderColor: "#333333",
                color: "#DDDDDD",
              }}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
