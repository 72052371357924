import axiosInterceptor from "./axiosInterceptor";

async function sendVerificationCode(email: string) {
  try {
    const response = await axiosInterceptor.post("/send_verification_code/", {
      email: email,
      tp: "consumer",
    });
    console.log(response);
  } catch (error) {
    console.log(error);
  }
}

export default sendVerificationCode;
