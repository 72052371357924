import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GripVertical } from "lucide-react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import HorizontalSelection from "../../../components/HorizontalSelection";
import { PrimaryButton, DefaultButton } from "../../../components/Button";
import CameraRecorder, { CameraRecorderHandle } from "./CameraRecorder";
import CodingBox from "./CodingBox";
// (Assume Timer and other imported components have been updated similarly to dark mode)

interface Question {
  id: string;
  title: string;
  question: string;
  solution: string;
  hint: string;
  answer: string;
  difficulty: number;
  type: "coding" | "other";
}

interface AssessmentState {
  questionMachine: any;
  answers: Record<string, string>;
  abilityVector: number[] | null;
}

interface TestCase {
  input: number[];
}

const Technical: React.FC = () => {
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const navigate = useNavigate();
  const cameraRecorderRef = useRef<CameraRecorderHandle>(null);
  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  const [assessmentState, setAssessmentState] = useState<AssessmentState>({
    questionMachine: null,
    answers: {},
    abilityVector: null,
  });
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null);
  const [totalQuestions] = useState<number>(5);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [userAnswer, setUserAnswer] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [assessmentStarted, setAssessmentStarted] = useState<boolean>(false);
  const [testCases, setTestCases] = useState<TestCase[]>([]);
  const [currentTestCase, setCurrentTestCase] = useState<number>(0);
  const [code, setCode] = useState<string>("");
  const [subject, setSubject] = useState<string>("Quant");
  const [startTime, setStartTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    const initializeAssessment = async () => {
      try {
        const subjectFromLocation = location.state?.subject || "Quant";
        setSubject(subjectFromLocation);
        await axiosInterceptor.post("/start_assessment/", {
          user_id: userId,
          subject: subjectFromLocation,
        });
        setStartTime(Date.now());
        setAssessmentStarted(true);
        await fetchQuestion();
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : "Failed to initialize assessment",
        );
      } finally {
        setLoading(false);
      }
    };

    initializeAssessment();
  }, []);

  const fetchQuestion = async () => {
    try {
      const response = await axiosInterceptor.post("/retrieve_question/", {
        user_id: userId,
      });
      const questionKey = Object.keys(response.data.questions)[0];
      const backendQuestion = response.data.questions[questionKey];

      setCurrentQuestion({
        id: questionKey,
        title: backendQuestion.title,
        question: backendQuestion.question,
        solution: backendQuestion.solution,
        hint: backendQuestion.hint,
        answer: backendQuestion.answer,
        difficulty: backendQuestion.difficulty,
        type: subject === "leetcode" ? "coding" : "other",
      });
    } catch (err) {
      setError("Failed to load question");
    }
  };

  const exitPage = async () => {
    try {
      if (cameraRecorderRef.current) {
        await cameraRecorderRef.current.stopAndDownload();
      }
      await endAssessment();
      navigate("/test-center/home");
    } catch (err) {
      console.error("Exit error:", err);
      setError("Failed to exit properly");
    }
  };

  const handleExitClick = () => setShowExitModal(true);
  const handleCloseExitModal = () => setShowExitModal(false);
  const handleConfirmExit = async () => {
    handleCloseExitModal();
    await exitPage();
  };

  const handleAnswerSubmission = async () => {
    if (!currentQuestion) return;
    try {
      const answerToSubmit =
        currentQuestion.type === "coding" ? code : userAnswer;
      await axiosInterceptor.post("/answer_question/", {
        user_id: userId,
        question_id: currentQuestion.id,
        answer: answerToSubmit,
      });
      if (cameraRecorderRef.current) {
        await cameraRecorderRef.current.stopAndDownload();
      }
      if (currentQuestionIndex < totalQuestions - 1) {
        await fetchQuestion();
        setCurrentQuestionIndex((prev) => prev + 1);
        setCode("");
        setUserAnswer("");
      } else {
        await endAssessment();
        navigate("/test-center/home");
      }
    } catch (err) {
      setError("Failed to process answer");
      console.error("Submission error:", err);
    }
  };

  const endAssessment = async () => {
    const durationSeconds = Math.floor((Date.now() - startTime) / 1000);
    setDuration(durationSeconds);
    try {
      await axiosInterceptor.post("/end_assessment/", {
        user_id: userId,
        duration: durationSeconds,
      });
    } catch (err) {
      console.error("Assessment end error:", err);
    }
  };

  const handleTimeUp = () => exitPage();

  const renderQuestion = () => {
    if (!currentQuestion) return null;
    if (currentQuestion.type === "coding") {
      return (
        <div className="space-y-4">
          <p className="text-foreground dark:text-foreground-dark">
            {currentQuestion.question}
          </p>
          {/* You can uncomment and darken example boxes if needed */}
        </div>
      );
    } else if (currentQuestion.type === "other") {
      return (
        <div className="space-y-4">
          <p className="text-foreground dark:text-foreground-dark">
            {currentQuestion.question}
          </p>
          {/* Render optional images if present */}
        </div>
      );
    }
  };

  return (
    <div className="min-h-screen bg-background-root dark:bg-background-dark-root text-foreground dark:text-foreground-dark">
      <div className="max-w-7xl mx-auto px-8 pt-8 pb-16">
        {/* Title Section with Progress */}
        <div className="mb-8">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h1 className="text-2xl font-semibold">Test Center</h1>
              <p className="text-sm text-foreground-dimmest dark:text-foreground-dimmest">
                Practice for your interviews!
              </p>
            </div>
            <div
              className="relative rounded-full"
              style={{ width: "150px", height: "150px" }}
            >
              <CameraRecorder
                isTechnical={true}
                rounded
                moreClasses="w-full h-full"
                ref={cameraRecorderRef}
                ids={[currentQuestionIndex + 1]}
                type="Technical"
              />
            </div>
            <div className="flex gap-4">
              <button
                onClick={handleAnswerSubmission}
                className="px-6 py-2 rounded-full bg-accent-primary text-foreground dark:text-foreground-dark hover:bg-accent-primary-dimmer  transition-colors"
              >
                {currentQuestionIndex === totalQuestions - 1
                  ? "Submit Test"
                  : "Next Question"}
              </button>
              <button
                onClick={handleExitClick}
                className="px-6 py-2 rounded-full border border-red-500 text-red-500 hover:bg-red-600 transition-colors"
              >
                Exit Interview
              </button>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <div className="h-2 w-48 bg-[#333333] rounded-full overflow-hidden">
              <div
                className="h-full bg-green-500 transition-all duration-300"
                style={{
                  width: `${((currentQuestionIndex + 1) / totalQuestions) * 100}%`,
                }}
              />
            </div>
            <span className="text-sm text-foreground-dimmest dark:text-foreground-dimmest">
              {currentQuestionIndex + 1}/{totalQuestions} Questions
            </span>
          </div>
        </div>

        {/* Technical Exam Section */}
        <div className="space-y-6">
          <h2 className="text-xl font-semibold">
            Question {currentQuestionIndex + 1}
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Question Content */}
            <div>{renderQuestion()}</div>
            {/* Answer Section */}
            <div>
              {currentQuestion?.type === "coding" ? (
                <CodingBox
                  setParentTestCases={setTestCases}
                  parentCurrentTestCase={currentTestCase}
                  setParentCurrentTestCase={setCurrentTestCase}
                  code={code}
                  onCodeChange={setCode}
                />
              ) : (
                <div className="space-y-6">
                  <textarea
                    className="w-full h-24 rounded-lg  p-3 bg-background-root dark:bg-background-dark-root text-foreground dark:text-foreground-dark focus:outline-none focus:ring-2 focus:ring-[#0059EC]"
                    placeholder="Type your answer here..."
                    value={userAnswer}
                    onChange={(e) => setUserAnswer(e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Exit Modal */}
      {showExitModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-background-root dark:bg-background-dark-root rounded-lg p-6 max-w-md w-full mx-4 ">
            <h3 className="text-xl font-medium mb-2 text-foreground dark:text-foreground-dark">
              Are you sure?
            </h3>
            <p className="text-sm text-foreground-dimmest dark:text-foreground-dimmest mb-6">
              Exiting the test will end your interview and cause you to lose all
              your progress!
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCloseExitModal}
                className="px-4 py-2 text-foreground-dimmest dark:text-foreground-dimmest bg-background-dimmer dark:bg-background-dark-dimmer rounded-md hover:bg-[#333333] transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmExit}
                className="px-4 py-2 text-foreground dark:text-foreground-dark bg-accent-primary rounded-md hover:bg-accent-primary-dimmer  transition-colors"
              >
                I'm sure
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Technical;
