import React from "react";
import HeroSection from "./HeroSection";
import TestimonialsSection from "./TestimonialsSection";
import FAQSection from "./FAQSection";
import ProcessSteps from "./ProcessSteps";
import RecruitmentSection from "./RecruitmentSection";
import RecruitmentEndSection from "./RecruitmentEndSection";
import Footer from "./Footer";
import GuideTransition from "./GuideTransition";
import TopNavigation from "./TopNavigation";

const Home: React.FC = () => {
  return (
    <>
      <TopNavigation />
      <div className="bg-landing-background flex flex-col gap-20 w-screen overflow-hidden *:py-32">
        <section>
          <HeroSection />
        </section>

        <section id="who-we-are">
          <RecruitmentSection />
        </section>

        <GuideTransition />

        <section id="getting-started">
          <ProcessSteps />
        </section>

        <section id="testimonials">
          <TestimonialsSection />
        </section>
        <RecruitmentEndSection />

        <section className="flex flex-col w-full pb-0" id="faqs">
          <FAQSection />
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Home;
