import { Question } from "../interfaces";

export const backgroundQuestions: Question[] = [
  {
    id: 1,
    question: "Give a general introduction about yourself.",
    type: "other",
  },
  { id: 2, question: "Discuss your educational background.", type: "other" },
  {
    id: 3,
    question: "Talk about your first major experience or project.",
    type: "other",
  },
  {
    id: 4,
    question: "Talk about your second major experience or project.",
    type: "other",
  },
  {
    id: 5,
    question: "Talk about your third major experience or project.",
    type: "other",
  },
  {
    id: 6,
    question: "Talk about your fourth major experience or project.",
    type: "other",
  },
];

export const behavioralQuestions: Question[] = [
  {
    id: 1,
    question:
      "What are three interests or hobbies you enjoy, and why do they matter to you?",
    type: "other",
  },
  {
    id: 2,
    question:
      "List three accomplishments and explain why they are significant to you",
    type: "other",
  },
  {
    id: 3,
    question:
      "What are three qualities you value most about yourself, and why?",
    type: "other",
  },
  {
    id: 4,
    question: "List three struggles and how you overcame them",
    type: "other",
  },
  {
    id: 5,
    question: "How would you define a successful life?",
    type: "other",
  },
  {
    id: 6,
    question: "What motivates you to stay resilient during challenging times?",
    type: "other",
  },
];

export const technicalQuestions: Question[] = [
  {
    id: 1,
    question: "Find and return the sum of two integers",
    type: "coding",
    example1: "Input: 1, 2 \n Output: 3",
    example2: "Input: 10, 11 \n Output: 21",
  },
  {
    id: 2,
    question: "Find and return the sum of two integers",
    type: "coding",
    example1: "Input: 1, 2 \n Output: 3",
    example2: "Input: 10, 11 \n Output: 21",
  },
  {
    id: 3,
    question: "Find and return the sum of two integers",
    type: "coding",
    example1: "Input: 1, 2 \n Output: 3",
    example2: "Input: 10, 11 \n Output: 21",
  },
  {
    id: 4,
    question: "Find and return the sum of two integers",
    type: "coding",
    example1: "Input: 1, 2 \n Output: 3",
    example2: "Input: 10, 11 \n Output: 21",
  },
  {
    id: 5,
    question: "Find and return the sum of two integers",
    type: "coding",
    example1: "Input: 1, 2 \n Output: 3",
    example2: "Input: 10, 11 \n Output: 21",
  },
];
