// src/routes/testcenter/TestCenter.tsx
import React, { useEffect, useState } from "react";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { Eye, ChevronDown, ArrowRight, ExternalLink, Info } from "lucide-react";
import { VideoCard } from "./VideoCard";
import { dummyAggregatedAssessment, testOptions } from "./constants";
import {
  TestType,
  AttemptType,
  TestAttemptInfo,
  AggregatedAssessmentExtended,
  TechnicalGroupKey,
  BehavioralGroupKey,
  JobTitleBucketKey,
} from "./interfaces";
import { getLevelLabel } from "./helpers";
import { PositiveChip, PrimaryChip } from "../../components/Chip";
import { PrimaryButton } from "../../components/Button";

// Define interfaces for our skill objects with metadata
interface SkillWithMetadata {
  name: string;
  rating: number;
  recommended: boolean;
  selected: boolean;
  groupKey: string;
}

// Tooltip component for info icons
const Tooltip: React.FC<{ text: string }> = ({ text }) => (
  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 p-2 bg-background dark:bg-background-dark text-xs text-foreground-dimmer dark:text-foreground-dark-dimmer rounded shadow-lg w-64 invisible group-hover:visible z-10">
    {text}
  </div>
);

const TestCenter: React.FC = () => {
  const userID = localStorage.getItem("user_id");
  const [selectedAttempt, setSelectedAttempt] =
    useState<AttemptType>("attempt1");
  const [testData, setTestData] = useState<TestAttemptInfo | null>(null);
  const [expandedSkills, setExpandedSkills] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<
    "Technical" | "Behavioral" | "Background"
  >("Technical");

  // Aggregated assessment state
  const [assessment, setAssessment] = useState<AggregatedAssessmentExtended>(
    dummyAggregatedAssessment,
  );

  useEffect(() => {
    const fetchTestResults = async () => {
      try {
        // Map the activeTab to the corresponding test type
        const testType: TestType = activeTab.toLowerCase() as TestType;

        const response = await axiosInterceptor.get<TestAttemptInfo>(
          `/api/get_test_results/${userID}/`,
          {
            params: {
              test_type: testType,
              attempt_number: selectedAttempt.replace("attempt", ""),
            },
          },
        );
        setTestData(response.data);
      } catch (error) {
        console.error("Error fetching test attempt data:", error);
      }
    };

    if (userID) {
      fetchTestResults();
    }
  }, [userID, activeTab, selectedAttempt]);

  // Toggle a technical skill's "selected" state
  const handleTechnicalToggle = (
    groupKey: TechnicalGroupKey,
    skillName: string,
  ): void => {
    setAssessment((prev) => ({
      ...prev,
      technical: {
        ...prev.technical,
        [groupKey]: prev.technical[groupKey].map((skill) =>
          skill.name === skillName
            ? { ...skill, selected: !skill.selected }
            : skill,
        ),
      },
    }));
  };

  // Toggle a job title bucket item
  const handleBackgroundToggle = (
    bucketKey: JobTitleBucketKey,
    attrName: string,
  ): void => {
    setAssessment((prev) => ({
      ...prev,
      background: {
        ...prev.background,
        [bucketKey]: prev.background[bucketKey].map((item) =>
          item.name === attrName ? { ...item, selected: !item.selected } : item,
        ),
      },
    }));
  };

  // Toggle a behavioral attribute
  const handleBehavioralToggle = (
    attributeName: string,
    groupName: BehavioralGroupKey,
  ): void => {
    setAssessment((prev) => ({
      ...prev,
      behavioral: {
        ...prev.behavioral,
        [groupName]: {
          ...prev.behavioral[groupName],
          [attributeName]: {
            ...prev.behavioral[groupName][attributeName],
            selected: !prev.behavioral[groupName][attributeName].selected,
          },
        },
      },
    }));
  };

  // Toggle expand/collapse a skill's details
  const toggleExpand = (skillName: string) => {
    setExpandedSkills((prev) =>
      prev.includes(skillName)
        ? prev.filter((name) => name !== skillName)
        : [...prev, skillName],
    );
  };

  // Get color for proficiency level
  const getProficiencyColor = (rating: number): string => {
    if (rating >= 85) return "text-green-500";
    if (rating >= 70) return "text-yellow-500";
    return "text-red-500";
  };

  // Get label for proficiency level
  const getProficiencyLevel = (rating: number): string => {
    if (rating >= 85) return "High";
    if (rating >= 70) return "Med-High";
    if (rating >= 60) return "Med";
    return "Low";
  };

  // Get all technical skills
  const getTechnicalSkills = (): SkillWithMetadata[] => {
    return Object.entries(assessment.technical).flatMap(([groupKey, skills]) =>
      skills.map((skill) => ({
        ...skill,
        groupKey,
      })),
    );
  };

  // Get behavioral skills
  const getBehavioralSkills = (): SkillWithMetadata[] => {
    const result: SkillWithMetadata[] = [];
    Object.entries(assessment.behavioral).forEach(([groupKey, group]) => {
      Object.entries(group).forEach(([name, attr]) => {
        result.push({
          name,
          rating: attr.rating,
          recommended: attr.recommended,
          selected: attr.selected,
          groupKey,
        });
      });
    });
    return result;
  };

  // Get background skills
  const getBackgroundSkills = (): SkillWithMetadata[] => {
    return Object.entries(assessment.background).flatMap(([groupKey, skills]) =>
      skills.map((skill) => ({
        ...skill,
        groupKey,
      })),
    );
  };

  // Get recommended skills based on active tab
  const getRecommendedSkills = (): SkillWithMetadata[] => {
    if (activeTab === "Technical") {
      return getTechnicalSkills()
        .filter((skill) => skill.recommended)
        .slice(0, 3);
    } else if (activeTab === "Behavioral") {
      return getBehavioralSkills()
        .filter((skill) => skill.recommended)
        .slice(0, 3);
    } else {
      return getBackgroundSkills()
        .filter((skill) => skill.recommended)
        .slice(0, 3);
    }
  };

  // Get areas to improve based on active tab
  const getAreasToImprove = (): SkillWithMetadata[] => {
    if (activeTab === "Technical") {
      return getTechnicalSkills()
        .filter((skill) => skill.rating < 80 && skill.rating > 60)
        .slice(0, 3);
    } else if (activeTab === "Behavioral") {
      return getBehavioralSkills()
        .filter((skill) => skill.rating < 80 && skill.rating > 60)
        .slice(0, 3);
    } else {
      return getBackgroundSkills()
        .filter((skill) => skill.rating < 80 && skill.rating > 60)
        .slice(0, 3);
    }
  };

  // Get active skills based on tab and sort by display status first
  const getActiveSkills = (): SkillWithMetadata[] => {
    let skills: SkillWithMetadata[] = [];
    if (activeTab === "Technical") {
      skills = getTechnicalSkills();
    } else if (activeTab === "Behavioral") {
      skills = getBehavioralSkills();
    } else {
      skills = getBackgroundSkills();
    }

    // Sort by display status (displayed ones first) then by rating
    return skills.sort((a, b) => {
      if (a.selected !== b.selected) {
        return a.selected ? -1 : 1; // Displayed items come first
      }
      return b.rating - a.rating; // Then sort by rating
    });
  };

  // Handle skill toggle based on active tab
  const handleSkillToggle = (skillName: string, groupKey: string): void => {
    if (activeTab === "Technical") {
      handleTechnicalToggle(groupKey as TechnicalGroupKey, skillName);
    } else if (activeTab === "Behavioral") {
      handleBehavioralToggle(skillName, groupKey as BehavioralGroupKey);
    } else {
      handleBackgroundToggle(groupKey as JobTitleBucketKey, skillName);
    }
  };

  // Get recommendation pill background color
  const getRecommendationColor = (): string => {
    if (activeTab === "Technical") {
      return "bg-[#002FEB]"; // Blue primary slightly darker
    } else if (activeTab === "Behavioral") {
      return "bg-[#782ADB]"; // Purple slightly darker
    } else {
      return "bg-[#3E9400]"; // Green slightly darker
    }
  };

  // Get improvement pill background color
  const getImprovementColor = (): string => {
    return "bg-[#F7A002]"; // Warning color from design system
  };

  // Get skills visibility text based on active tab
  const getSkillsVisibilityText = (): string => {
    if (activeTab === "Technical") {
      return "Display your top skills, hide your weaknesses and improve them through courses and more mock interviews";
    } else {
      return "Display your top skills, hide your weaknesses and improve them by reviewing previous submissions and more mock interviews";
    }
  };

  return (
    <div className="min-h-screen bg-background-root dark:bg-background-dark-root text-foreground dark:text-foreground-dark">
      <div className="w-full mx-auto px-6 py-6">
        {/* Header with description */}
        <div className="border-b border-[#2D303E] pb-6 mb-6">
          <h1 className="text-2xl font-bold mb-3">Test Zone</h1>
          <p className="text-foreground-dimmer dark:text-foreground-dark-dimmer mb-1">
            This zone is your personal career coach and advocate. After our
            tests, we highlight strengths to showcase to recruiters and suggest
            crash courses to sharpen and expand your skills.
          </p>
          <p className="text-foreground-dimmer dark:text-foreground-dark-dimmer">
            <span className="text-blue-400">
              Our mission is simple: level the playing field and help you grow.
            </span>{" "}
            In our judgment-free space, we identify your strengths, guide your
            improvement, and ensure you present your best self to employers.
          </p>
        </div>

        {/* Tab Navigation */}
        <div className="border-b border-[#2D303E] mb-8 w-full">
          <div className="flex w-full">
            {["Technical", "Behavioral", "Background"].map((tab) => (
              <button
                key={tab}
                className={`pb-4 text-sm font-medium transition-colors flex-1 text-center ${
                  activeTab === tab
                    ? "text-foreground dark:text-foreground-dark border-b-2 border-blue-500"
                    : "text-gray-400 hover:text-foreground-dimmer dark:text-foreground-dark-dimmer"
                }`}
                onClick={() => setActiveTab(tab as any)}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="rounded-xl overflow-hidden flex flex-col bg-background-root dark:bg-background-dark-root">
          <div className="p-4 text-foreground dark:text-foreground-dark">
            {/* Top Cards */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-10">
              {/* Test Card */}
              <div className="bg-background dark:bg-background-dark rounded-lg p-6">
                <h3 className="text-lg font-medium mb-2">{activeTab} Test</h3>
                <p className="text-sm text-gray-400 mb-4">
                  ~{" "}
                  {testOptions.find((opt) => opt.title === activeTab)
                    ?.duration || "60 min"}
                </p>
                <p className="text-sm text-foreground-dimmer dark:text-foreground-dark-dimmer mb-6">
                  Hone your {activeTab.toLowerCase()} interview skills through
                  real-life practice questions.
                </p>
                <PrimaryButton
                  message="Start Test"
                  handleClick={() => {
                    const option = testOptions.find(
                      (opt) => opt.title === activeTab,
                    );
                    if (option) window.location.href = option.route;
                  }}
                ></PrimaryButton>
              </div>

              {/* Display Recommendations */}
              <div className="bg-background dark:bg-background-dark rounded-lg p-6">
                <h3 className="text-lg font-medium mb-2">
                  Display Recommendations
                </h3>
                <p className="text-sm text-gray-400 mb-4">
                  Click on a tag to navigate to the attribute
                </p>
                <p className="text-sm text-foreground-dimmer dark:text-foreground-dark-dimmer mb-4">
                  Based on your recent test performance and the attributes
                  recruiters are seeking, we recommend displaying:
                </p>
                <div className="flex flex-wrap gap-2">
                  {getRecommendedSkills().map((skill) => (
                    <PrimaryChip
                      message={skill.name}
                      key={skill.name}
                    ></PrimaryChip>
                  ))}
                </div>
              </div>

              {/* Areas to Improve */}
              <div className="bg-background dark:bg-background-dark rounded-lg p-6">
                <h3 className="text-lg font-medium mb-2">Areas to Improve</h3>
                <p className="text-sm text-gray-400 mb-4">
                  Click on a tag to navigate to the attribute
                </p>
                <p className="text-sm text-foreground-dimmer dark:text-foreground-dark-dimmer mb-4">
                  Based on your recent test performance and the attributes
                  recruiters are seeking, we recommend improving:
                </p>
                <div className="flex flex-wrap">
                  {getAreasToImprove().map((skill) => (
                    <span
                      key={skill.name}
                      className={`inline-block px-3 py-1.5 rounded-full text-xs font-medium ${getImprovementColor()} text-foreground dark:text-foreground-dark mr-2 mb-2 cursor-pointer hover:opacity-90`}
                    >
                      {skill.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            {/* Video Section */}
            <div className="mb-10 bg-background dark:bg-background-dark rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">{activeTab} Interview</h3>
                <div className="flex items-center gap-2">
                  <select
                    value={selectedAttempt}
                    onChange={(e) =>
                      setSelectedAttempt(e.target.value as AttemptType)
                    }
                    className="rounded px-2 py-1 text-sm bg-background-root dark:bg-background-dark-root text-foreground dark:text-foreground-dark focus:ring-2 focus:ring-[#0059EC] border border-[#2D303E]"
                  >
                    <option value="attempt1">Attempt 1</option>
                    <option value="attempt2">Attempt 2</option>
                  </select>
                </div>
              </div>

              <VideoCard
                videoUrl={
                  testData?.videoUrls?.[
                    parseInt(selectedAttempt.replace("attempt", ""), 10) - 1
                  ] || undefined
                }
                title={`${activeTab} Interview ${selectedAttempt.replace("attempt", "Attempt ")}`}
              />
            </div>

            {/* Breakdown Table */}
            <div className="bg-background dark:bg-background-dark rounded-lg p-6 mb-6">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-medium">Breakdown</h3>
                <p className="text-sm text-gray-400">
                  {getSkillsVisibilityText()}
                </p>
              </div>

              {/* Table Header */}
              <div
                className={`grid ${activeTab === "Technical" ? "grid-cols-12" : "grid-cols-10"} gap-4 border-b border-[#2D303E] pb-3 mb-2 text-sm text-gray-400`}
              >
                <div className="col-span-1 flex justify-center">Display</div>
                <div className="col-span-3">Skill</div>
                <div className="col-span-3 flex items-center">
                  Proficiency
                  <span className="ml-1 text-gray-500 inline-flex items-center group relative">
                    <Info className="w-3 h-3" />
                    <Tooltip text="Our estimate of the ability based on parameters from our internal models." />
                  </span>
                </div>
                <div className="col-span-3 flex items-center">
                  Confidence Score
                  <span className="ml-1 text-gray-500 inline-flex items-center group relative">
                    <Info className="w-3 h-3" />
                    <Tooltip text="Relates to standard error on how far off we might be based on the statistical estimation results from our internal models." />
                  </span>
                </div>
                {activeTab === "Technical" && (
                  <div className="col-span-2 text-right">View</div>
                )}
              </div>

              {/* Skills Rows */}
              {getActiveSkills().map((skill) => (
                <React.Fragment key={skill.name}>
                  <div
                    className={`grid ${activeTab === "Technical" ? "grid-cols-12" : "grid-cols-10"} gap-4 py-4 border-b border-[#2D303E] text-sm`}
                  >
                    <div className="col-span-1 flex justify-center items-center">
                      <Eye
                        className={`w-5 h-5 cursor-pointer ${skill.selected ? "text-gray-200" : "text-gray-500"}`}
                        onClick={() =>
                          handleSkillToggle(skill.name, skill.groupKey)
                        }
                      />
                    </div>
                    <div className="col-span-3 flex items-center">
                      {skill.name}
                    </div>
                    <div
                      className={`col-span-3 flex items-center ${getProficiencyColor(skill.rating)}`}
                    >
                      {getProficiencyLevel(skill.rating)} (
                      {Math.round(skill.rating / 10)}/10)
                    </div>
                    <div className="col-span-3 flex items-center">
                      {skill.rating}%
                    </div>
                    {activeTab === "Technical" && (
                      <div className="col-span-2 flex justify-end items-center">
                        <button
                          className="rounded-md flex items-center hover:text-foreground-dimmer dark:text-foreground-dark-dimmer transition-colors"
                          onClick={() => toggleExpand(skill.name)}
                        >
                          Sub-Attributes
                          <ChevronDown
                            className={`ml-1 w-4 h-4 transition-transform ${
                              expandedSkills.includes(skill.name)
                                ? "rotate-180"
                                : ""
                            }`}
                          />
                        </button>
                      </div>
                    )}
                  </div>

                  {/* Expanded Row for Sub-attributes (only for Technical) */}
                  {activeTab === "Technical" &&
                    expandedSkills.includes(skill.name) && (
                      <div className="bg-[#151820] py-3 px-6 mb-2">
                        <div className="text-sm text-gray-400 mb-3">
                          Sub-attributes for {skill.name}
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {/* Sample sub-attributes */}
                          {[
                            {
                              name: `${skill.name} Fundamentals`,
                              rating: Math.min(skill.rating + 5, 100),
                            },
                            {
                              name: `${skill.name} Applications`,
                              rating: skill.rating,
                            },
                            {
                              name: `Advanced ${skill.name}`,
                              rating: Math.max(skill.rating - 10, 40),
                            },
                          ].map((subSkill) => (
                            <div
                              key={subSkill.name}
                              className="flex justify-between items-center text-sm py-1"
                            >
                              <span>{subSkill.name}</span>
                              <div className="flex items-center gap-4">
                                <span
                                  className={getProficiencyColor(
                                    subSkill.rating,
                                  )}
                                >
                                  {getProficiencyLevel(subSkill.rating)} (
                                  {Math.round(subSkill.rating / 10)}/10)
                                </span>
                                <button className="bg-[#0032FF] hover:opacity-90 px-3 py-1 rounded flex items-center text-xs transition-colors text-foreground dark:text-foreground-dark">
                                  Course{" "}
                                  <ExternalLink className="ml-1 w-3 h-3" />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                </React.Fragment>
              ))}
            </div>

            {/* Video Section */}
            <div className="mb-10 bg-[#1A1C23] rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">{activeTab} Interview</h3>
                <div className="flex items-center gap-2">
                  <select
                    value={selectedAttempt}
                    onChange={(e) =>
                      setSelectedAttempt(e.target.value as AttemptType)
                    }
                    className="rounded px-2 py-1 text-sm bg-background-root dark:bg-background-dark-roottext-foreground-dimmer dark:text-foreground-dark-dimmer focus:ring-2 focus:ring-accent-primary border border-[#2D303E]"
                  >
                    <option value="attempt1">Attempt 1</option>
                    <option value="attempt2">Attempt 2</option>
                  </select>
                </div>
              </div>

              <VideoCard
                videoUrl={
                  testData?.videoUrls?.[
                    parseInt(selectedAttempt.replace("attempt", ""), 10) - 1
                  ] || undefined
                }
                title={`${activeTab} Interview ${selectedAttempt.replace("attempt", "Attempt ")}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestCenter;
