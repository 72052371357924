import React, { useEffect, useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import axiosInterceptor from "../../utils/axiosInterceptor";
import sendVerificationCode from "../../utils/sendEmailVerification";
import { VerifyModal } from "../../components/LandingComponents";

function Verify(): JSX.Element {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("...");
  const [code, setCode] = useState<string[]>(["", "", "", "", "", ""]);

  // Check if email is already verified
  useEffect(() => {
    const isEmailVerified = localStorage.getItem("is_email_verified");
    if (isEmailVerified === "true") {
      console.log("Already verified, redirecting to home");
      navigate("/");
    }
  }, [navigate]);

  const user_id = localStorage.getItem("user_id") || "";

  // Fetch user email
  useEffect(() => {
    if (!user_id) return;
    axiosInterceptor
      .get(`getuser/${user_id}/`)
      .then((response: any) => {
        setEmail(response.data["u_data"]["email"]);
      })
      .catch((error: any) => {
        console.error("Error fetching user email:", error);
      });
  }, [user_id]);

  // Confirm sign-up by verifying the full code
  async function confirmSignUp(fullCode: string): Promise<boolean> {
    try {
      const response = await axiosInterceptor.get(
        `/check_verification_code/?code=${fullCode}&email=${email}`,
      );
      if (response.status >= 200 && response.status < 300) {
        localStorage.setItem("is_email_verified", "true");
        alert("Account verified successfully!");
        setTimeout(() => {
          navigate("/account/submitted");
        }, 250);
        return true;
      }
    } catch (error) {
      console.error("Error verifying account:", error);
      alert("Invalid verification code. Please try again.");
    }
    return false;
  }

  // Handle form submission
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const fullCode = code.join("");
    confirmSignUp(fullCode).then((res) => {
      if (res) navigate("/");
    });
  }

  // Trigger resend code logic
  const triggerResendCodeLogic = async () => {
    await sendVerificationCode(email);
    alert("Email resent! Please check your inbox.");
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <VerifyModal
        code={code}
        handleCodeChange={setCode}
        handleSubmit={handleSubmit}
        handleRequestResend={triggerResendCodeLogic}
        email={email}
      ></VerifyModal>
    </div>
  );
}

export default Verify;
