import { HTMLAttributes, ReactNode } from "react";

interface HorizontalSelectionProps {
  isSelected: boolean;
  handleSelectedChange: (updatedSelectedState: boolean) => void;
  children: ReactNode;
}

const HorizontalSelection = ({
  isSelected,
  handleSelectedChange,
  children,
  className = "",
}: HorizontalSelectionProps & HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      role="switch"
      aria-checked={isSelected}
      onClick={() => handleSelectedChange(!isSelected)}
      className={`w-full px-4 py-3 transition-colors duration-200 border-[1px] border-solid rounded-md ${
        isSelected
          ? "border-accent-primary/10 bg-accent-primary-higher hover:border-accent-primary/50 dark:border-accent-primary dark:bg-accent-primary/5 hover:dark:bg-accent-primary/20"
          : "bg-background dark:bg-background-dark-root hover:bg-background-dimmer border-background-deepest dark:border-background-dark-deepest"
      } hover:dark:bg-background-dark-dimmer`}
    >
      <div className={`flex gap-4 items-center justify-start ${className}`}>
        <div
          className={`relative shrink-0 w-5 h-5 border-[1px] rounded-sm transition-colors ${
            isSelected
              ? "border-accent-primary bg-accent-primary"
              : "border-background-deepest dark:border-background-dark-deepest bg-transparent"
          }`}
        >
          {isSelected && (
            <svg
              className="absolute inset-0 text-background"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </div>
        {children}
      </div>
    </button>
  );
};

export default HorizontalSelection;
