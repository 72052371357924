import React, { useState, useEffect, useMemo, useCallback } from "react";
import axiosInterceptor from "../../utils/axiosInterceptor";
import ScreenSpinner from "../../components/ScreenSpinner";
import { Dropdown } from "react-bootstrap";
import {
  Course,
  CourseProgress,
  SelectedCourseDrawerProps,
  CourseContent,
  LectureContent,
} from "./types";
import SelectedCourseDrawer from "./SelectedCourseDrawer";
import {
  fetchCourseRecommendations,
  fetchCoursesProgress,
  fetchSavedCourseTypes,
  fetchAllCourses,
  fetchCourseContent,
  fetchLectureContent,
  isCourseMissingPrerequisites,
  fetchCompletedLectures,
  generateMITCitation,
  extractLectureContentURL,
  computeLecturesProgress,
  fetchUserId,
} from "./courseUtils";
import { useNavigate } from "react-router-dom";
import FileURLViewer from "../../components/FileUrlViewer";
import CoursePaths from "./CoursePaths";
import { DropdownToggle } from "../../components/DropdownToggle";
import { DefaultChip, PositiveChip, PrimaryChip } from "../../components/Chip";

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [recommendations, setRecommendations] = useState<Course[]>([]);
  const [coursesProgress, setCoursesProgress] = useState<CourseProgress[]>([]);
  const [selectedCourseTypes, setSelectedCourseTypes] = useState<
    Record<string, boolean>
  >({});

  // Selection/focus for the path
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [focusedCourse, setFocusedCourse] = useState<Course | null>(null);
  const [lastSelectedCourse, setLastSelectedCourse] = useState<Course | null>(
    null,
  );

  // Data loaded checks
  const [courseDataLoaded, setCourseDataLoaded] = useState(false);
  const [progressDataLoaded, setProgressDataLoaded] = useState(false);
  const [recommendationDataLoaded, setRecommendationDataLoaded] =
    useState(false);

  // Drawer data
  const [showSelectedCourseDrawer, setShowSelectedCourseDrawer] =
    useState(false);
  const [selectedCourseContent, setSelectedCourseContent] =
    useState<CourseContent | null>(null);
  const [
    selectedCourseLecturePreviewData,
    setSelectedCourseLecturePreviewData,
  ] = useState<LectureContent | null>(null);
  const [selectedCourseCompletedLectures, setSelectedCourseCompletedLectures] =
    useState<string[] | null>(null);

  // // Initial recommendations (with no progress)
  // const [initialRecommendations, setInitialRecommendations] = useState<
  //   Course[]
  // >([]);

  const navigate = useNavigate();

  // ----------------------------------------
  // 1) Fetch all course data
  // ----------------------------------------
  useEffect(() => {
    const loadAllData = async () => {
      try {
        const [allCourses, userId] = await Promise.all([
          fetchAllCourses(),
          fetchUserId(),
        ]);
        setCourses(allCourses);
        setCourseDataLoaded(true);

        const initialRecommendations = allCourses
          .filter(
            (course) =>
              !Array.isArray(course.prerequisites) ||
              course.prerequisites.length === 0,
          )
          .slice(0, 3);

        setRecommendations(initialRecommendations);

        const allCoursesProgress = await fetchCoursesProgress(userId);
        setCoursesProgress(allCoursesProgress);
        setProgressDataLoaded(true);

        const actualRecs = await fetchCourseRecommendations(
          allCourses,
          allCoursesProgress,
        );
        setRecommendations(actualRecs);
        setRecommendationDataLoaded(true);
      } catch (error) {
        console.error("Error loading data in <Courses />:", error);
        navigate("/");
      }
    };
    loadAllData();
  }, [navigate]);

  // ----------------------------------------
  // 2) Load user's saved course types
  // ----------------------------------------
  const loadSavedCourseTypes = useCallback(async (loadedCourses: Course[]) => {
    if (loadedCourses.length === 0) return;
    const courseTypes = [...new Set(loadedCourses.map((c) => c.courseType))];
    try {
      const userId = localStorage.getItem("user_id");
      if (!userId) throw new Error("No user_id found.");
      const savedTypes = await fetchSavedCourseTypes(userId);

      const selectedTypes: Record<string, boolean> = courseTypes.reduce(
        (acc, type) => {
          acc[type] = savedTypes.includes(type);
          return acc;
        },
        {} as Record<string, boolean>,
      );

      // If everything is false, default them all to true
      if (Object.values(selectedTypes).every((val) => !val)) {
        courseTypes.forEach((t) => (selectedTypes[t] = true));
      }
      setSelectedCourseTypes(selectedTypes);
    } catch (err) {
      console.error("Error loading saved course types:", err);
      // fallback = everything checked
      const fallback: Record<string, boolean> = {};
      courseTypes.forEach((t) => (fallback[t] = true));
      setSelectedCourseTypes(fallback);
    }
  }, []);

  useEffect(() => {
    loadSavedCourseTypes(courses);
  }, [courses, loadSavedCourseTypes]);

  // ----------------------------------------
  // 3) Filter toggles
  //    - The checkbox changes immediately; server update is background only.
  // ----------------------------------------
  const handleCourseTypeChange = useCallback(
    (type: string) => {
      // Toggle in local state immediately
      const newSelectedTypes = {
        ...selectedCourseTypes,
        [type]: !selectedCourseTypes[type],
      };
      setSelectedCourseTypes(newSelectedTypes);

      // Fire-and-forget server call
      const userId = localStorage.getItem("user_id");
      if (!userId) return;

      const savedTypes = Object.entries(newSelectedTypes)
        .filter(([, val]) => val)
        .map(([courseType]) => courseType);

      axiosInterceptor
        .post(`/set_saved_course_types_for_user/${userId}/`, {
          saved_course_types: savedTypes,
        })
        .catch((err) => {
          console.error("Error saving course types:", err);
        });
    },
    [selectedCourseTypes],
  );

  // ----------------------------------------
  // 4) Selection & focus on the path
  // ----------------------------------------
  const handleCourseSelectedChange = useCallback((course: Course | null) => {
    setSelectedCourse(course);
    if (course) setFocusedCourse(course);
    if (!course) setShowSelectedCourseDrawer(false);
  }, []);

  const handleCourseFocusChange = useCallback(
    (course: Course | null) => {
      if (!selectedCourse) {
        setFocusedCourse(course);
      }
    },
    [selectedCourse],
  );

  // ----------------------------------------
  // 5) Load offcanvas data for selected course
  // ----------------------------------------
  useEffect(() => {
    const updateCourseDrawerData = async () => {
      if (!selectedCourse) return;
      try {
        setLastSelectedCourse(selectedCourse);
        setShowSelectedCourseDrawer(true);
        const [content, completed, lecture0] = await Promise.all([
          fetchCourseContent(selectedCourse.id),
          fetchCompletedLectures(selectedCourse.id),
          fetchLectureContent(selectedCourse.id, 0),
        ]);
        setSelectedCourseContent(content);
        setSelectedCourseCompletedLectures(completed);
        setSelectedCourseLecturePreviewData(lecture0);
      } catch (err) {
        console.error("Error fetching selected course data:", err);
      }
    };
    updateCourseDrawerData();
  }, [selectedCourse]);

  // ----------------------------------------
  // 6) Course progress handlers
  // ----------------------------------------
  const handleNavigateToCourse = useCallback(
    (course: Course) => {
      navigate(`/courses/${course.id}/lectures/1`);
    },
    [navigate],
  );

  const handleUpdateCourseProgress = useCallback(
    async (course: Course, completed: boolean) => {
      const userId = localStorage.getItem("user_id");
      if (!userId) return;

      let newVal = completed ? 1 : 0;
      if (!completed) {
        const doneLectures = await fetchCompletedLectures(course.id);
        if (doneLectures.length > 0) newVal = 0.5; // partial progress
      }

      try {
        await axiosInterceptor.patch(
          `course-progress/update/${userId}/`,
          {
            updates: [{ courseNumber: course.courseNumber, progress: newVal }],
          },
          { headers: { "Content-Type": "application/json" } },
        );
        setCoursesProgress((prev) => {
          const copy = [...prev];
          const found = copy.find(
            (p) => p.courseNumber === course.courseNumber,
          );
          if (found) found.progress = newVal;
          else
            copy.push({ courseNumber: course.courseNumber, progress: newVal });
          return copy;
        });
      } catch (err) {
        console.error("Failed to update progress for", course.name, err);
      }
    },
    [],
  );

  // ----------------------------------------
  // 7) Build offcanvas drawer props
  // ----------------------------------------
  const selectedCourseDrawerProps: SelectedCourseDrawerProps | null =
    useMemo(() => {
      if (!lastSelectedCourse) return null;
      if (!selectedCourseContent || !selectedCourseCompletedLectures) {
        // Still loading
        return {
          showDrawer: showSelectedCourseDrawer,
          selectedCourse: lastSelectedCourse,
          selectedCoursePrerequisites: [],
          selectedCourseCitation: "",
          courseStatusChipProps: null,
          lecturePreview: <p>Content Preview Loading…</p>,
          lecturesProgress: [],
          handleHide: () => setShowSelectedCourseDrawer(false),
          handleNavigateToCourse,
        };
      }

      const progressObj = coursesProgress.find(
        (cp) => cp.courseNumber === lastSelectedCourse.courseNumber,
      );
      const missingPrereqs = isCourseMissingPrerequisites(
        lastSelectedCourse,
        coursesProgress,
      );
      const recommended = recommendations.some(
        (r) => r.courseNumber === lastSelectedCourse.courseNumber,
      );

      const prerequisites = courses.filter((c) =>
        lastSelectedCourse.prerequisites?.includes(c.courseNumber),
      );

      const citation = generateMITCitation(
        selectedCourseContent.author,
        selectedCourseContent.title,
        selectedCourseContent.term,
      );

      const computedLecturesProgress = computeLecturesProgress(
        selectedCourseContent,
        selectedCourseCompletedLectures,
      );

      const lecturePreviewURL = selectedCourseLecturePreviewData
        ? extractLectureContentURL(selectedCourseLecturePreviewData)
        : null;

      return {
        showDrawer: showSelectedCourseDrawer,
        selectedCourse: lastSelectedCourse,
        selectedCoursePrerequisites: prerequisites,
        selectedCourseCitation: citation,
        courseStatusChipProps: progressObj
          ? {
              courseProgress: progressObj.progress,
              needsMorePrerequisites: missingPrereqs,
              isRecommended: recommended,
            }
          : null,
        lecturePreview:
          lecturePreviewURL && selectedCourseLecturePreviewData ? (
            <FileURLViewer
              url={lecturePreviewURL}
              fileType={selectedCourseLecturePreviewData?.type}
            />
          ) : (
            <p>Content Loading…</p>
          ),
        lecturesProgress: computedLecturesProgress || [],
        handleHide: () => setShowSelectedCourseDrawer(false),
        handleNavigateToCourse,
      };
    }, [
      lastSelectedCourse,
      selectedCourseContent,
      selectedCourseCompletedLectures,
      selectedCourseLecturePreviewData,
      showSelectedCourseDrawer,
      coursesProgress,
      courses,
      recommendations,
      handleNavigateToCourse,
    ]);

  // ----------------------------------------
  // 8) Aggregate user progress for the top bar
  // ----------------------------------------
  const visibleCourses = useMemo(() => {
    return courses.filter((c) => selectedCourseTypes[c.courseType]);
  }, [courses, selectedCourseTypes]);

  const { completedCount, inProgressCount, notStartedCount } = useMemo(() => {
    let _completed = 0;
    let _inProg = 0;

    if (!progressDataLoaded) {
      return {
        completedCount: 0,
        inProgressCount: 0,
        notStartedCount: visibleCourses.length,
      };
    }

    for (const course of visibleCourses) {
      const cp = coursesProgress.find(
        (x) => x.courseNumber === course.courseNumber,
      );
      const val = cp?.progress ?? 0;
      if (val >= 1) _completed++;
      else if (val > 0) _inProg++;
    }

    return {
      completedCount: _completed,
      inProgressCount: _inProg,
      notStartedCount: visibleCourses.length - _completed - _inProg,
    };
  }, [visibleCourses, coursesProgress, progressDataLoaded]);

  // ----------------------------------------
  // 9) Render
  // ----------------------------------------
  if (!courseDataLoaded) {
    return <ScreenSpinner />;
  }

  return (
    <div
      onClick={() => {
        // Clicking background unselects
        handleCourseFocusChange(null);
        handleCourseSelectedChange(null);
      }}
      className="h-full w-full flex flex-col gap-8 items-start py-4"
    >
      {/* Top bar with dropdown & progress */}
      <div className="flex flex-row items-start justify-start gap-4 w-full px-4">
        {/* We use autoClose="outside" so it won't close after each checkbox toggle */}
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            as={DropdownToggle}
            className="bg-background dark:bg-background-dark text-foreground-dimmer dark:text-foreground-dark-dimmer !border-none !shadow-none 
               hover:bg-background-dimmer dark:hover:bg-background-dark-dimmer transition-none px-4 py-2 flex items-center
               rounded-md"
            style={{
              borderWidth: "0.5px",
              borderStyle: "solid",
              borderColor: "rgba(56, 58, 64, 1)",
              borderRadius: "4px",
            }}
            id="course-type-dropdown"
            message="Course Type"
          >
            <span className="flex items-center gap-2">
              Course Type
              <span className="ml-1">▼</span>
            </span>
          </Dropdown.Toggle>
          {/* renderOnMount => avoid any mount/dismount delay */}
          <Dropdown.Menu
            renderOnMount
            className="bg-background dark:bg-background-dark text-foreground dark:text-foreground-dark mt-2 !rounded-md shadow-sm cursor-pointer 
               !text-base !font-normal transition-none"
            style={{
              borderWidth: "0.5px",
              borderStyle: "solid",
              borderColor: "rgba(56, 58, 64, 1)",
              borderRadius: "4px",
            }}
          >
            {Object.keys(selectedCourseTypes).map((type) => (
              <Dropdown.Item
                as="div"
                key={type}
                // Force background/text on normal, hover, active
                className="bg-background dark:bg-background-dark hover:bg-background-dimmer dark:hover:bg-background-dark-dimmer active:bg-background-dimmest dark:active:bg-background-dark-dimmest text-foreground dark:text-foreground-dark transition-none"
              >
                {/* 
          We wrap checkbox & label in a <label> for immediate toggling
          'onChange' toggles local state instantly 
        */}
                <label className="flex items-center gap-x-2 w-full cursor-pointer select-none">
                  <input
                    checked={selectedCourseTypes[type]}
                    type="checkbox"
                    onChange={() => handleCourseTypeChange(type)}
                    className="accent-accent-primary w-4 h-4 rounded"
                  />
                  <span>{type}</span>
                </label>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {/* Progress aggregator - text white */}
        <div className="flex flex-row items-center gap-2">
          <PrimaryChip message={"Completed: " + completedCount}></PrimaryChip>
          <PositiveChip
            message={"In Progress: " + inProgressCount}
          ></PositiveChip>
          <DefaultChip
            message={"Not Started: " + notStartedCount}
          ></DefaultChip>
        </div>
      </div>

      {/* Main course map */}
      <CoursePaths
        courses={courses}
        recommendations={recommendations}
        isRecommendationDataLoaded={recommendationDataLoaded}
        selectedCourseTypes={selectedCourseTypes}
        coursesProgress={coursesProgress}
        selectedCourse={selectedCourse}
        focusedCourse={focusedCourse}
        handleNavigateToCourse={handleNavigateToCourse}
        handleCourseFocusChange={handleCourseFocusChange}
        handleCourseSelectedChange={handleCourseSelectedChange}
        handleUpdateCourseProgress={handleUpdateCourseProgress}
      />

      {selectedCourseDrawerProps && (
        <SelectedCourseDrawer {...selectedCourseDrawerProps} />
      )}
    </div>
  );
};

export default Courses;
