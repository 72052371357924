import { FC, SyntheticEvent, useMemo, useRef } from "react";
// import { BezierConnectorProps } from "../../components/BezierConnector";
import {
  Course,
  CourseCardProps,
  CourseColumnProps,
  CourseMapProps,
} from "./types";
// import BezierConnector from "../../components/BezierConnector";
import {
  computeCoursesMissingPrerequisites,
  findRecursiveCoursePrerequisites,
  getCourseLevels,
} from "./courseUtils";
import CourseCard from "./CourseCard";

const CourseMap: FC<CourseMapProps> = ({
  courses,
  recommendations,
  isRecommendationDataLoaded,
  selectedCourseTypes,
  coursesProgress,
  selectedCourse,
  focusedCourse,
  handleNavigateToCourse,
  handleCourseFocusChange,
  handleCourseSelectedChange,
  handleUpdateCourseProgress,
}) => {
  const courseCardRefs = useRef<Record<string, HTMLDivElement>>({});

  const [courseLevels, _courseConnections] = useMemo(() => {
    return getCourseLevels(courses);
  }, [courses]);

  const needsMorePrerequisites: Course[] = useMemo<Course[]>(() => {
    return computeCoursesMissingPrerequisites(courses, coursesProgress);
  }, [courses, coursesProgress]);

  const highlightedCourses: Set<string> = useMemo<Set<string>>(() => {
    const prerequisites = selectedCourse
      ? findRecursiveCoursePrerequisites(selectedCourse.courseNumber, courses)
      : focusedCourse
        ? findRecursiveCoursePrerequisites(focusedCourse.courseNumber, courses)
        : new Set<string>();
    if (prerequisites.size > 0) return prerequisites;
    else {
      return new Set();
    }
  }, [focusedCourse, selectedCourse, courses]);

  const courseCardColumns: CourseColumnProps[] = useMemo<
    CourseColumnProps[]
  >(() => {
    const columns: CourseCardProps[][] = [];
    if (courses.length > 0) {
      const focusedCourseLevel = focusedCourse
        ? courseLevels[focusedCourse.courseNumber]
          ? courseLevels[focusedCourse.courseNumber]
          : null
        : null;
      courses.forEach((course) => {
        const courseProgress = coursesProgress.find(
          (coursesProgressData) =>
            coursesProgressData.courseNumber === course.courseNumber,
        );

        const currentCourseLevel = courseLevels[course.courseNumber];
        if (currentCourseLevel === undefined)
          throw new Error(
            `course ${course.courseNumber} : ${course.name} does not have a level`,
          );

        const levelLessThanFocused = focusedCourseLevel
          ? focusedCourseLevel > currentCourseLevel
          : false;
        const selected = selectedCourse
          ? selectedCourse.courseNumber === course.courseNumber
          : false;
        const highlighted = highlightedCourses.has(course.courseNumber);
        const recommended = recommendations.some(
          (recommendedCourse) => recommendedCourse.id === course.id,
        );

        const courseCardProp: CourseCardProps = {
          course: course,
          isDisabled: levelLessThanFocused,
          showPrerequisiteLabel:
            selectedCourse && selectedCourse.prerequisites
              ? selectedCourse.prerequisites.includes(course.courseNumber)
              : focusedCourse && focusedCourse.prerequisites
                ? focusedCourse.prerequisites.includes(course.courseNumber)
                : false,
          isSelected: selected,
          isHighlighted: highlighted,
          isRecommended: recommended,
          isLoaded: isRecommendationDataLoaded,
          needsMorePrerequisites: needsMorePrerequisites.some(
            (courseNeedingPrereq) => courseNeedingPrereq.id === course.id,
          ),
          progress: courseProgress ? courseProgress.progress : 0,
          handleCourseSelectedChange: handleCourseSelectedChange,
          handleCourseFocusChange: handleCourseFocusChange,
          handleNavigateToCourse: handleNavigateToCourse,
          handleUpdateCourseProgress: handleUpdateCourseProgress,
        };

        if (columns[currentCourseLevel] === undefined) {
          columns[currentCourseLevel] = [];
        }
        columns[currentCourseLevel].push(courseCardProp);
      });
    }

    for (let i = columns.length - 1; i >= 0; i--) {
      columns[i] = columns[i]
        .filter((courseCardProp) =>
          focusedCourse || selectedCourse
            ? courseCardProp.isHighlighted ||
              selectedCourseTypes[courseCardProp.course.courseType]
            : selectedCourseTypes[courseCardProp.course.courseType],
        )
        .sort((cardA, cardB) =>
          cardA.course.name
            .trim()
            .toLowerCase()
            .localeCompare(cardB.course.name.trim().toLowerCase()),
        );
      if (columns[i].length === 0) columns.splice(i, 1);
    }

    const cardColumnData: CourseColumnProps[] = columns.map(
      (courseCardPropList) => {
        const enabled = courseCardPropList.filter(
          (courseCardProp) => courseCardProp.isHighlighted,
        );
        const disabled = courseCardPropList.filter(
          (courseCardProp) => !courseCardProp.isHighlighted,
        );
        return {
          enabledCourseCardProps: enabled,
          disabledCourseCardProps: disabled,
          allCourseCardProps: courseCardPropList,
          overlayHighlighted: selectedCourse ? true : false,
        };
      },
    );
    return cardColumnData;
  }, [
    courses,
    focusedCourse,
    courseLevels,
    coursesProgress,
    selectedCourse,
    highlightedCourses,
    recommendations,
    isRecommendationDataLoaded,
    needsMorePrerequisites,
    handleCourseSelectedChange,
    handleCourseFocusChange,
    handleNavigateToCourse,
    handleUpdateCourseProgress,
    selectedCourseTypes,
  ]);

  // const bezierConnectorProps: BezierConnectorProps[] = useMemo<
  //   BezierConnectorProps[]
  // >(() => {
  //   const newBezierConnections: BezierConnectorProps[] = courseConnections
  //     .filter(
  //       (prerequisiteConnection) =>
  //         highlightedCourses.has(prerequisiteConnection.course.courseNumber) &&
  //         highlightedCourses.has(
  //           prerequisiteConnection.prerequisite.courseNumber,
  //         ),
  //     )
  //     .map((connection) => {
  //       const startDiv =
  //         courseCardRefs.current[connection.prerequisite.courseNumber];

  //       const endDiv = courseCardRefs.current[connection.course.courseNumber];

  //       return {
  //         startRef: { current: startDiv },
  //         endRef: { current: endDiv },
  //       };
  //     })
  //     .filter((connectorProp) => connectorProp.endRef && connectorProp.endRef);
  //   return newBezierConnections;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [courseConnections, highlightedCourses, courseCardColumns]);

  return (
    <div className="h-full w-full p-8 overflow-hidden flex flex-row relative ">
      <div
        className={`overflow-x-scroll flex flex-row gap-4 ${
          selectedCourse ? "pr-96" : ""
        }`}
      >
        {courseCardColumns.map(
          (
            {
              overlayHighlighted,
              enabledCourseCardProps,
              disabledCourseCardProps,
              allCourseCardProps,
            },
            columnIndex,
          ) =>
            overlayHighlighted ? (
              <div
                className="relative flex flex-col justify-start items-center min-w-80 h-full"
                key={columnIndex}
              >
                <div className="z-10 absolute overflow-y-scroll min-w-fit flex flex-col justify-center gap-2 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] max-h-full min-h-full py-2 ">
                  {enabledCourseCardProps.map((courseCardProp, index) => (
                    <CourseCard
                      {...courseCardProp}
                      key={index}
                      ref={(div) => {
                        if (div)
                          courseCardRefs.current[
                            courseCardProp.course.courseNumber
                          ] = div;
                        else
                          delete courseCardRefs.current[
                            courseCardProp.course.courseNumber
                          ];
                      }}
                    ></CourseCard>
                  ))}
                </div>
                <div
                  onClick={(e: SyntheticEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    handleCourseSelectedChange(null);
                  }}
                  onFocus={(e: SyntheticEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    handleCourseSelectedChange(null);
                  }}
                  className="z-0 absolute overflow-y-scroll min-w-fit flex flex-col gap-2 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] opacity-10 blur-sm"
                >
                  {disabledCourseCardProps.map((courseCardProp, index) => (
                    <CourseCard
                      {...courseCardProp}
                      key={index}
                      ref={(div) => {
                        if (div)
                          courseCardRefs.current[
                            courseCardProp.course.courseNumber
                          ] = div;
                        else
                          delete courseCardRefs.current[
                            courseCardProp.course.courseNumber
                          ];
                      }}
                    ></CourseCard>
                  ))}
                </div>
              </div>
            ) : (
              <div
                className="flex flex-col justify-start items-center"
                key={columnIndex}
              >
                <div className="overflow-y-scroll min-w-fit flex flex-col gap-2 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] py-2">
                  {allCourseCardProps.map((courseCardProp, index) => (
                    <CourseCard
                      {...courseCardProp}
                      key={index}
                      ref={(div) => {
                        if (div)
                          courseCardRefs.current[
                            courseCardProp.course.courseNumber
                          ] = div;
                        else
                          delete courseCardRefs.current[
                            courseCardProp.course.courseNumber
                          ];
                      }}
                    ></CourseCard>
                  ))}
                </div>
              </div>
            ),
        )}
      </div>
      {/* {selectedCourse ? (
        bezierConnectorProps.map((connectorProps, index) => (
          <BezierConnector
            className="stroke-background-deepest stroke-[0.3rem] animate-appear"
            {...connectorProps}
            key={index}
          ></BezierConnector>
        ))
      ) : (
        <></>
      )} */}
    </div>
  );
};
export default CourseMap;
