// src/utils/axiosInterceptor.ts

import axios, { AxiosResponse, AxiosHeaders } from "axios";
import { API_BASE_URL } from "../constants";

// Define interfaces for API responses
interface RefreshTokenResponse {
  access_token: string;
  refresh_token: string;
}

const axiosInterceptor = axios.create({
  baseURL: API_BASE_URL,
  // other configurations (e.g., headers, timeout)
});

const logout = () => {
  localStorage.clear();
  window.location.href = "/account/login";
};

const refreshToken = async (): Promise<string | null> => {
  try {
    const response = await axiosInterceptor.post<RefreshTokenResponse>(
      "/refresh_tokens/",
      {
        refresh_token: localStorage.getItem("refresh_token"),
      },
    );
    localStorage.setItem("access_token", response.data.access_token);
    localStorage.setItem("refresh_token", response.data.refresh_token);
    return response.data.access_token;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        logout();
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
    return null;
  }
};

// Request Interceptor to add Authorization header
axiosInterceptor.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      // Ensure headers are defined
      if (!config.headers) {
        config.headers = new AxiosHeaders();
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Response Interceptor to handle 401 errors and token refresh
axiosInterceptor.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: unknown) => {
    if (
      axios.isAxiosError(error) &&
      error.response &&
      error.response.status === 401 &&
      error.response.data.error === "Invalid access token"
    ) {
      const newToken = await refreshToken();
      if (newToken) {
        // Non-null assertion to assure TypeScript that config exists
        // error.config!.headers.Authorization = `Bearer ${newToken}`;
        // Retry the original request with the new token
        // return axiosInterceptor.request(error.config!);
        console.log("skipped non-null assertion :(");
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInterceptor;
