/**
 * SearchFilters.tsx
 * Component for company, industry, and location search filters
 * Updated to match Figma design
 */

import React, { FormEvent, ChangeEvent } from "react";
import { Search } from "lucide-react";

interface SearchFiltersProps {
  searchParams: {
    companyName: string;
    industry: string;
    location: string;
  };
  setSearchParams: React.Dispatch<
    React.SetStateAction<{
      companyName: string;
      industry: string;
      location: string;
    }>
  >;
}

const SearchFilters: React.FC<SearchFiltersProps> = ({
  searchParams,
  setSearchParams,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  const handleClear = (field: string) => {
    setSearchParams((prev) => ({ ...prev, [field]: "" }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full"
    >
      {/* Company Name Search */}
      <div className="relative">
        <input
          type="text"
          placeholder="Company Name"
          className="w-full px-4 py-3 rounded-md text-foreground dark:text-foreground-dark bg-background dark:bg-background-dark focus:outline-none focus:ring-1 focus:ring-[#0032DF]"
          name="companyName"
          value={searchParams.companyName}
          onChange={handleInputChange}
          style={{
            borderRadius: "4px",
            borderColor: "rgba(56, 58, 64, 1)",
          }}
        />
        <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center">
          {searchParams.companyName && (
            <button
              type="button"
              className="mr-2 text-foreground-dimmer dark:text-foreground-dark-dimmer hover:text-foreground-dimmest dark:text-foreground-dark-dimmest"
              onClick={() => handleClear("companyName")}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM12 10.4L10.4 12L8 9.6L5.6 12L4 10.4L6.4 8L4 5.6L5.6 4L8 6.4L10.4 4L12 5.6L9.6 8L12 10.4Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          )}
          <Search className="w-4 h-4 text-foreground-dimmer dark:text-foreground-dark-dimmer" />
        </div>
      </div>

      {/* Industry Search */}
      <div className="relative">
        <input
          type="text"
          placeholder="Industry"
          className="w-full px-4 py-3 rounded-md text-foreground dark:text-foreground-dark bg-background dark:bg-background-dark focus:outline-none focus:ring-1 focus:ring-[#0032DF]"
          name="industry"
          value={searchParams.industry}
          onChange={handleInputChange}
          style={{
            borderRadius: "4px",
            borderColor: "rgba(56, 58, 64, 1)",
          }}
        />
        <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center">
          {searchParams.industry && (
            <button
              type="button"
              className="mr-2 text-foreground-dimmer dark:text-foreground-dark-dimmer hover:text-foreground-dimmest dark:text-foreground-dark-dimmest"
              onClick={() => handleClear("industry")}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM12 10.4L10.4 12L8 9.6L5.6 12L4 10.4L6.4 8L4 5.6L5.6 4L8 6.4L10.4 4L12 5.6L9.6 8L12 10.4Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          )}
          <Search className="w-4 h-4 text-foreground-dimmer dark:text-foreground-dark-dimmer" />
        </div>
      </div>

      {/* Location Search */}
      <div className="relative">
        <input
          type="text"
          placeholder="Location"
          className="w-full px-4 py-3 rounded-md text-foreground dark:text-foreground-dark bg-background dark:bg-background-dark focus:outline-none focus:ring-1 focus:ring-[#0032DF]"
          name="location"
          value={searchParams.location}
          onChange={handleInputChange}
          style={{
            borderRadius: "4px",
            borderColor: "rgba(56, 58, 64, 1)",
          }}
        />
        <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center">
          {searchParams.location && (
            <button
              type="button"
              className="mr-2 text-foreground-dimmer dark:text-foreground-dark-dimmer "
              onClick={() => handleClear("location")}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM12 10.4L10.4 12L8 9.6L5.6 12L4 10.4L6.4 8L4 5.6L5.6 4L8 6.4L10.4 4L12 5.6L9.6 8L12 10.4Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          )}
          <Search className="w-4 h-4 text-foreground-dimmer dark:text-foreground-dark-dimmer" />
        </div>
      </div>
    </form>
  );
};

export default SearchFilters;
