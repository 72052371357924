import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Webcam from "react-webcam";
import axiosInterceptor from "../../../utils/axiosInterceptor";

interface CameraRecorderProps {
  isBackground?: boolean;
  isBehavioral?: boolean;
  isTechnical?: boolean;
  audio?: boolean;
  ids: number[];
  type: string;
  rounded?: boolean;
  moreClasses?: string;
}

export interface CameraRecorderHandle {
  stopAndDownload: () => void;
}

const CameraRecorder = forwardRef<CameraRecorderHandle, CameraRecorderProps>(
  (
    {
      isBackground,
      isBehavioral,
      isTechnical,
      audio = true,
      ids,
      type,
      rounded = false,
      moreClasses = "",
    },
    ref,
  ) => {
    const webcamRef = useRef<Webcam>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
    const [isWebcamReady, setIsWebcamReady] = useState(false);
    const [isRecordingStopped, setIsRecordingStopped] = useState(false);
    const [isFlashing, setIsFlashing] = useState(true);

    const handleDataAvailable = useCallback((event: BlobEvent) => {
      if (event.data.size > 0) {
        setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
      }
    }, []);

    const startRecording = useCallback(() => {
      setRecordedChunks([]);
      if (webcamRef.current?.video && webcamRef.current.video.srcObject) {
        const stream = webcamRef.current.video.srcObject as MediaStream;
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: "video/webm",
        });
        mediaRecorderRef.current.ondataavailable = handleDataAvailable;
        mediaRecorderRef.current.start();
        setCapturing(true);
      } else {
        console.log("Webcam stream not available or not ready");
      }
    }, [handleDataAvailable]);

    const handleStopCaptureClick = useCallback(() => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        setCapturing(false);
        setIsRecordingStopped(true);
      } else {
        console.log("No media recorder to stop");
      }
    }, []);

    const handleUpload = useCallback(() => {
      if (recordedChunks.length > 0) {
        const now = new Date();
        const timestamp = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
        const blob = new Blob(recordedChunks, { type: "video/webm" });
        const user_id = localStorage.getItem("user_id");
        const uploadURL = `file_upload/${user_id}/`;
        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
        const formData = new FormData();
        formData.append("root_directory", "video");
        formData.append("file", blob, `ids=${timestamp}.webm`);
        formData.append("promptType", type);

        axiosInterceptor
          .post(uploadURL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "X-CSRFToken": csrfToken,
            },
          })
          .then((uploadResponse) => {
            console.log("Upload successful", uploadResponse.data);
          })
          .catch((error) => {
            console.error("Error during file upload or processing: ", error);
          });
      } else {
        console.log("No recorded chunks to upload");
      }
    }, [recordedChunks, ids, type]);

    useEffect(() => {
      if (isRecordingStopped && recordedChunks.length > 0) {
        setIsRecordingStopped(false);
        handleUpload();
      }
    }, [isRecordingStopped, recordedChunks, handleUpload]);

    useEffect(() => {
      const checkWebcamReady = setInterval(() => {
        if (webcamRef.current?.video && webcamRef.current.video.srcObject) {
          setIsWebcamReady(true);
          clearInterval(checkWebcamReady);
        }
      }, 100);

      return () => {
        clearInterval(checkWebcamReady);
        mediaRecorderRef.current?.stop();
      };
    }, []);

    useEffect(() => {
      if (isWebcamReady) {
        startRecording();
      }
    }, [isWebcamReady, startRecording]);

    useImperativeHandle(
      ref,
      () => ({
        stopAndDownload: handleStopCaptureClick,
      }),
      [handleStopCaptureClick],
    );

    useEffect(() => {
      let interval: NodeJS.Timeout | undefined;
      if (capturing) {
        interval = setInterval(() => {
          setIsFlashing((prev) => !prev);
        }, 500);
      }
      return () => {
        if (interval) clearInterval(interval);
      };
    }, [capturing]);

    const classNames = [
      "camera-container",
      isBackground && "background",
      isBehavioral && "behavioral",
      isTechnical && "technical",
      moreClasses,
    ]
      .filter(Boolean)
      .join(" ");

    return (
      <div
        className={
          classNames + (rounded ? " !rounded-full !overflow-hidden" : "")
        }
      >
        <Webcam className="camera" audio={audio} ref={webcamRef} />
        {capturing && (
          <>
            <div
              className={`recording-indicator ${
                isFlashing ? "visible" : ""
              } ${isTechnical ? "!right-[4.65rem]" : ""}`}
            >
              <div className="recording-dot"></div>
            </div>
            {isTechnical && (
              <span className="absolute top-[2.2rem] text-foreground dark:text-foreground-dark right-[2.2rem] text-xs -translate-x-1/2 -translate-y-1/2">
                REC
              </span>
            )}
          </>
        )}
      </div>
    );
  },
);

export default CameraRecorder;
