import React from "react";

interface VideoCardProps {
  videoUrl?: string;
  title: string;
}

export const VideoCard: React.FC<VideoCardProps> = ({ videoUrl, title }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handleClick = () => {
    if (videoUrl) {
      setIsPlaying(true);
    } else {
      alert("Sorry, no video available for this segment!");
    }
  };

  if (!isPlaying) {
    return (
      <div
        onClick={handleClick}
        className="relative w-full h-[60vh] bg-background dark:bg-background-dark flex items-center justify-center mb-4 rounded cursor-pointer"
      >
        <div className="flex flex-col items-center">
          <p className="text-sm font-semibold text-foreground dark:text-foreground-dark">
            {title}
          </p>
          <p className="text-xs text-foreground-dimmest dark:text-foreground-dimmest">
            Click to play
          </p>
        </div>
        {/* Fake 'Play' icon in center */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-12 h-12 bg-black opacity-70 rounded-full flex items-center justify-center">
            <svg
              className="w-6 h-6 text-foreground dark:text-foreground-dark"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M6.3 4.16a1 1 0 0 1 1.66-.76l7.2 5.84a1 1 0 0 1 0 1.52l-7.2 5.84A1 1 0 0 1 6 16.84V4.16z" />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return videoUrl ? (
    <div className="w-full mb-4">
      <p className="text-sm font-semibold mb-1 text-foreground dark:text-foreground-dark">
        {title}
      </p>
      <video
        src={videoUrl}
        autoPlay
        controls
        className="w-full h-48 bg-black rounded"
      />
    </div>
  ) : (
    <div className="w-full h-48 bg-background-root dark:bg-background-dark-root flex items-center justify-center mb-4 rounded">
      <p className="text-sm text-foreground-dimmest dark:text-foreground-dimmest">
        No Video Available
      </p>
    </div>
  );
};
