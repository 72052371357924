// Question mapping (text to number)
export const questionMapping: Record<string, number> = {
  "I am the life of the party.": 1,
  "I feel little concern for others.": 2,
  "I am always prepared.": 3,
  "I get stressed out easily.": 4,
  "I have a rich vocabulary.": 5,
  "I don't talk a lot.": 6,
  "I am interested in people.": 7,
  "I leave my belongings around.": 8,
  "I am relaxed most of the time.": 9,
  "I have difficulty understanding abstract ideas.": 10,
  "I feel comfortable around people.": 11,
  "I insult people.": 12,
  "I pay attention to details.": 13,
  "I worry about things.": 14,
  "I have a vivid imagination.": 15,
  "I keep in the background.": 16,
  "I sympathize with others' feelings.": 17,
  "I make a mess of things.": 18,
  "I seldom feel blue.": 19,
  "I am not interested in abstract ideas.": 20,
  "I start conversations.": 21,
  "I am not interested in other people's problems.": 22,
  "I get chores done right away.": 23,
  "I am easily disturbed.": 24,
  "I have excellent ideas.": 25,
  "I have little to say.": 26,
  "I have a soft heart.": 27,
  "I often forget to put things back in their proper place.": 28,
  "I get upset easily.": 29,
  "I do not have a good imagination.": 30,
  "I talk to a lot of different people at parties.": 31,
  "I am not really interested in others.": 32,
  "I like order.": 33,
  "I change my mood a lot.": 34,
  "I am quick to understand things.": 35,
  "I don't like to draw attention to myself.": 36,
  "I take time out for others.": 37,
  "I shirk my duties.": 38,
  "I have frequent mood swings.": 39,
  "I use difficult words.": 40,
  "I don't mind being the center of attention.": 41,
  "I feel others' emotions.": 42,
  "I follow a schedule.": 43,
  "I get irritated easily.": 44,
  "I spend time reflecting on things.": 45,
  "I am quiet around strangers.": 46,
  "I make people feel at ease.": 47,
  "I am exacting in my work.": 48,
  "I often feel blue.": 49,
  "I am full of ideas.": 50,
};
