import {
  // Technical & Skills Icons
  Cpu, // AI/CS/Software
  Wrench, // Hardware/Tools
  ChartLine, // Finance/Economics
  Microscope, // Science/Bio/Chem

  // Background & Career Icons
  Building2, // Company/Industry
  BadgeCheck, // Job/Experience
  Trophy, // Skills/Achievements

  // Behavioral Icons
  Users, // Management/Team
  Brain, // Intelligence/Cognitive
  Shield, // Security/Resilience
  Heart, // Culture/Values

  // Document Icons
  CircuitBoard, // Technical test
  Layers, // Background test
  Smile, // Behavioral test
} from "lucide-react";

// Icon mapping for TestCenter components
export const testCenterIcons = {
  technical: {
    group1: Cpu,
    group2: Wrench,
    group3: ChartLine,
    group4: Microscope,
  },
  behavioral: {
    management: Users,
    expression: Brain,
    resilience: Shield,
    culture: Heart,
  },
  background: {
    industries: Building2,
    jobTitles: BadgeCheck,
    skills: Trophy,
  },
  // New section for test option icons
  testOptions: {
    technical: CircuitBoard,
    background: Layers,
    behavioral: Smile,
  },
};
