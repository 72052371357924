import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor";

// Logo images
import LogoIcon from "../../assets/img/home/logo-icon.png";

// Icon images - normal state
import ProfileIcon from "../../assets/icons/person-icon.png";
import TestsIcon from "../../assets/icons/clipboard-icon.png";
import CoursesIcon from "../../assets/icons/courses-icon.png";
import JobsIcon from "../../assets/icons/search-icon.png";
import MessagesIcon from "../../assets/icons/message-icon.png";
import NotificationsIcon from "../../assets/icons/bell-icon.png";
import InsightsIcon from "../../assets/icons/chart-icon.png";
import ManagementIcon from "../../assets/icons/gear-icon.png";
import LogoutIcon from "../../assets/icons/logout-icon.png";
import UiLibraryIcon from "../../assets/icons/brush-icon.png";

// Icon images - active state
import ProfileIconActive from "../../assets/icons/person-icon-active.png";
import TestsIconActive from "../../assets/icons/clipboard-icon-active.png";
import CoursesIconActive from "../../assets/icons/courses-icon-active.png";
import JobsIconActive from "../../assets/icons/search-icon-active.png";
import MessagesIconActive from "../../assets/icons/message-icon-active.png";
import NotificationsIconActive from "../../assets/icons/bell-icon-active.png";
import InsightsIconActive from "../../assets/icons/chart-icon-active.png";
import ManagementIconActive from "../../assets/icons/gear-icon-active.png";
import UiLibraryIconActive from "../../assets/icons/brush-icon-active.png";

interface HeaderProps {
  isUserLoggedIn: boolean;
}

interface Page {
  normalIcon: string;
  activeIcon: string;
  text: string;
  link: string;
  adminOnly?: boolean;
  action?: () => void;
  badge?: number | null;
}

const Header: React.FC<HeaderProps> = ({ isUserLoggedIn }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [notificationCount] = React.useState<number | null>(10); // Example notification count

  // Redirect to Profile page if at root path after login
  useEffect(() => {
    if (isUserLoggedIn && location.pathname === "/") {
      navigate("/profile");
    }
  }, [isUserLoggedIn, location.pathname, navigate]);

  async function logoutUser() {
    try {
      await axiosInterceptor.post(`/logout/`, {
        refresh_token: localStorage.getItem("refresh_token"),
      });
    } catch (error: unknown) {
      console.error(error);
    }
    localStorage.clear();
    window.location.reload();
  }

  const loggedInPages: Page[] = [
    {
      normalIcon: ProfileIcon,
      activeIcon: ProfileIconActive,
      text: "Profile",
      link: "/profile",
    },
    {
      normalIcon: TestsIcon,
      activeIcon: TestsIconActive,
      text: "Tests",
      link: "/test-center/home",
    },
    {
      normalIcon: CoursesIcon,
      activeIcon: CoursesIconActive,
      text: "Courses",
      link: "/courses",
    },
    {
      normalIcon: JobsIcon,
      activeIcon: JobsIconActive,
      text: "Jobs",
      link: "/jobs",
    },
    {
      normalIcon: MessagesIcon,
      activeIcon: MessagesIconActive,
      text: "Messages",
      link: "/chat",
    },
    // {
    //   normalIcon: NotificationsIcon,
    //   activeIcon: NotificationsIconActive,
    //   text: "Notifications",
    //   link: "/notifications",
    //   badge: notificationCount
    // },
    // {
    //   normalIcon: InsightsIcon,
    //   activeIcon: InsightsIconActive,
    //   text: "Insights",
    //   link: "/insights",
    //   adminOnly: true,
    // },
    {
      normalIcon: ManagementIcon,
      activeIcon: ManagementIconActive,
      text: "Manage",
      link: "/management",
      adminOnly: true,
    },
  ];

  if (process.env.NODE_ENV === "development") {
    loggedInPages.push({
      normalIcon: UiLibraryIcon,
      activeIcon: UiLibraryIconActive,
      text: "Library",
      link: "/ui-library",
    });
  }

  const accountPages: Page[] = [
    {
      normalIcon: LogoutIcon,
      activeIcon: LogoutIcon,
      text: "Log Out",
      link: "#",
      action: logoutUser,
    },
  ];

  // Helper function to determine if a page is active
  const isPageActive = (pageLink: string) => {
    // Default to Profile when on home route
    if (location.pathname === "/" && pageLink === "/profile") {
      return true;
    }
    // Otherwise check if the current path includes the page link
    return location.pathname.includes(pageLink);
  };

  return (
    <>
      {isUserLoggedIn ? (
        <Nav
          id="sidebar"
          className="flex flex-col z-20 fixed left-0 top-[0px] bg-[#0e1332] w-20 py-2 h-[calc(100vh)]"
        >
          <div className="flex items-center justify-center w-full px-2">
            <div className="w-10 h-10 mx-auto mt-2">
              <img
                src={LogoIcon}
                alt="AlgoLink Icon"
                className="w-full h-full object-contain"
              />
            </div>
          </div>

          <Nav className="flex flex-col w-full mt-6 items-center">
            {loggedInPages
              .filter(
                (tab) =>
                  !tab.adminOnly ||
                  localStorage.getItem("is_algolink_admin") === "true",
              )
              .map((page, index) => {
                const isActive = isPageActive(page.link);
                return (
                  <Nav.Link
                    key={index}
                    as={Link}
                    to={page.link}
                    className={`
                      flex flex-col items-center rounded-lg z-10 p-2 mb-2
                      ${isActive ? "bg-accent-primary w-16 mx-auto" : "w-16 bg-[#0e1332] hover:bg-accent-primary/50"}
                    `}
                    onClick={page.action}
                  >
                    <span className="flex items-center justify-center w-6 h-6 mb-1">
                      <img
                        src={isActive ? page.activeIcon : page.normalIcon}
                        alt={page.text}
                        className="w-6 h-6 object-contain"
                      />
                      {/* Small badge dot if needed */}
                      {page.badge && (
                        <div className="h-2 w-2 bg-red-500 rounded-full absolute top-0 right-0"></div>
                      )}
                    </span>
                    <span className="text-xs font-medium text-background-dimmest">
                      {page.text}
                    </span>
                  </Nav.Link>
                );
              })}
          </Nav>

          <div className="flex-grow" />

          <Nav className="flex flex-col mb-2 w-full">
            {accountPages.map((page, index) => (
              <Nav.Link
                key={index}
                as={Link}
                to={page.link}
                className="flex flex-col items-center p-2 w-full bg-[#0e1332] hover:bg-accent-primary/50 rounded-lg"
                onClick={page.action}
              >
                <span className="flex items-center justify-center w-6 h-6 mb-1">
                  <img
                    src={page.normalIcon}
                    alt={page.text}
                    className="w-6 h-6 object-contain"
                  />
                </span>
                <span className="text-xs font-medium text-background">
                  {page.text}
                </span>
              </Nav.Link>
            ))}
          </Nav>
        </Nav>
      ) : (
        <Nav
          id="navbar"
          className="flex flex-col gap-y-2 lg:gap-y-0 lg:flex-row justify-center lg:justify-between items-center py-0 z-0"
        ></Nav>
      )}
    </>
  );
};

export default Header;
