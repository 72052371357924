// Define the types for the environment variables and URLs
const isProduction: boolean = process.env.NODE_ENV === "production";

export const API_BASE_URL: string = isProduction
  ? "https://e4eayj4wkh.execute-api.us-east-1.amazonaws.com/dev"
  : "http://127.0.0.1:8000";

export const APP_BASE_URLS: string[] = isProduction
  ? [
      "https://www.algolink.net",
      "https://algolink.net",
      "https://algolink.net.s3-website-us-east-1.amazonaws.com",
    ]
  : ["http://localhost:3000"];
