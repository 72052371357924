import { HTMLAttributes, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLoaderData } from "react-router-dom";
import "../../assets/courseAssets/main.css";
import "../../assets/courseAssets/main.scss";
import SEOWrapper from "../../components/SEOWrapper";
import {
  Course,
  NewCourseContent,
  CourseProgress,
  CourseStatusChipProps,
} from "./types";
import { ProgressIndicatorProps } from "../../components/ProgressIndicator";
import {
  fetchNewCompletedLectures,
  newComputeLecturesProgress,
  fetchAllNewCourses,
  fetchNewCoursesProgress,
  fetchUserId,
  fetchCourseRecommendations,
  isCourseMissingPrerequisites,
  toggleLectureCompletion,
  newExtractLectureNameFromFilePath,
} from "./courseUtils";
import ProgressIndicator from "../../components/ProgressIndicator";
import CourseStatusChip from "./CourseStatusChip";
import FileURLViewer from "../../components/FileUrlViewer";

export default function NewCourseOverview() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = useLoaderData() as NewCourseContent;
  const [completedLectures, setCompletedLectures] = useState<string[]>([]);
  const [allCourses, setAllCourses] = useState<Course[]>([]);
  const [allCourseProgress, setAllCoursesProgress] = useState<CourseProgress[]>(
    [],
  );
  const [recommendedCourses, setRecommendedCourses] = useState<Course[]>([]);

  useEffect(() => {
    const updateLectures = async () => {
      setCompletedLectures(await fetchNewCompletedLectures(content.id));
    };
    const updateCourses = async () => {
      const userId = fetchUserId();
      const courses = await fetchAllNewCourses();
      const coursesProgress = await fetchNewCoursesProgress(userId);
      const recommended = await fetchCourseRecommendations(
        courses,
        coursesProgress,
      ); // Don't think this needs a new version for new curriculum, but could be wrong
      setAllCourses(await courses);
      setAllCoursesProgress(await coursesProgress);
      setRecommendedCourses(recommended);
    };
    updateLectures();
    updateCourses();
  }, [content.id]);

  const currentCourseStatusChipProps = useMemo<CourseStatusChipProps>(() => {
    const currentCourse = allCourses.find((c) => c.id === content.id);
    if (!currentCourse) return { courseStatusChipProps: null };

    const selectedCourseProgress = allCourseProgress.find(
      (cp) => cp.courseNumber === currentCourse.courseNumber,
    );
    if (!selectedCourseProgress) return { courseStatusChipProps: null };

    const recommended = recommendedCourses.some(
      (rc) => rc.courseNumber === currentCourse.courseNumber,
    );
    const missingPrereqs = isCourseMissingPrerequisites(
      currentCourse,
      allCourseProgress,
    );

    return {
      courseStatusChipProps: {
        courseProgress: selectedCourseProgress.progress,
        isRecommended: recommended,
        needsMorePrerequisites: missingPrereqs,
      },
    };
  }, [allCourses, allCourseProgress, recommendedCourses, content.id]);

  const progressItemProps = useMemo<
    (ProgressIndicatorProps & HTMLAttributes<HTMLDivElement>)[][]
  >(() => {
    const handleToggleLectureCompletion = async (lectureId: string) => {
      const idx = completedLectures.indexOf(lectureId);
      if (idx >= 0) {
        completedLectures.splice(idx, 1);
        setCompletedLectures([...completedLectures]);
      } else {
        setCompletedLectures([...completedLectures, lectureId]);
      }
      await toggleLectureCompletion(content.id.replaceAll(" ", "-"), lectureId);
    };

    const handleSetLectureGroupCompleted = async (
      contentType: string,
      completed: boolean,
    ) => {
      const targetGroup = content.lectureContent;
      if (!targetGroup) return;
      if (completed) {
        // Mark them incomplete
        for (const lectureId of targetGroup.contentList) {
          const idx = completedLectures.indexOf(lectureId);
          if (idx >= 0) completedLectures.splice(idx, 1);
          await toggleLectureCompletion(
            content.id.replaceAll(" ", "-"),
            lectureId,
          );
        }
        setCompletedLectures([...completedLectures]);
      } else {
        // Mark them completed
        for (const lectureId of targetGroup.contentList) {
          if (!completedLectures.includes(lectureId)) {
            completedLectures.push(lectureId);
            await toggleLectureCompletion(
              content.id.replaceAll(" ", "-"),
              lectureId,
            );
          }
        }
        setCompletedLectures([...completedLectures]);
      }
    };

    let accumulatedLength = 0;
    const lecturesProgress = newComputeLecturesProgress(
      content,
      completedLectures,
    );
    const groups: (ProgressIndicatorProps &
      HTMLAttributes<HTMLDivElement>)[][] = [];

    const group = content.lectureContent;
    if (group) {
      const groupProgress =
        lecturesProgress.lectureType === group.contentType
          ? lecturesProgress
          : null;
      const startIndicator: ProgressIndicatorProps &
        HTMLAttributes<HTMLDivElement> = {
        progress: groupProgress ? groupProgress.progress : 0,
        showConnectionLine: group.contentList.length > 0,
        handleClick: () => {
          const isCurrentlyComplete = groupProgress
            ? groupProgress.progress >= 1
            : false;
          handleSetLectureGroupCompleted(
            group.contentType,
            isCurrentlyComplete,
          );
        },
        children: [
          <div key="groupTitle" className="flex flex-col mb-4">
            <p className="text-2xl text-foreground dark:text-foreground-dark">
              {group.contentType}
            </p>
            {groupProgress ? (
              <p className="text-foreground-dimmest dark:text-foreground-dark-dimmest ">
                {Math.floor(
                  groupProgress.progress * groupProgress.totalLectures,
                )}{" "}
                / {groupProgress.totalLectures} Completed
              </p>
            ) : (
              <p className="text-foreground-dimmest dark:text-foreground-dimmest">
                0 / 0 Completed
              </p>
            )}
          </div>,
        ],
      };

      group.contentList.sort((a, b) => {
        const numberA = parseInt(a.match(/\d+/)?.[0] ?? "0", 10);
        const numberB = parseInt(b.match(/\d+/)?.[0] ?? "0", 10);
        return numberA - numberB;
      });

      const currentPath = window.location.pathname.slice(0, -1);

      const items: Array<
        ProgressIndicatorProps & HTMLAttributes<HTMLDivElement>
      > = [startIndicator];
      group.contentList.forEach((lectureId, idx) => {
        const isDone = completedLectures.includes(lectureId);
        const lectureName = newExtractLectureNameFromFilePath(lectureId);
        items.push({
          progress: isDone ? 1 : 0,
          showConnectionLine: idx !== group.contentList.length - 1,
          handleClick: () => handleToggleLectureCompletion(lectureId),
          children: (
            <div className="mb-8">
              <a
                href={`${currentPath}${accumulatedLength + 1}`}
                className="no-underline hover:underline text-foreground dark:text-foreground-dark "
              >
                <p className="text-lg">{`${lectureName}`}</p>
              </a>
            </div>
          ),
        });
        accumulatedLength++;
      });
      groups.push(items);
    }
    return groups;
  }, [completedLectures, content]);

  const title = `${content.title} - PDF Lecture - AlgoLink`;
  const description =
    "Access PDF lecture materials for your AlgoLink course. Enhance your learning with supplementary resources and discussion.";
  const s3BaseUrl =
    "https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/";
  const url = `${s3BaseUrl}${content.lectureFilePath}`;
  const fileName = newExtractLectureNameFromFilePath(content.lectureFilePath);

  return (
    <SEOWrapper
      title={`${content.title} - AlgoLink Course Overview`}
      description={`Explore the ${content.title} course by AlgoLink.`}
    >
      <div className="w-full min-h-screen bg-background-root dark:bg-background-dark-root text-foreground dark:text-foreground-dark flex items-center justify-center px-4">
        <div className="flex flex-col w-full max-w-[70rem] pt-6 gap-10">
          <div className="flex flex-col gap-4">
            <h3 className="font-semibold text-2xl">{content.title}</h3>
            <CourseStatusChip {...currentCourseStatusChipProps} />
          </div>

          <div className="flex flex-col-reverse md:flex-row gap-8 mb-8">
            {/* Left side: progress indicators */}
            <div className="md:w-1/2 overflow-y-auto pr-4">
              {progressItemProps.map((group, groupIdx) => (
                <div className="flex flex-col mb-8" key={groupIdx}>
                  {group.map((entry, idx) => (
                    <ProgressIndicator key={idx} {...entry} />
                  ))}
                </div>
              ))}
            </div>

            {/* Right side: content outlet  UPDATE THIS WITH LECTURE DISPLAY*/}
            <div className="md:w-1/2 flex flex-col gap-6">
              <div className="flex flex-col w-full gap-6">
                <h2 className="text-foreground dark:text-foreground-dark  font-normal text-2xl">
                  {fileName}
                </h2>
                <div className="tab overflow-auto w-full aspect-video bg-foreground">
                  <FileURLViewer
                    url={url}
                    fileType={content.type}
                  ></FileURLViewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SEOWrapper>
  );
}
