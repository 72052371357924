import React, { useState } from "react";
import Modal from "../../../components/Modal";
import { Trash2, Loader2, Plus } from "lucide-react";
// import { profileIcons } from "../../../icons";
import { useNavigate } from "react-router-dom";
import { CareerCardProps } from "../interfaces";

interface ExtendedCareerCardProps extends CareerCardProps {
  directEditPath?: string;
  isLoading?: boolean;
  onDeleteModalClose?: () => Promise<void>;
  onEditModalClose?: () => Promise<void>;
}

const CareerCard: React.FC<ExtendedCareerCardProps> = ({
  title,
  children,
  showAddButton = true,
  showDeleteButton = false,
  showEditAsModal = true,
  showEditButton = true,
  modalTitle = title,
  deleteModalTitle = "Delete",
  directEditPath,
  isLoading = false,
  onDeleteModalClose,
  onEditModalClose,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const handleDeleteModalClose = async () => {
    setShowDeleteModal(false);
    if (onDeleteModalClose) {
      await onDeleteModalClose();
    }
  };

  const handleEditModalClose = async () => {
    setShowEditModal(false);
    if (onEditModalClose) {
      await onEditModalClose();
    }
  };

  // const getIcon = () => {
  //   const iconProps = { className: "w-5 h-5 text-accent-primary" };
  //   switch (title) {
  //     case "Education":
  //       return <profileIcons.career.education {...iconProps} />;
  //     case "Experience":
  //       return <profileIcons.career.experience {...iconProps} />;
  //     case "External Courses":
  //       return <profileIcons.career.courses {...iconProps} />;
  //     case "Skills":
  //       return <profileIcons.career.skills {...iconProps} />;
  //     case "Resume/CV":
  //       return <profileIcons.career.resume {...iconProps} />;
  //     case "Transcript":
  //       return <profileIcons.career.transcript {...iconProps} />;
  //     case "Job Preference":
  //     case "Job Preferences":
  //       return <profileIcons.career.jobPreferences {...iconProps} />;
  //     case "Personality":
  //       return <profileIcons.career.personality {...iconProps} />;
  //     default:
  //       return <profileIcons.career.tests {...iconProps} />;
  //   }
  // };

  const handleEditClick = () => {
    if (isLoading) return;
    if (directEditPath) {
      navigate(directEditPath);
    } else if (showEditAsModal) {
      setShowEditModal(true);
    }
  };

  return (
    <div
      className="bg-background dark:bg-background-dark rounded-lg overflow-hidden border-background-dimmest dark:border-background-dark-dimmest"
      style={{
        borderWidth: "0.5px",
        borderStyle: "solid",
      }}
    >
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <h3 className="text-lg font-medium text-foreground-dimmer dark:text-foreground-dark-dimmer">
              {title}
            </h3>
          </div>
          <div className="flex items-center gap-2">
            {showAddButton && (
              <button
                onClick={() => setShowAddModal(true)}
                disabled={isLoading}
                className="text-foreground dark:text-foreground-dark hover:text-foreground-dimmer  disabled:opacity-50"
              >
                <Plus size={20} />
              </button>
            )}
            {showDeleteButton && (
              <button
                onClick={() => !isLoading && setShowDeleteModal(true)}
                className="text-foreground dark:text-foreground-dark hover:text-[#FF0000] disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader2 size={20} className="animate-spin" />
                ) : (
                  <Trash2 size={20} />
                )}
              </button>
            )}
            {showEditButton && (
              <button
                onClick={handleEditClick}
                className="text-foreground dark:text-foreground-dark hover:text-foreground-dimmer dark:hover:text-foreground-dimmer disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader2 size={20} className="animate-spin" />
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" />
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>

        <div>{children[0]}</div>

        {showAddButton && (
          <Modal
            title={title}
            show={showAddModal}
            setShow={setShowAddModal}
            dark
          >
            {children[1]}
          </Modal>
        )}

        {showDeleteButton && (
          <Modal
            title={deleteModalTitle}
            show={showDeleteModal}
            setShow={handleDeleteModalClose}
            dark
          >
            {children[2]}
          </Modal>
        )}

        {!directEditPath && showEditAsModal && (
          <Modal
            title={modalTitle}
            show={showEditModal}
            setShow={handleEditModalClose}
            dark
          >
            {children[1]}
          </Modal>
        )}
      </div>
    </div>
  );
};

export default CareerCard;
