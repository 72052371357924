/**
 * MergedJobsPage.tsx
 * Main component that implements the two-column layout while preserving the original data flow.
 * Updated with design system adjustments
 */

import React, { useState, useEffect, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor";

import {
  Company,
  Job,
  ConsolidatedJob,
  ConsolidatedCategory,
  CONSOLIDATED_CATEGORY_ORDER,
} from "./interfaces";
import SearchFilters from "./components/SearchFilters";
import CategoryTabs from "./components/CategoryTabs";
import JobsList from "./components/JobsList";
import JobDetail from "./components/JobDetail";
import { mapToConsolidatedJob } from "./utils/jobUtils";

const MergedJobsPage: React.FC = () => {
  // State for companies and jobs
  const [companies, setCompanies] = useState<Company[]>([]);
  const [allJobs, setAllJobs] = useState<Job[]>([]);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [loadingJobs, setLoadingJobs] = useState<boolean>(false);

  // Selected job and category
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
  const [activeCategory, setActiveCategory] = useState<ConsolidatedCategory>(
    CONSOLIDATED_CATEGORY_ORDER[0],
  );

  // Search parameters
  const [searchParams, setSearchParams] = useState({
    companyName: "",
    industry: "",
    location: "",
  });

  // Fetch companies and initial jobs
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitial(true);
        const userId = localStorage.getItem("user_id");
        if (!userId) {
          console.error("No user_id found in localStorage");
          return;
        }

        // Fetch companies first
        const companiesResponse = await axiosInterceptor.get<Company[]>(
          "/get_all_companies/",
        );
        const fetchedCompanies = companiesResponse.data;
        setCompanies(fetchedCompanies);

        // Load jobs for all companies
        setLoadingJobs(true);
        const allJobsPromises = fetchedCompanies.map((company) =>
          axiosInterceptor.get<Job[]>(
            `/get_active_jobs_for_company_with_user_data/${company.id}/${userId}/`,
          ),
        );

        const jobResponses = await Promise.all(allJobsPromises);
        const jobsData = jobResponses.flatMap((response) => response.data);

        // Add company details to each job for filtering
        const jobsWithCompanyDetails = jobsData.map((job) => {
          const company = fetchedCompanies.find((c) => c.id === job.company);

          return {
            ...job,
            company_details: company
              ? {
                  company_name: company.company_name || "AlgoLink",
                  industry: company.industry,
                  company_description: company.company_description,
                  company_size: company.company_size,
                }
              : {
                  company_name: "AlgoLink",
                  industry: "",
                  company_description: "",
                  company_size: "",
                },
          };
        });

        setAllJobs(jobsWithCompanyDetails);

        // Set the first job as selected if available
        if (jobsWithCompanyDetails.length > 0) {
          setSelectedJobId(jobsWithCompanyDetails[0].id);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoadingInitial(false);
        setLoadingJobs(false);
      }
    };

    fetchInitialData();
  }, []);

  // Process jobs with consolidated categories
  const consolidatedJobs = useMemo(() => {
    return allJobs.map(mapToConsolidatedJob);
  }, [allJobs]);

  // Apply filters (search and category)
  const filteredJobs = useMemo(() => {
    // First apply search filters
    const searchFiltered = consolidatedJobs.filter((job: ConsolidatedJob) => {
      const { companyName, industry, location } = searchParams;

      const matchesCompanyName =
        !companyName ||
        (job.company_details?.company_name || "")
          .toLowerCase()
          .includes(companyName.toLowerCase());

      const matchesIndustry =
        !industry ||
        (job.company_details?.industry || "")
          .toLowerCase()
          .includes(industry.toLowerCase());

      const matchesLocation =
        !location ||
        (job.city || "").toLowerCase().includes(location.toLowerCase());

      return matchesCompanyName && matchesIndustry && matchesLocation;
    });

    // Then filter by active category
    const categoryFiltered = searchFiltered.filter(
      (job: ConsolidatedJob) => job.consolidatedCategory === activeCategory,
    );

    // Sort with starred jobs at the top
    return categoryFiltered.sort((a: ConsolidatedJob, b: ConsolidatedJob) => {
      if (a.hasStar !== b.hasStar) {
        return a.hasStar ? -1 : 1;
      }
      return 0; // Maintain original order for jobs with same star status
    });
  }, [consolidatedJobs, searchParams, activeCategory]);

  // Get the currently selected job
  const selectedJob = useMemo(() => {
    return (
      consolidatedJobs.find(
        (job: ConsolidatedJob) => job.id === selectedJobId,
      ) || null
    );
  }, [consolidatedJobs, selectedJobId]);

  // Handle job rating
  const handleRateJob = async (jobId: number, ratingValue: number) => {
    try {
      const userId = localStorage.getItem("user_id");
      if (!userId) {
        console.error("No user_id found in localStorage");
        return;
      }

      // Update on server using the original API endpoint
      await axiosInterceptor.post("/create_or_update_rating/", {
        user_id: userId,
        job_id: jobId,
        rating: ratingValue,
        rating_type: "user_rating_job",
      });

      // Update local state
      setAllJobs((prevJobs) =>
        prevJobs.map((job) =>
          job.id === jobId ? { ...job, user_rating_of_job: ratingValue } : job,
        ),
      );
    } catch (error) {
      console.error("Error rating job:", error);
    }
  };

  // Count jobs per category for the tabs
  const categoryCounts = useMemo(() => {
    const counts: Record<ConsolidatedCategory, number> = {
      Recommended: 0,
      Interested: 0,
      "Not Interested": 0,
      Matches: 0,
      Referral: 0,
    };

    consolidatedJobs.forEach((job: ConsolidatedJob) => {
      counts[job.consolidatedCategory]++;
    });

    return counts;
  }, [consolidatedJobs]);

  if (loadingInitial) {
    return (
      <div className="flex items-center justify-center h-screen bg-background-root dark:bg-background-dark-root">
        <Spinner animation="border" variant="primary" />
        <span className="ml-2 text-base text-foreground-dimmest dark:text-foreground-dark-dimmest">
          Loading jobs board...
        </span>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-background-root dark:bg-background-dark-root">
      {/* Search Filters */}
      <div className="px-4 py-4 bg-background-root dark:bg-background-dark-root">
        <SearchFilters
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </div>

      {/* Category Tabs */}
      <div className="bg-background-root dark:bg-background-dark-root">
        <CategoryTabs
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          categoryCounts={categoryCounts}
        />
      </div>

      {/* Main content: 2-column layout */}
      <div className="flex flex-1 overflow-hidden">
        {/* Left column: Job listings */}
        <div
          className="overflow-y-auto bg-background-root dark:bg-background-dark-root border-r border-background-dimmest dark:border-background-dark-dimmest"
          style={{ width: "calc(415px + 32px)" }}
        >
          {loadingJobs ? (
            <div className="p-6 text-center">
              <Spinner animation="border" variant="primary" size="sm" />
              <span className="ml-2 text-base text-foreground-dimmest dark:text-foreground-dark-dimmest">
                Loading jobs...
              </span>
            </div>
          ) : (
            <JobsList
              jobs={filteredJobs}
              selectedJobId={selectedJobId}
              onSelectJob={setSelectedJobId}
            />
          )}
        </div>

        {/* Right column: Job details */}
        <div className="flex-1 overflow-y-auto bg-background-root dark:bg-background-dark-root">
          {selectedJob ? (
            <JobDetail job={selectedJob} onRateJob={handleRateJob} />
          ) : (
            <div className="p-6 text-center text-foreground-dimmest dark:text-foreground-dark-dimmest bg-background dark:bg-background-dark">
              Select a job to view details
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MergedJobsPage;
