/**
 * RatingDropdown.tsx
 * Component for rating jobs with proper disabling for locked categories
 * Updated to match Figma design + dark mode styling
 * - Uses #003DF5 for the default button color
 * - Dark (#1C1D21) for the dropdown menu
 */

import React from "react";
import { Dropdown } from "react-bootstrap";

interface RatingDropdownProps {
  jobId: number;
  currentRating: number;
  onRateJob: (jobId: number, rating: number) => Promise<void>;
  disabled: boolean;
}

const RatingDropdown: React.FC<RatingDropdownProps> = ({
  jobId,
  currentRating,
  onRateJob,
  disabled,
}) => {
  // Map numeric rating to descriptive label
  const getLabelForRating = (rating: number) => {
    switch (rating) {
      case 1:
        return "Not Interested";
      case 3:
        return "Interested";
      case 5:
        return "Very Interested";
      default:
        return "--Rate--";
    }
  };

  const label = getLabelForRating(currentRating);

  return (
    <Dropdown>
      {/* Toggle button (blue) */}
      <Dropdown.Toggle
        id={`rating-dropdown-${jobId}`}
        disabled={disabled}
        style={{
          backgroundColor: "#003DF5", // Bright blue
          borderColor: "#003DF5",
          color: "#FFFFFF",
          borderRadius: "4px",
        }}
        className="hover:bg-[#3175E3]"
      >
        {label}
      </Dropdown.Toggle>

      {/* Menu (dark) */}
      <Dropdown.Menu
        style={{
          backgroundColor: "#1C1D21",
          border: "0.5px solid rgba(56, 58, 64, 1)",
          borderRadius: "4px",
        }}
      >
        {/* "Not Interested" */}
        <Dropdown.Item
          onClick={() => onRateJob(jobId, 1)}
          style={{ backgroundColor: "#1C1D21", color: "#FFFFFF" }}
          className="hover:bg-[#36383F]"
        >
          Not Interested
        </Dropdown.Item>

        {/* "Interested" */}
        <Dropdown.Item
          onClick={() => onRateJob(jobId, 3)}
          style={{ backgroundColor: "#1C1D21", color: "#FFFFFF" }}
          className="hover:bg-[#36383F]"
        >
          Interested
        </Dropdown.Item>

        {/* "Very Interested" */}
        <Dropdown.Item
          onClick={() => onRateJob(jobId, 5)}
          style={{ backgroundColor: "#1C1D21", color: "#FFFFFF" }}
          className="hover:bg-[#36383F]"
        >
          Very Interested
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RatingDropdown;
