import { HTMLAttributes, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLoaderData } from "react-router-dom";
import "../../assets/courseAssets/main.css";
import "../../assets/courseAssets/main.scss";
import SEOWrapper from "../../components/SEOWrapper";
import {
  Course,
  CourseContent,
  CourseProgress,
  CourseStatusChipProps,
} from "./types";
import { ProgressIndicatorProps } from "../../components/ProgressIndicator";
import {
  fetchCompletedLectures,
  generateMITCitation,
  computeLecturesProgress,
  fetchAllCourses,
  fetchCoursesProgress,
  fetchUserId,
  fetchCourseRecommendations,
  isCourseMissingPrerequisites,
  toggleLectureCompletion,
} from "./courseUtils";
import ProgressIndicator from "../../components/ProgressIndicator";
import CourseStatusChip from "./CourseStatusChip";

export default function CourseOverview() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = useLoaderData() as CourseContent;
  const [completedLectures, setCompletedLectures] = useState<string[]>([]);
  const [allCourses, setAllCourses] = useState<Course[]>([]);
  const [allCourseProgress, setAllCoursesProgress] = useState<CourseProgress[]>(
    [],
  );
  const [recommendedCourses, setRecommendedCourses] = useState<Course[]>([]);

  useEffect(() => {
    const updateLectures = async () => {
      setCompletedLectures(await fetchCompletedLectures(content.id));
    };
    const updateCourses = async () => {
      const userId = fetchUserId();
      const courses = await fetchAllCourses();
      const coursesProgress = await fetchCoursesProgress(userId);
      const recommended = await fetchCourseRecommendations(
        courses,
        coursesProgress,
      );
      setAllCourses(await courses);
      setAllCoursesProgress(await coursesProgress);
      setRecommendedCourses(recommended);
    };
    updateLectures();
    updateCourses();
  }, [content.id]);

  const currentCourseStatusChipProps = useMemo<CourseStatusChipProps>(() => {
    const currentCourse = allCourses.find((c) => c.id === content.id);
    if (!currentCourse) return { courseStatusChipProps: null };

    const selectedCourseProgress = allCourseProgress.find(
      (cp) => cp.courseNumber === currentCourse.courseNumber,
    );
    if (!selectedCourseProgress) return { courseStatusChipProps: null };

    const recommended = recommendedCourses.some(
      (rc) => rc.courseNumber === currentCourse.courseNumber,
    );
    const missingPrereqs = isCourseMissingPrerequisites(
      currentCourse,
      allCourseProgress,
    );

    return {
      courseStatusChipProps: {
        courseProgress: selectedCourseProgress.progress,
        isRecommended: recommended,
        needsMorePrerequisites: missingPrereqs,
      },
    };
  }, [allCourses, allCourseProgress, recommendedCourses, content.id]);

  const progressItemProps = useMemo<
    (ProgressIndicatorProps & HTMLAttributes<HTMLDivElement>)[][]
  >(() => {
    const handleToggleLectureCompletion = async (lectureId: string) => {
      const idx = completedLectures.indexOf(lectureId);
      if (idx >= 0) {
        completedLectures.splice(idx, 1);
        setCompletedLectures([...completedLectures]);
      } else {
        setCompletedLectures([...completedLectures, lectureId]);
      }
      await toggleLectureCompletion(content.id.replaceAll(" ", "-"), lectureId);
    };

    const handleSetLectureGroupCompleted = async (
      contentType: string,
      completed: boolean,
    ) => {
      const targetGroup = content.contentGroups.find(
        (cg) => cg.contentType === contentType,
      );
      if (!targetGroup) return;
      if (completed) {
        // Mark them incomplete
        for (const lectureId of targetGroup.contentList) {
          const idx = completedLectures.indexOf(lectureId);
          if (idx >= 0) completedLectures.splice(idx, 1);
          await toggleLectureCompletion(
            content.id.replaceAll(" ", "-"),
            lectureId,
          );
        }
        setCompletedLectures([...completedLectures]);
      } else {
        // Mark them completed
        for (const lectureId of targetGroup.contentList) {
          if (!completedLectures.includes(lectureId)) {
            completedLectures.push(lectureId);
            await toggleLectureCompletion(
              content.id.replaceAll(" ", "-"),
              lectureId,
            );
          }
        }
        setCompletedLectures([...completedLectures]);
      }
    };

    let accumulatedLength = 0;
    const lecturesProgress = computeLecturesProgress(
      content,
      completedLectures,
    );
    const groups: (ProgressIndicatorProps &
      HTMLAttributes<HTMLDivElement>)[][] = [];

    content.contentGroups.forEach((group) => {
      const groupProgress = lecturesProgress.find(
        (lp) => lp.lectureType === group.contentType,
      );
      const startIndicator: ProgressIndicatorProps &
        HTMLAttributes<HTMLDivElement> = {
        progress: groupProgress ? groupProgress.progress : 0,
        showConnectionLine: group.contentList.length > 0,
        handleClick: () => {
          const isCurrentlyComplete = groupProgress
            ? groupProgress.progress >= 1
            : false;
          handleSetLectureGroupCompleted(
            group.contentType,
            isCurrentlyComplete,
          );
        },
        children: [
          <div key="groupTitle" className="flex flex-col mb-4">
            <p className="text-2xl text-foreground dark:text-foreground-dark">
              {group.contentType}
            </p>
            {groupProgress ? (
              <p className="text-foreground-dimmest dark:text-foreground-dimmest">
                {Math.floor(
                  groupProgress.progress * groupProgress.totalLectures,
                )}{" "}
                / {groupProgress.totalLectures} Completed
              </p>
            ) : (
              <p className="text-foreground-dimmest dark:text-foreground-dimmest">
                0 / 0 Completed
              </p>
            )}
          </div>,
        ],
      };

      group.contentList.sort((a, b) => {
        const numberA = parseInt(a.match(/\d+/)?.[0] ?? "0", 10);
        const numberB = parseInt(b.match(/\d+/)?.[0] ?? "0", 10);
        return numberA - numberB;
      });

      const items: Array<
        ProgressIndicatorProps & HTMLAttributes<HTMLDivElement>
      > = [startIndicator];
      group.contentList.forEach((lectureId, idx) => {
        const isDone = completedLectures.includes(lectureId);
        items.push({
          progress: isDone ? 1 : 0,
          showConnectionLine: idx !== group.contentList.length - 1,
          handleClick: () => handleToggleLectureCompletion(lectureId),
          children: (
            <div className="mb-8">
              <Link
                to={`lectures/${accumulatedLength + 1}`}
                className="no-underline hover:underline text-foreground dark:text-foreground-dark"
              >
                <p className="text-lg">{`${group.contentType} ${idx + 1}`}</p>
              </Link>
            </div>
          ),
        });
        accumulatedLength++;
      });
      groups.push(items);
    });
    return groups;
  }, [completedLectures, content]);

  return (
    <SEOWrapper
      title={`${content.title} - AlgoLink Course Overview`}
      description={`Explore the ${content.title} course by ${content.author}. Learn more via MIT OpenCourseWare content.`}
    >
      <div className="w-full min-h-screen bg-background-root dark:bg-background-dark-root text-foreground dark:text-foreground-dark flex items-center justify-center px-4">
        <div className="flex flex-col w-full max-w-[70rem] pt-6 gap-10">
          <div className="flex flex-col gap-4">
            <h3 className="font-semibold text-2xl">{content.title}</h3>
            <CourseStatusChip {...currentCourseStatusChipProps} />
          </div>

          <div className="flex flex-col-reverse md:flex-row gap-8 mb-8">
            {/* Left side: progress indicators */}
            <div className="md:w-1/2 overflow-y-auto pr-4">
              {progressItemProps.map((group, groupIdx) => (
                <div className="flex flex-col mb-8" key={groupIdx}>
                  {group.map((entry, idx) => (
                    <ProgressIndicator key={idx} {...entry} />
                  ))}
                </div>
              ))}
            </div>

            {/* Right side: content outlet */}
            <div className="md:w-1/2 flex flex-col gap-6">
              <Outlet />
              <p className="max-w-[80ch] text-foreground-dimmest dark:text-foreground-dimmest">
                {generateMITCitation(
                  content.author,
                  content.title,
                  content.term,
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </SEOWrapper>
  );
}
