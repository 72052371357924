// src/components/Modal.tsx
import React, { HTMLAttributes } from "react";
import { Modal as BootstrapModal } from "react-bootstrap";

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  show: boolean;
  setShow: (updatedShowState: boolean) => void;
  title: string;
  dark?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  show,
  setShow,
  title,
  children,
  dark = false,
}) => {
  const handleClose = () => setShow(false);
  return (
    <BootstrapModal
      show={show}
      onHide={handleClose}
      contentClassName={
        "bg-background-root dark:bg-background-dark-root text-foreground dark:text-foreground-dark rounded-sm"
      }
      dialogClassName={
        "modal-dialog-centered modal-lg bg-background-root dark:bg-background-dark-root rounded-sm"
      }
    >
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{children}</BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default Modal;
